import { LookupItems, ResearchTypeId, RegionId } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UserEntitlement } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';

export const hasBiggestConcernsEntitlement = (userEntitlements?: UserEntitlementsResponseData) => {
    const userEntitlementsList: (UserEntitlement | undefined)[] = userEntitlements?.packages
        .filter((pckg) => pckg.entitlements !== undefined)
        .flatMap((pckg) => pckg.entitlements, Infinity) || [];


    let hasCompanyReportEntitlement = false;
    let hasRegionEntitlement = false;

    const regionIDSet = new Set([
        RegionId.FRSAmericas.toString(),
        RegionId.FRSAsia.toString(),
        RegionId.FRSEurope.toString(),
    ]);

    for (const userEntitlement of userEntitlementsList) {
        if (
            userEntitlement?.item_lid === LookupItems.ResearchTypeId &&
            userEntitlement.value.toString() === ResearchTypeId.FrsCompanyReport.toString()
        ) {
            hasCompanyReportEntitlement = true;
        }

        if (userEntitlement?.item_lid === LookupItems.RegionLid && regionIDSet.has(userEntitlement.value.toString())) {
            hasRegionEntitlement = true;
        }

        // Break early if both entitlements are found
        if (hasCompanyReportEntitlement && hasRegionEntitlement) {
            break;
        }
    }

    return (hasCompanyReportEntitlement && hasRegionEntitlement);
};
