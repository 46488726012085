import { TypographyStyle28 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, SxProps } from '@mui/material';
import { useMemo } from 'react';
import { breakpointWorksOnDesktop, breakpointWorksOnMobile } from './AnalystDirectory';

interface ProfilePictureProps {
    imageUrl: string;
    firstName: string;
    lastName: string;
    width: string;
    height: string;
    avatarDim: string;
    typographySx?: SxProps;
    isDetailView?: boolean;
}

const positionCenterSx: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const gradients = {
    '0': ['#5E9EFF', '#832AFF'],
    '1': ['#4A95FA', '#26E9E3'],
    '2': ['#DFF310', '#4CC028'],
    '3': ['#FF65D5', '#FFBA6E'],
};

const getCombinedCharCodes = (name: string): number => {
    return name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
};

export function ProfilePicture(props: ProfilePictureProps) {
    const {
        width,
        height,
        avatarDim,
        typographySx = {},
        imageUrl = '',
        firstName = '',
        lastName = '',
        isDetailView = false,
    } = props;

    const initials = useMemo(() => {
        if (!firstName && !lastName) return '';
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`;
    }, [firstName, lastName]);

    const profileSx: SxProps = {
        width,
        height,
        borderRadius: '20px',
        objectFit: 'cover',
        objectPosition: '50% 0',
        [breakpointWorksOnMobile]: {
            ...(isDetailView
                ? {}
                : {
                      width: '62px',
                      height: '69px',
                  }),
        },
    };

    // temporary logic to fix issue in uat
    // will be removed once platinum(https://github.com/CFRAResearch/cfra-dev-environment/pull/4571) is moved to uat
    const skipImageUrl = imageUrl.includes('analyst_images/123.jpg');

    if (imageUrl && !skipImageUrl) {
        return <Box component='img' src={imageUrl} alt='avatar' sx={profileSx} />;
    }

    const combinedCharCodes = (getCombinedCharCodes(firstName) + getCombinedCharCodes(lastName)) % 4;
    const profileGradient = gradients[combinedCharCodes.toString() as keyof typeof gradients];
    const circleFrameSx: SxProps = {
        width: avatarDim,
        height: avatarDim,
        position: 'relative',
        backgroundImage: `linear-gradient(135deg, ${profileGradient[0]}, ${profileGradient[1]})`,
        [breakpointWorksOnMobile]: {
            borderRadius: '20px',
            ...(isDetailView
                ? {}
                : {
                      width: '62px',
                      height: '69px',
                  }),
        },
        [breakpointWorksOnDesktop]: {
            borderRadius: '50%',
        },
    };

    // Update the typography size for initials in mobile
    const initialsTypographySx: SxProps = {
        ...typographySx,
        [breakpointWorksOnMobile]: {
            fontSize: '24px',
            lineHeight: '24px',
        },
        [breakpointWorksOnDesktop]: {
            lineHeight: '48.4px',
        },
    };

    return (
        <Box sx={circleFrameSx}>
            <Box sx={positionCenterSx}>
                <TypographyStyle28 sx={initialsTypographySx}>{initials}</TypographyStyle28>
            </Box>
        </Box>
    );
}
