import { Authenticator, Button, useAuthenticator, View } from '@aws-amplify/ui-react';
import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import { Hub } from 'aws-amplify';
import { ETFPageContainer } from 'components/layout';
import useVerifyLogin from 'hooks/useVerifyLogin';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import BackgroundImage from '../../../assets/images/login_background_image.png';
import { CustomForceUpdatePassword } from './CustomForceUpdatePassword/CustomForceUpdatePassword';
import { InstitutionalLogo } from './InstitutionalLogo';
import './Login.scss';
import { UrlLoginSource } from 'utils/enums';
import _ from 'lodash';
import { preloadImage } from '@cfra-nextgen-frontend/shared/src/utils/icons';
import { hasQueryParam } from '@cfra-nextgen-frontend/shared/src/utils/links';

const formFields = {
    signIn: {
        username: {
            placeholder: 'Email',
            isRequired: true,
        },
        password: {
            placeholder: 'Password',
            isRequired: true,
        },
    },
    resetPassword: {
        username: {
            placeholder: 'Enter your email',
        },
    }
};

preloadImage(BackgroundImage);
const incorrectPasswordAlert = _.debounce(() => alert('Incorrect password!'), 800);

const passwordUpdateMessage = 'Your password was successfully updated. Please login using your new credentials';

const headerDescriptions: Record<string, string> = {
    default: 'Login to access our full suite of forensic research including Accounting Lens, Legal Edge and Bespoke Edge.',
    resetPassword: "Enter your email to receive a code for resetting your password. Check your junk or spam folder if you don't see it in your inbox.",
    confirmResetPassword: 'Please enter the code emailed to you to complete the password reset process.'
};

export function Login({ rememberMe }: { rememberMe?: React.MutableRefObject<boolean | undefined> }) {
    const { route, toResetPassword } = useAuthenticator((context) => [context.route]);
    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);
    const [forgetPassword, setForgetPassword] = useState(false);
    const { isLoginVerified, isLoading, loginToken, updatePassword } = useVerifyLogin({ setForgetPassword });
    const isMobile = useMediaQuery('(max-width:999.5px)');

    const AuthHeaderSection: JSX.Element = useMemo(() => {
        const description = headerDescriptions[route] || headerDescriptions.default;
        return <InstitutionalLogo description={description} />;
    }, [route]);

    // show default alert when invalid login received from url
    useEffect(() => {
        if (isLoading === false && updatePassword === false && isLoginVerified === false) {
            const { username, password, source, forgot } = loginToken || {};
            const hasToken = hasQueryParam('token')
            if (forgot === false && username && password && source === UrlLoginSource.MARKETING && hasToken) {
                incorrectPasswordAlert();
            }
        }
    }, [isLoading, isLoginVerified, loginToken, updatePassword])

    // reset state once forget password page loaded
    useEffect(() => {
        if (route === 'resetPassword' && forgetPassword) {
            setForgetPassword(false);
        }
    }, [forgetPassword, route]);

    useEffect(() => {
        if (forgetPassword) {
            toResetPassword();
        }
    }, [forgetPassword, toResetPassword]);

    useEffect(() => {
        if (rememberMe && !rememberMe.current && loginToken?.rememberMe) {
            rememberMe.current = true;
        }
    }, [loginToken?.rememberMe, rememberMe]);

    useEffect(() => {
        const unSubscribe = Hub.listen('auth', (data: { payload: any }) => {
            const { payload } = data;
            switch (payload.event) {
                case 'forgotPassword':
                    setForgetPassword(false);
                    break;
                case 'forgotPasswordSubmit':
                    setForgetPassword(false);
                    ShowSnack(SnackMessageForm({ message: passwordUpdateMessage }));
                    break;
            }
        });

        return () => unSubscribe();
    }, [ShowSnack]);

    const components = useMemo(() => ({
        Header() {
            return AuthHeaderSection;
        },
        SignIn: {
            Footer() {
                const { toResetPassword } = useAuthenticator();
                return (
                    <View style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '400px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label className="checkbox-label">
                                <input
                                    type='checkbox'
                                    onChange={(e) => {
                                        if (!rememberMe) {
                                            return;
                                        }
                                        rememberMe.current = (e.target as HTMLInputElement)?.checked;
                                    }}
                                    className="checkbox-input"
                                    defaultChecked={rememberMe?.current ?? false}
                                />
                                <span className={`checkbox-custom ${rememberMe?.current ? 'checked' : ''}`} />
                                <span style={{ marginLeft: '0.5rem', color: isMobile ? '#fff' : '#555555' }}>Remember Me</span>
                            </label>
                        </div>
                        <Button
                            fontWeight='normal'
                            onClick={() => {
                                toResetPassword();
                                if (rememberMe === undefined) {
                                    return;
                                }

                                rememberMe.current = false;
                            }}
                            size='small'
                            variation='link'
                            className="forgot-password-link"
                        >
                            Forgot Password?
                        </Button>
                    </View>
                );
            },
        },
    }), [AuthHeaderSection, isMobile, rememberMe]);

    if (isLoading) {
        return (
            <Box width='100%' height='100%'>
                <Skeleton sx={{ mt: '120px', mx: '20px', height: '30px' }} />
            </Box>
        );
    }

    return (
        <ETFPageContainer
            containerStyles={{
                maxWidth: 'unset',
                backgroundColor: '#fff',
                minHeight: '500px'
            }}
        >
            <div style={{ display: 'flex', minHeight: '100vh', height: '100%', overflow: 'hidden' }}>
            <div style={{ flex: isMobile ? '0 0 100%' : '0 0 50%', backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                <div style={{ position: 'absolute', left: '50%', right: isMobile ? '50%' : '0%', border: '50%' }}>
                    {!isLoginVerified && (
                        <Authenticator formFields={formFields} components={components} hideSignUp={true} />
                    )}

                    {isLoginVerified && (
                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            flexDirection='column'
                            minHeight='100vh'>
                            <SnackbarProvider classes={{ containerRoot: 'cfra-snackbar-root' }}>
                                <CustomForceUpdatePassword
                                    username={loginToken.username}
                                    password={loginToken.password}
                                    onSuccess={() => (window.location.href = '/')}
                                    onFailure={() => window.location.reload()}
                                    HeaderSection={
                                        <View textAlign='center' style={{ display: 'flex', flexDirection: 'column' }}>
                                            {AuthHeaderSection}
                                        </View>
                                    }
                                    formLabelSx={{ color: isMobile ? '#fff' : '002b5a' }}
                                />
                            </SnackbarProvider>
                        </Box>
                    )}
                </div>
            </div>
        </ETFPageContainer>
    );
}
