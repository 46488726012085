import { createTheme, ThemeOptions } from '@mui/material';
import { inputBaseStyles } from '../FormTextField';
import { inputFontStyle } from '../shared/StyledFormLabel';
import calendar from '@cfra-nextgen-frontend/shared/src/assets/icons/calendar.svg';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';

// used on Institutional
export const dateRangePickerThemeVariant1 = createTheme({
    components: {
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    display: 'flex !important',
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    '@media (max-width: 430px)': {
                        paddingLeft: '3px',
                        paddingRight: '0px',
                    },
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    zIndex: 5000,
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    borderRadius: '10px',
                    marginTop: '10px',
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    display: 'flex',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    width: '100%',
                    maxWidth: '121px',
                    height: '48px',
                    borderRadius: '10px !important',
                    position: 'relative',
                    '&::after': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        right: 10,
                        top: '50%',
                        transform: 'translateY(-50%)',
                        width: '17px',
                        height: '20px',
                        backgroundImage: `url(${calendar})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                    },
                },
                input: {
                    ...inputBaseStyles,
                    ...inputFontStyle,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: '0 !important',
                    fontSize: '14px',
                    lineHeight: '1.64em',
                    color: '#57626a',
                    fontFamily: fontFamilies.GraphikRegular,
                },
            },
        },
    },
} as ThemeOptions);
