import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { SharedTopNavigation } from '@cfra-nextgen-frontend/shared/src/components/TopNavigation/SharedTopNavigation';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { Results1Column } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/resultsComponents/Results1Column';
import { Results2Columns } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/resultsComponents/Results2Columns';
import {
    ShowHideStrategies,
    TypeSearchProps,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { UsageLoggerReturnTypes, useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { PopupLink } from '@cfra-nextgen-frontend/shared/src/types/popupContent';
import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { ComponentLid, EntityTypeLid } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { useMediaQuery } from '@mui/material';
import CFRAInstitutionalLogo from 'assets/images/CFRAInstitutionalLogo.svg';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { determineGetResearchSuggesterOptions } from 'features/topNavigation/researchSuggester';
import { topNavItems } from 'features/topNavigation/topNavItems';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { maxPageWidthInPxIncludingPadding } from 'utils/lookAndFeel';
import { defaultSearchUsageReqBody, getPageLid, searchesUsageLoggerProps } from 'utils/usage';

const companySuggesterSize = 25;
const researchSuggesterSize = 10;
const resultsLeftColumnTitle = 'Companies';
const resultsRightColumnTitle = 'Research';
const toggleHeight = 44;
const togglePaddingBottom = 10;
const toggleContainerHeight = toggleHeight + togglePaddingBottom;

export function TopNavigation({ userEntitlements }: { userEntitlements?: UserEntitlementsResponseData }) {
    const { setRequestBody } = useUsageLogger(searchesUsageLoggerProps);

    const isDesktopVariant = useMediaQuery('(min-width:650.5px)');
    const isBelow900 = useMediaQuery('(max-width:900.5px)');
    const topLinkTextChanges = useMediaQuery('(min-width:410.5px)');

    const [companySuggesterResultsNumber, setCompanySuggesterResultsNumber] = useState<number | undefined | null>(undefined);
    const [researchSuggesterResultsNumber, setResearchSuggesterResultsNumber] = useState<number | undefined | null>(undefined);

    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { setCfraDataLocalRef } = analyticsDataContext || {};

    useEffect(() => {
        const isEmptySearch = companySuggesterResultsNumber === undefined && researchSuggesterResultsNumber === undefined;

        const getResults = (count: number | null | undefined) => {
            if (count === null) return 'Loading...';
            if (count === undefined) return undefined;
            return String(count);
        };

        setCfraDataLocalRef?.current?.(
            (previousValue) => ({
                ...previousValue,
                actionData: {
                    ...previousValue?.actionData,
                    searchResults: isEmptySearch ? undefined: joinWithDelimiter({
                        values: [
                            getResults(companySuggesterResultsNumber),
                            getResults(researchSuggesterResultsNumber),
                        ],
                    }),
                },
            }),
            undefined,
            false,
        );
    }, [setCfraDataLocalRef, companySuggesterResultsNumber, researchSuggesterResultsNumber]);

    const { independentValue: setRequestBodyRef } = useMakeIndependent({
        valueGetter: () => (props: Parameters<UsageLoggerReturnTypes['setRequestBody']>[0]) => setRequestBody(props),
        defaultValue: () => {},
    });

    const memoisedTopNavItems = useMemo(() => {
        const topNavItemCopy = cloneDeep(topNavItems);
        Object.keys(topNavItemCopy).forEach((key) => {
            const topNavItem = topNavItemCopy[key];
            if (topNavItem.shouldHideItem?.(userEntitlements)) {
                delete topNavItemCopy[key]; // Entire Object (top level) to delete
            } else {
                // Check sub links
                const newLinks = topNavItem.popup?.links.filter((link: PopupLink) => {
                    return !link.shouldHideLink?.(userEntitlements);
                });
                if (topNavItemCopy[key].popup && newLinks && newLinks.length > 0) {
                    topNavItemCopy[key].popup = {
                        title: topNavItemCopy[key].popup?.title || '',
                        description: topNavItemCopy[key].popup?.description || '',
                        links_title: topNavItemCopy[key].popup?.links_title || '',
                        links: newLinks,
                    };
                }
            }
        });
        return topNavItemCopy;
    }, [userEntitlements]);

    const desktopVariantProps: Partial<TypeSearchProps> = useMemo(
        () =>
            isBelow900
                ? {
                      titleWithScrollableAreaPropsToOverride: {
                          left: {
                              containerSx: {
                                  width: '30%',
                              },
                              scrollableAreaContainerWrapperSx: {
                                  width: '100%',
                                  padding: '0px 2px',
                                  borderRight: '1px solid #CCCCCC',
                              },
                              scrollableAreaContainerSx: {
                                  padding: '0px 2px 0px 0px',
                              },
                              titleProps: {
                                  text: resultsLeftColumnTitle,
                                  sx: { paddingLeft: '10px !important' },
                              },
                          },
                          right: {
                              titleProps: {
                                  text: resultsRightColumnTitle,
                                  sx: { paddingLeft: '18px' },
                              },
                              scrollableAreaContainerWrapperSx: {
                                  paddingLeft: '2px',
                              },
                          },
                      },
                      ResultsColumnsComponent: (props) => (
                          <Results2Columns {...props} titleWithLinkProps={{ titleSx: { paddingLeft: '10px' } }} />
                      ),
                  }
                : {
                      titles: {
                          left: resultsLeftColumnTitle,
                          right: resultsRightColumnTitle,
                      },
                  },
        [isBelow900],
    );

    const mobileVariantProps: Partial<TypeSearchProps> = useMemo(
        () => ({
            titleWithScrollableAreaPropsToOverride: {
                left: {
                    containerSx: {
                        height: `calc(100% - ${toggleContainerHeight}px)`,
                    },
                    scrollableAreaContainerWrapperSx: {},
                    scrollableAreaContainerSx: {
                        padding: '0px 6px',
                        maxHeight: '85%',
                    },
                },
                right: {
                    containerSx: {
                        height: `calc(100% - ${toggleContainerHeight}px)`,
                    },
                    scrollableAreaContainerWrapperSx: {},
                    scrollableAreaContainerSx: {
                        padding: '0px 6px',
                        maxHeight: '85%',
                    },
                },
            },

            ResultsColumnsComponent: ({ leftOptions, rightOptions, ...props }) => (
                <Results1Column
                    options={{
                        [resultsLeftColumnTitle]: leftOptions,
                        [resultsRightColumnTitle]: rightOptions,
                    }}
                    toggleContainerSx={{
                        width: '226px',
                        height: `${toggleContainerHeight}px`,
                        paddingBottom: `${togglePaddingBottom}px`,
                    }}
                    {...props}
                    titleWithLinkProps={{
                        title: 'Search Result',
                        titleSx: { paddingLeft: '10px' },
                        linkTextStyles: {
                            paddingLeft: '10px',
                            marginBottom: useMediaQuery('(max-width:375px)') ? '10px' : '0px',
                        },
                        titleIconStyles: { display: useMediaQuery('(max-width:650.5px)') ? 'none' : 'unset' },
                        headerAndLinkStyles: {
                            '@media (max-width: 374.5px)': {
                                flexDirection: 'column',
                                display: 'contents',
                                height: 'unset',
                            },
                        },
                    }}
                />
            ),
        }),
        [],
    );

    const result = useMemo(() => {
        return (
            <SharedTopNavigation
                maxWidth={maxPageWidthInPxIncludingPadding}
                topNavItems={memoisedTopNavItems}
                logoSrc={CFRAInstitutionalLogo}
                breakpointsThemeOptions={commonCustomBreakpointsTheme}
                typeSearchProps={{
                    showHideStrategy: ShowHideStrategies.UseShowSearchInput,
                    topLinkParams: {
                        text: topLinkTextChanges ? `Go to ${PageNames.ResearchHub}` : `${PageNames.ResearchHub}`,
                        pathname: PageNamesToRoutePaths[PageNames.ResearchHub],
                        navigateTo: PageNames.ResearchHub,
                    },
                    getLeftOptions: determineGetCompanySuggesterOptions({
                        size: companySuggesterSize,
                        getOptionsContainer,
                        externalOnClickCallback: (data, analyticsDataPickerRef) => {
                            const source = data?.['_source'];
                            analyticsDataPickerRef.current?.registerAction({
                                action: `click on company`,
                                companyTicker: source?.['company_security.security_trading.ticker_symbol'],
                                companyName: source?.['company_security.company.company_name'],
                                companyCfraId: source?.['company_security.company.cfra_company_id'],
                                companySecurityId: source?.['company_security.security_trading.cfra_security_trading_id'],
                                companyExchange: source?.['company_security.security_trading.exchange_lid.exchange_code'],
                            });

                            setRequestBodyRef.current({
                                body: {
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.GlobalSearch,
                                    entity_type_lid: EntityTypeLid.Company,
                                    primary_entity_id: source?.['company_security.company.cfra_company_id'],
                                    detail: {
                                        ticker: source?.['company_security.security_trading.ticker_symbol'],
                                        exchange_code:
                                            source?.['company_security.security_trading.exchange_lid.exchange_code'],
                                    },
                                },
                                enabled: true,
                            });
                        },
                        setRowsCount: setCompanySuggesterResultsNumber,
                    }),
                    getRightOptions: determineGetResearchSuggesterOptions({
                        size: researchSuggesterSize,
                        getOptionsContainer,
                        onOptionClick: (searchTerm) => {
                            setRequestBodyRef.current({
                                body: {
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.GlobalSearch,
                                    text: searchTerm,
                                },
                                enabled: true,
                            });
                        },
                        viewMode: 'tease',
                        setRowsCount: setResearchSuggesterResultsNumber,
                    }),
                    onInputChange: (inputValue) => {
                        if (inputValue) {
                            return;
                        }

                        setCompanySuggesterResultsNumber(undefined);
                        setResearchSuggesterResultsNumber(undefined);
                    },
                    ...(isDesktopVariant ? desktopVariantProps : mobileVariantProps),
                }}
            />
        );
    }, [
        memoisedTopNavItems,
        topLinkTextChanges,
        isDesktopVariant,
        desktopVariantProps,
        mobileVariantProps,
        setRequestBodyRef,
    ]);

    return result;
}
