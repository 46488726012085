import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Radio } from '@mui/material';
import { RadioGroup } from '../Radio/RadioGroup';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

type FormRadioGroupProps = {
    options: { key: string | number; value: string }[];
    defaultValue?: string | number;
    title?: string;
} & CommonFormComponentProps;

export default function FormRadioGroup(props: FormRadioGroupProps) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <Controller
                name={props.name}
                control={props.control}
                render={({ field }) => {
                    let defaultValue;
                    defaultValue = props?.getValues?.(props.name);
                    return (
                        <RadioGroup
                            value={defaultValue || props.defaultValue}
                            onChange={(_, data) => {
                                const option = typeof data === 'string' ? props.options[Number(data)] : undefined;

                                analyticsDataPickerRef.current?.registerAction({
                                    action: joinWithDelimiter({
                                        values: [
                                            'click on radio button',
                                            props.title,
                                            option?.value,
                                            String(option?.key ?? ''),
                                        ],
                                    }),
                                });

                                field.onChange(data);
                                props.submitHandler?.(data as any);
                            }}>
                            {props.options.map((option) => (
                                <FormControlLabel
                                    key={option.key}
                                    value={option.key}
                                    control={<Radio />}
                                    label={option.value}
                                />
                            ))}
                        </RadioGroup>
                    );
                }}
            />
        </>
    );
}
