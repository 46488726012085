import { ETFCloseButton, RoundedTextButton } from '@cfra-nextgen-frontend/shared/src';
import { roundedTextButtonThemeV5ThemeOptions } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { LinkButtonV2 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    CreateSavedItemTypes,
    SavedItemListResponseTypes,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle37,
    TypographyStyle38,
    TypographyStyle39,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsPreferencesEachElement,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { getSavedItemsByTypeConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { Box, createTheme, styled } from '@mui/material';
import HomePageScreenshotFull from 'assets/images/HomePageScreenshotFull.png';
import HomePageScreenshotTop from 'assets/images/HomePageScreenshotTop.png';
import LoginPageScreenshot from 'assets/images/LoginPageScreenshot.png';
import {
    CompanySearchModalRef,
    NewWatchlistCompanySearchModal,
} from 'components/watchlists/newWatchlist/CompanySearchModal';
import { FileUploadModalRef, NewWatchlistFileUploadModal } from 'components/watchlists/newWatchlist/FileUploadModal';
import { NewWatchlistModal, NewWatchlistRef } from 'components/watchlists/newWatchlist/Modal';
import { Dispatch, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';

const userPreferencesGetterParams = {
    preferenceType: PreferenceType.IdsAndSelectionsPreferences,
};

const AppCommonSelector = UserPreferencesSelectors[UserPreferences.AppCommon];

export function WelcomePopup() {
    const { getUserPreferences, setUserPreferences, isInitialPreferencesLoaded } =
        useUserPreferences(useUserPreferencesProps);

    const [showFileUploadModal, _setShowFileUploadModal] = useState(false);
    const [showCompanySearchModal, _setShowCompanySearchModal] = useState(false);
    const [showWelcomePopup, setShowWelcomePopup] = useState(false);
    const [userHasAtLeastOneWatchlist, setUserHasAtLeastOneWatchlist] = useState<boolean | undefined>(undefined);

    const setShowFileUploadModal = useCallback(
        (showFileUploadModal: boolean) => {
            if (!showFileUploadModal) {
                setShowWelcomePopup(true);
            }
            _setShowFileUploadModal(showFileUploadModal);
        },
        [setShowWelcomePopup],
    );

    const setShowCompanySearchModal = useCallback(
        (showCompanySearchModal: boolean) => {
            if (!showCompanySearchModal) {
                setShowWelcomePopup(true);
            }
            _setShowCompanySearchModal(showCompanySearchModal);
        },
        [setShowWelcomePopup],
    );

    const newWatchlistModalRef = useRef<NewWatchlistRef>(null);
    const fileUploadRef = useRef<FileUploadModalRef>(null);
    const companySearchRef = useRef<CompanySearchModalRef>(null);

    const shouldShowExitForm = (ref: any) => {
        return ref?.current?.shouldShowExitForm ? ref?.current?.shouldShowExitForm() : true;
    };

    const showWelcomePopupPreference = useMemo(() => {
        if (!isInitialPreferencesLoaded) {
            return false;
        }

        const _showWelcomePopup = getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: AppCommonSelector,
        })?.showWelcomePopup;
        return _showWelcomePopup === undefined || _showWelcomePopup;
    }, [getUserPreferences, isInitialPreferencesLoaded]);

    const setShowWelcomePopupPreference = useCallback(
        (showWelcomePopup?: boolean) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    each: [
                        {
                            ...AppCommonSelector,
                            action: IdsAndSelectionsPreferencesActions.SetShoWelcomePopup,
                            showWelcomePopup,
                        },
                    ],
                },
            );
        },
        [setUserPreferences],
    );

    const callbackOnClose = useCallback(() => {
        setShowWelcomePopupPreference(false);
        setShowWelcomePopup(false);
    }, [setShowWelcomePopupPreference]);

    const onWatchlistCreatedCallback = (createdItem: CreateSavedItemTypes) => {
        callbackOnClose();
    };

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getAllWatchlistQry = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [UserSavedItemsLookupID.InstitutionalWatchlist] },
        getSavedItemsByTypeConfig,
    );

    useEffect(() => {
        if (getAllWatchlistQry.isLoading) {
            return;
        }

        if ((getAllWatchlistQry.data?.data?.length || 0) > 0) {
            setUserHasAtLeastOneWatchlist(true);
            // we should wait more then cache time for preferences updater (more than 3 seconds)
            setTimeout(() => callbackOnClose(), 3500);
            return;
        }

        if (getAllWatchlistQry.data?.data?.length === 0) {
            setUserHasAtLeastOneWatchlist(false);
        }
    }, [getAllWatchlistQry.isLoading, getAllWatchlistQry.data, callbackOnClose]);

    useEffect(() => {
        if (
            showWelcomePopupPreference === true &&
            !getAllWatchlistQry.isLoading &&
            userHasAtLeastOneWatchlist === false
        ) {
            setShowWelcomePopup(true);
        }
    }, [showWelcomePopupPreference, setShowWelcomePopup, getAllWatchlistQry.isLoading, userHasAtLeastOneWatchlist]);

    if (!showWelcomePopupPreference || getAllWatchlistQry.isLoading || userHasAtLeastOneWatchlist) {
        return null;
    }

    return (
        <>
            <WelcomePopupContent
                showWelcomePopup={showWelcomePopup}
                setShowWelcomePopup={setShowWelcomePopup}
                callbackOnClose={callbackOnClose}
                setShowFileUploadModal={setShowFileUploadModal}
                setShowCompanySearchModal={setShowCompanySearchModal}
            />
            {showFileUploadModal && (
                <NewWatchlistModal
                    ref={newWatchlistModalRef}
                    showModal={showFileUploadModal}
                    setShowModal={setShowFileUploadModal}
                    onWatchlistCreated={onWatchlistCreatedCallback}
                    shouldShowExitForm={() => shouldShowExitForm(fileUploadRef)}>
                    <NewWatchlistFileUploadModal
                        ref={fileUploadRef}
                        handleCloseModal={(showExitForm: boolean) => {
                            newWatchlistModalRef?.current?.handleOnClose(showExitForm);
                            setShowWelcomePopup(true);
                        }}
                        setCreateWatchlistData={(
                            data: { cfra_security_trading_id: string; cfra_company_id: string }[],
                        ) => newWatchlistModalRef?.current?.setCreateWatchlistData(data)}
                    />
                </NewWatchlistModal>
            )}
            {showCompanySearchModal && (
                <NewWatchlistModal
                    ref={newWatchlistModalRef}
                    showModal={showCompanySearchModal}
                    setShowModal={setShowCompanySearchModal}
                    onWatchlistCreated={onWatchlistCreatedCallback}
                    shouldShowExitForm={() => shouldShowExitForm(companySearchRef)}
                    modalBoxStyles={{ maxWidth: '800px' }}>
                    <NewWatchlistCompanySearchModal
                        ref={companySearchRef}
                        setCreateWatchlistData={(
                            data: { cfra_security_trading_id: string; cfra_company_id: string }[],
                        ) => newWatchlistModalRef?.current?.setCreateWatchlistData(data)}
                    />
                </NewWatchlistModal>
            )}
        </>
    );
}

const StyledImage = styled('img')(({ theme }) => ({
    width: '129px',
    height: '129px',
    objectFit: 'cover',
    boxShadow: '0px 4px 4px 0px #00000040',
}));

const PopupTitle = styled(TypographyStyle37)({
    width: '100%',
    textAlign: 'center',
    letterSpacing: 0,
    lineHeight: '32px',
});

const UploadCsvButtonTheme = createTheme(roundedTextButtonThemeV5ThemeOptions, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    width: '218px',
                    height: '49px',
                },
            },
        },
    },
});

export const WelcomePopupContent = ({
    showWelcomePopup,
    setShowWelcomePopup,
    callbackOnClose,
    setShowFileUploadModal,
    setShowCompanySearchModal,
}: {
    showWelcomePopup: boolean;
    setShowWelcomePopup: Dispatch<boolean>;
    callbackOnClose: () => void;
    setShowFileUploadModal: Dispatch<boolean>;
    setShowCompanySearchModal: Dispatch<boolean>;
}) => {
    return (
        <ETFModal
            modalBoxStyles={{
                maxWidth: '488px',
                maxHeight: '603px',
                backgroundColor: '#F3F5FC',
                padding: '21px 23px 25px 23px',
                boxShadow: '0px 4px 4px 0px #00000040',
            }}
            zIndex={1900}
            externalOpenModal={showWelcomePopup}
            keepContentOnClose={false}
            callbackOnClose={callbackOnClose}
            showCloseButton={false}
            childrenContainerStyles={{
                flexDirection: 'column',
                overflow: 'hidden',
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', maxHeight: '18px' }}>
                <ETFCloseButton
                    onClick={callbackOnClose}
                    buttonFontSize={18}
                    hovercolor='#555555'
                    backgroundcolor='transparent'
                    enabledcolor='#AAAAAA'
                    transform='scale(1.4)'
                />
            </Box>
            <Box
                sx={{
                    marginTop: '15px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    ...scrollbarThemeV3,
                }}>
                {/*Top images section*/}
                <Box width='100%' display='flex' justifyContent='center'>
                    <Box
                        minWidth='283px'
                        width='283px'
                        height='179px'
                        position='relative'
                        display='flex'
                        justifyContent='center'>
                        <StyledImage
                            src={HomePageScreenshotTop}
                            style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                        />
                        <StyledImage
                            src={HomePageScreenshotFull}
                            style={{ position: 'absolute', top: 50, left: 63, zIndex: 2 }}
                        />
                        <StyledImage
                            src={LoginPageScreenshot}
                            style={{ position: 'absolute', top: 22, right: 0, zIndex: 3 }}
                        />
                    </Box>
                </Box>
                <PopupTitle sx={{ marginTop: '33px' }}>Welcome to the CFRA</PopupTitle>
                <PopupTitle sx={{ color: '#609EA0' }}>Institutional Portal</PopupTitle>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                    <TypographyStyle38
                        sx={{
                            textAlign: 'center',
                            letterSpacing: 0,
                            lineHeight: '26px',
                            paddingTop: '15px',
                            maxWidth: '385px',
                        }}>
                        To get the most out of your experience, start by creating a personalized watchlist. Monitor
                        companies that matter to you and stay informed of new research.
                    </TypographyStyle38>
                </Box>
            </Box>
            <TypographyStyle39
                sx={{
                    width: '100%',
                    textAlign: 'center',
                    letterSpacing: 0,
                    lineHeight: '26px',
                    paddingTop: '13px',
                }}>
                Let’s Build Your First Watchlist!
            </TypographyStyle39>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '25px',
                }}>
                <RoundedTextButton
                    buttonText='Upload CSV File'
                    theme={UploadCsvButtonTheme}
                    onClickCallback={() => {
                        setShowFileUploadModal(true);
                        setShowWelcomePopup(false);
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '6px',
                }}>
                <LinkButtonV2
                    onClick={() => {
                        setShowCompanySearchModal(true);
                        setShowWelcomePopup(false);
                    }}
                    sx={{
                        width: '110px',
                        height: '36px',
                    }}>
                    Search to add
                </LinkButtonV2>
            </Box>
        </ETFModal>
    );
};
