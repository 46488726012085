import { ETFCloseButton } from '@cfra-nextgen-frontend/shared/src';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { TypographyStyle2 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, Grid } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { ButtonsPanel } from './ButtonsPanel';
import { FiltersBadge } from './FiltersBadge';
import { FiltersButton } from './FiltersButton';
import { FiltersButtonWithModalProps } from './shared';

const title = 'Filters';

export function FiltersButtonWithModal({
    filtersFormJsx,
    filtersChipPanelJsx,
    externalChipItems,
    submitHandler,
    control,
    externalFormStateStorage,
    filterButtonTextStyle,
    watchlistName = '',
}: FiltersButtonWithModalProps) {
    const [showModal, setShowModal] = useState(false);

    const filtersScrollableContainerRef = useRef<HTMLDivElement>(null);

    const handleOpenModal = useCallback(() => {
        analyticsDataPickerRef.current?.registerAction({
            action: joinWithDelimiter({
                values: ['open modal', title],
            }),
        });
        setShowModal(true);
    }, []);

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const callbackOnClose = useCallback(() => {
        setShowModal(false);
    }, []);

    const captureCloseModalAnalytics = useCallback(() => {
        analyticsDataPickerRef.current?.registerAction({
            action: joinWithDelimiter({
                values: ['close modal', title],
            }),
        });
        setShowModal(false);
    }, []);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <FiltersButton
                externalChipItems={externalChipItems}
                onClick={handleOpenModal}
                filterButtonTextStyle={filterButtonTextStyle}
            />
            <ETFModal
                keepContentOnClose
                modalBoxStyles={{
                    height: '100%',
                    width: '100%',
                    maxHeight: 'calc(100% - 160px)',
                    maxWidth: 'calc(100% - 28px)',
                    padding: '14px 0px',
                    borderRadius: '8px',
                }}
                zIndex={1900}
                externalOpenModal={showModal}
                callbackOnClose={() => {
                    captureCloseModalAnalytics();
                    callbackOnClose();
                }}
                showCloseButton={false}
                childrenContainerStyles={{
                    alignItems: 'start',
                    height: '100%',
                }}>
                <Grid container sx={{ height: '100%', display: 'block' }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'start',
                            height: '40px',
                            borderBottom: '1px solid #eaeaea',
                            margin: '0px 14px',
                        }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}>
                            <TypographyStyle2
                                sx={{
                                    marginRight: '8px',
                                }}>
                                {title}
                            </TypographyStyle2>
                            <FiltersBadge
                                externalChipItems={externalChipItems}
                                sx={{
                                    '& .MuiBadge-badge:not(.MuiBadge-invisible)': {
                                        transform: 'scale(1) translate(100%, 15%) !important',
                                    },
                                }}
                            />
                        </Box>
                        <ETFCloseButton
                            onClick={() => {
                                captureCloseModalAnalytics();
                                callbackOnClose();
                            }}
                            buttonFontSize={25}
                        />
                    </Grid>
                    <Grid
                        ref={filtersScrollableContainerRef}
                        item
                        xs={12}
                        sx={{
                            overflowY: 'auto',
                            height: 'calc(100% - 40px)',
                            ...scrollbarThemeV3,
                        }}>
                        {filtersFormJsx}
                        {filtersChipPanelJsx}
                        {control && (
                            <ButtonsPanel
                                containerRef={filtersScrollableContainerRef}
                                submitHandler={submitHandler}
                                callbackOnClose={callbackOnClose}
                                control={control}
                                externalFormStateStorage={externalFormStateStorage}
                                externalChipItems={externalChipItems}
                                watchlistName={watchlistName}
                            />
                        )}
                    </Grid>
                </Grid>
            </ETFModal>
        </>
    );
}
