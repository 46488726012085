import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    ScreenerResearchCompanyData,
    UsageScreenerData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getReportCompanyProps } from 'analytics/utils';
import {
    ApiNames,
    EntityTypeLid,
    filterNullsAndDuplicates,
    ProductLid,
    RequestTypes,
    UsageRequestTypes,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { getUsageApiQueryKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import {
    ItemVariant1,
    ItemVariant1Props,
} from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant1';
import {
    GetOptionsBlankContainer,
    GetOptionsContainer,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { ResearchDescriptionText } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { Layout } from '@cfra-nextgen-frontend/shared';

const MaxItemsInList = 20;

type CompanySearchProps = {
    passNavigateUrl?: boolean;
    OptionsContainer: ReturnType<GetOptionsContainer | GetOptionsBlankContainer>;
    itemTextContainersSx?: ItemVariant1Props['textContainersSx'];
    onLinkClickCallback?: () => void;
};

export function CompanySearch({
    passNavigateUrl,
    OptionsContainer,
    itemTextContainersSx,
    onLinkClickCallback,
}: CompanySearchProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [viewedCompanyIds, setViewedCompanyIds] = useState<string[]>([]);

    const usageQuery = sendSingleRequest?.(
        {
            productLid: ProductLid.ForensicAccountingResearch,
            usageTypeLid: UsageTypeLid.PageView,
            entityTypeLid: [EntityTypeLid.Equity, EntityTypeLid.Company].join(','),
            fields: ['primary_entity_id'],
        },
        {
            requestType: RequestTypes.GET,
            path: 'usage',
            queryKeyFirstElement: getUsageApiQueryKey(UsageRequestTypes.CompanyViewed),
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<UsageScreenerData>;

    useEffect(() => {
        if (usageQuery?.data?.data) {
            const usageData = usageQuery.data.data.results?.data || [];
            const companyIds: string[] = filterNullsAndDuplicates(usageData.map((d: any) => d.primary_entity_id));
            setViewedCompanyIds([...companyIds.slice(0, MaxItemsInList)]);
        }
    }, [usageQuery?.data?.data]);

    const usageScreenerQuery = sendSingleRequest?.(
        {
            path: 'company/screener',
            size: MaxItemsInList,
            securityType: 'company',
            view: 'default',
            requestBody: {
                filters: {
                    values: {
                        'company_security.security_trading.cfra_security_trading_id': {
                            values: viewedCompanyIds,
                        },
                    },
                },
            },
            config: {
                enabled: viewedCompanyIds.length !== 0,
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'company/screener',
            queryKeyFirstElement: 'usageScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    const { isLoading, dataList, noResults } = useMemo(() => {
        const data: any[] = [];
        let noResults = false;
        const isLoading = usageQuery.isLoading || usageScreenerQuery?.isLoading;
        if (isLoading) {
            return { isLoading, dataList: data, noResults };
        }
        if (viewedCompanyIds && !usageScreenerQuery?.isLoading && usageScreenerQuery?.data?.results?.company) {
            viewedCompanyIds.forEach((id: any, idx: number) => {
                const company = usageScreenerQuery?.data?.results?.company.find((item: any) => item.id === id);
                if (company) {
                    data.push(company);
                }
            });
        }
        noResults = !isLoading && (viewedCompanyIds.length === 0 || data.length === 0);

        return { isLoading, dataList: data, noResults };
    }, [
        usageQuery.isLoading,
        usageScreenerQuery?.data?.results?.company,
        usageScreenerQuery?.isLoading,
        viewedCompanyIds,
    ]);
   
    if (isLoading) {
        return (
            <OptionsContainer>
                <Layout.Skeleton height='10px' />
            </OptionsContainer>
        );
    }
    if (noResults) {
        return (
            <OptionsContainer>
                <ResearchDescriptionText>No history available</ResearchDescriptionText>
            </OptionsContainer>
        );
    }
    
    return (
        <OptionsContainer>
            {dataList.map((company: any, idx: number) => {
                return (
                    <AnalyticsDataContextProvider
                        key={idx}
                        cfraDataLocal={{
                            actionData: getReportCompanyProps({
                                researchReportSecurityItem: company?.company_security,
                            }),
                        }}>
                        <ItemVariant1
                            leftPartLeftText={company.company_security.security_trading.ticker_symbol}
                            leftPartRightText={company.company_security.security_trading.exchange_lid.exchange_code}
                            navigateUrl={
                                passNavigateUrl
                                    ? company.company_security.security_trading.cfra_security_trading_id
                                        ? `/company-profile/security/${company.company_security.security_trading.cfra_security_trading_id}`
                                        : `/company-profile/company/${company.company_security.company.cfra_company_id}`
                                    : undefined
                            }
                            onLinkClickCallback={() => {
                                onLinkClickCallback?.();
                            }}
                            rightPartText={company.company_security.company.company_name}
                            textContainersSx={itemTextContainersSx}
                        />
                    </AnalyticsDataContextProvider>
                );
            })}
        </OptionsContainer>
    );
}
