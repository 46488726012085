import { GenericSelector } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApplicationType } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageNames } from './enums';
import { UsePreferenceType } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';

export const useUserPreferencesProps: UsePreferenceType = {
    applicationType: ApplicationType.InstitutionalPortal,
};

export const enum UserPreferences {
    AppCommon = 'appCommon',
    WatchlistSelectLastViewedId = 'watchlistSelectLastViewedId',
    HomePageCommon = 'homePageCommon',
    HomePageCompanyReportsCommon = 'homePageCompanyReportsCommon',
    HomePageCompanyReportsTableView = 'homePageCompanyReportsTableView',
    HomePageRiskMonitor = 'homePageRiskMonitor',
    HomePageIndustryResearch = 'homePageIndustryResearch',
    ResearchHubSearchResults = 'researchHubSearchResults',
    ResearchHubSearchResultsTableView = 'researchHubSearchResultsTableView',
    ResearchCompanySearchResults = 'researchCompanySearchResults',
    ResearchCompanySearchResultsTableView = 'researchCompanySearchResultsTableView',
    ResearchIndustrySearchResults = 'researchIndustrySearchResults',
    ResearchIndustrySearchResultsTableView = 'researchIndustrySearchResultsTableView',
    BespokeAdminSearchResultsTableView = 'bespokeAdminSearchResultsTableView',
    BespokeEdgeSearchResults = 'bespokeEdgeSearchResults',
    BespokeEdgeSearchResultsTableView = 'bespokeEdgeSearchResultsTableView',
    LegalEdgeSearchResults = 'legalEdgeSearchResults',
    LegalEdgeSearchResultsTableView = 'legalEdgeSearchResultsTableView',
    WatchlistResultsTable = 'watchlistResultsTable',
    AccountingLensRiskMonitor = 'accountingLensRiskMonitor',
    ScoresResultsCard = 'scoresResultsCard',
    CompanyProfileCompanyHeadlines = 'companyPageCompanyHeadlines',
}

export enum Locations {
    CompanyReports = 'Company Reports',
    RiskMonitor = 'Risk Monitor',
    IndustryResearch = 'Industry Research',
    SearchResults = 'Search Results',
    CompanyHeadlines = 'Company Headlines',
    Trending = 'Trending',
    MyRecentViews = 'My Recent Views',
    DailyPacket = 'Daily Packet',
    LocalSearch = 'Local Search',
    Filters = 'Filters',
    PopularSearches = 'Popular Searches',
    MoreInsights = 'More Insights',
    Scores = 'Scores',
    CompanyProfile = 'Company Profile',
    CompanyOverview = 'Company Overview',
    LatestHeadlines = 'Latest Headlines',
    FinancialSnapshot = 'Financial Snapshot',
    EarningsScore = 'CFRA Earnings Score',
    CashFlowScore = 'CFRA Cash Flow Score',
    MeetOurAnalysts = 'Meet Our Analysts',
    TopNavigation = 'Top Navigation',
}

enum FeaturesNames {
    AppCommon = 'appCommon',
    WatchlistSelectLastViewedId = 'watchlistSelectLastViewedId',
}

export type InstitutionalSelector = GenericSelector<{
    pageName?: PageNames;
    location?: Locations;
    featureName?: FeaturesNames;
    view?: Views;
}>;

export const UserPreferencesSelectors: Record<UserPreferences, InstitutionalSelector> = {
    [UserPreferences.AppCommon]: {
        featureName: FeaturesNames.AppCommon,
    },
    [UserPreferences.WatchlistSelectLastViewedId]: {
        featureName: FeaturesNames.WatchlistSelectLastViewedId,
    },
    [UserPreferences.HomePageCommon]: {
        pageName: PageNames.Home,
    },
    [UserPreferences.HomePageCompanyReportsCommon]: {
        pageName: PageNames.Home,
        location: Locations.CompanyReports,
    },
    [UserPreferences.HomePageCompanyReportsTableView]: {
        pageName: PageNames.Home,
        location: Locations.CompanyReports,
        view: Views.TableView,
    },
    [UserPreferences.HomePageRiskMonitor]: {
        pageName: PageNames.Home,
        location: Locations.RiskMonitor,
    },
    [UserPreferences.HomePageIndustryResearch]: {
        pageName: PageNames.Home,
        location: Locations.IndustryResearch,
    },
    [UserPreferences.ResearchHubSearchResults]: {
        pageName: PageNames.ResearchHub,
        location: Locations.SearchResults,
    },
    [UserPreferences.ResearchHubSearchResultsTableView]: {
        pageName: PageNames.ResearchHub,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.ResearchCompanySearchResults]: {
        pageName: PageNames.AccountingLensCompanyResearch,
        location: Locations.SearchResults,
    },
    [UserPreferences.ResearchCompanySearchResultsTableView]: {
        pageName: PageNames.AccountingLensCompanyResearch,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.ResearchIndustrySearchResults]: {
        pageName: PageNames.AccountingLensIndustryResearch,
        location: Locations.SearchResults,
    },
    [UserPreferences.ResearchIndustrySearchResultsTableView]: {
        pageName: PageNames.AccountingLensIndustryResearch,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.BespokeAdminSearchResultsTableView]: {
        pageName: PageNames.BespokeAdmin,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.BespokeEdgeSearchResults]: {
        pageName: PageNames.BespokeEdge,
        location: Locations.SearchResults,
    },
    [UserPreferences.BespokeEdgeSearchResultsTableView]: {
        pageName: PageNames.BespokeEdge,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.LegalEdgeSearchResults]: {
        pageName: PageNames.LegalEdge,
        location: Locations.SearchResults,
    },
    [UserPreferences.LegalEdgeSearchResultsTableView]: {
        pageName: PageNames.LegalEdge,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.WatchlistResultsTable]: {
        pageName: PageNames.Watchlists,
    },
    [UserPreferences.AccountingLensRiskMonitor]: {
        pageName: PageNames.AccountingLens,
        location: Locations.RiskMonitor,
    },
    [UserPreferences.ScoresResultsCard]: {
        pageName: PageNames.Scores,
        location: Locations.SearchResults,
    },
    [UserPreferences.CompanyProfileCompanyHeadlines]: {
        pageName: PageNames.CompanyProfile,
        location: Locations.CompanyHeadlines,
    },
};
