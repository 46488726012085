import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import React, { useLayoutEffect } from 'react';

type PageProps = AnalyticsPageProps & {
    children?: React.ReactNode;
};

export const Page = ({ children, ...props }: PageProps) => {
    useLayoutEffect(() => {
        globalThis.analytics?.registerPageView?.(props);
    }, [props]);

    return <AnalyticsDataContextProvider isPageLevelContext>{children}</AnalyticsDataContextProvider>;
};
