import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { BannerCard, childContainerStyles } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { DividerStyle2 } from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { ItemVariant5 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant5';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    ApiNames,
    ProductLid,
    RequestTypes,
    ResearchTypeId,
    TIME_IN_HRS,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Stack } from '@mui/material';
import { getReportOpenAnalyticsCallback, getReportProps } from 'analytics/utils';
import TrendingBanner from 'assets/images/trending_banner.svg';
import { getAnalyticsActionDataForLink } from 'components/AgGrid/utils';
import { getLockModal } from 'components/LockComponent/getLockModalContent';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Locations } from 'utils/preferences';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import {
    GetOptionsBlankContainer,
    GetOptionsContainer,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';

const ALLOWED_RESEARCH_IN_TRENDING_SECTION = [
    ResearchTypeId.FrsNotebooks,
    ResearchTypeId.FrsEarningsAlert,
    ResearchTypeId.LegalEducational,
    ResearchTypeId.LegalAlert,
    ResearchTypeId.LegalPipeline,
    ResearchTypeId.LegalPipelineSpecialInterestList,
    ResearchTypeId.LegalReport,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsDiagnosticReport,
];

interface TrendingCardProps {
    OptionsContainer?: ReturnType<GetOptionsContainer | GetOptionsBlankContainer>;
    onLinkClickCallback?: () => void | undefined;
}

export function TrendingCard({ OptionsContainer, onLinkClickCallback }: TrendingCardProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [trendingReportIds, setTrendingReportIds] = useState([]);
    const [elapsedTimeHrs, setElapsedTimeHrs] = useState(TIME_IN_HRS.ONE_DAY);

    const usageTrendingQuery = sendSingleRequest?.(
        {
            productLid: ProductLid.ForensicAccountingResearch,
            usageTypeLid: UsageTypeLid.PageView,
            size: 10,
            elapsedTimeHrs,
            researchReportTypeId: ALLOWED_RESEARCH_IN_TRENDING_SECTION.join(','),
            requestBody: {},
            config: {},
        },
        {
            requestType: RequestTypes.GET,
            path: 'usage-trending',
            queryKeyFirstElement: 'usageTrendingQuery',
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<any>;

    const trendingScreenerQuery = sendSingleRequest?.(
        {
            path: 'research/screener',
            size: 10,
            securityType: 'research',
            view: 'research_hub',
            viewMode: 'tease',
            requestBody: {
                filters: {
                    values: {
                        'insights.research_report._id': {
                            values: trendingReportIds,
                        },
                    },
                },
            },
            config: {
                enabled: trendingReportIds.length !== 0,
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'research/screener',
            queryKeyFirstElement: 'trendingResearchScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    useEffect(() => {
        if (usageTrendingQuery?.data?.data) {
            const reportIds = usageTrendingQuery?.data?.data?.map((d: any) => d.research_report_opensearch_id) || [];
            if (reportIds.length === 0 && elapsedTimeHrs === TIME_IN_HRS.ONE_DAY) {
                setElapsedTimeHrs(TIME_IN_HRS.THREE_DAYS);
            } else {
                setTrendingReportIds(reportIds);
            }
        }
    }, [elapsedTimeHrs, usageTrendingQuery?.data?.data]);

    const trendingResearchData = useMemo(() => {
        const data: any[] = [];
        if (trendingReportIds && !trendingScreenerQuery?.isLoading && trendingScreenerQuery?.data?.results?.research) {
            trendingReportIds.forEach((id: any, idx: number) => {
                const research = trendingScreenerQuery?.data?.results?.research.find((item: any) => item.id === id);
                if (research) {
                    data.push(research);
                }
            });
        }
        return data;
    }, [trendingReportIds, trendingScreenerQuery?.isLoading, trendingScreenerQuery?.data?.results?.research]);

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    if (OptionsContainer) {
        return (
            <OptionsContainer>
                <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                <TrendingStackComponent
                    trendingResearchData={trendingResearchData}
                    analyticsDataPickerRef={analyticsDataPickerRef}
                    onLinkClickCallback={onLinkClickCallback}
                />
            </OptionsContainer>
        );
    } else {
        return (
            <>
                <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                <TrendingBannerComponent
                    trendingResearchData={trendingResearchData}
                    isLoading={usageTrendingQuery?.isLoading || trendingScreenerQuery?.isLoading}
                    analyticsDataPickerRef={analyticsDataPickerRef}
                />
            </>
        );
    }
}

function TrendingStackComponent(props: {
    analyticsDataPickerRef: React.RefObject<AnalyticsDataPickerRefValue>;
    trendingResearchData: any[];
    onLinkClickCallback?: () => void;
}) {
    const analyticsDataPickerRef = props.analyticsDataPickerRef;
    const navigate = useNavigate();
    const onPillClickHandler = useCallback((url: string) => navigate(url), [navigate]);

    return (
        <Stack divider={<DividerStyle2 />} spacing={1}>
            {props.trendingResearchData.map((research: any, idx: number) => {
                const primaryTickers =
                    research?.research_report_security__trading?.filter((d: any) => d?.is_primary) || [];

                const hasLock = research?.research_report.s3_pdf_url === '';
                const middleTitleHref = `/pdf/${research.id}`;

                return (
                    <AnalyticsDataContextProvider
                        key={idx}
                        cfraDataLocal={{
                            actionData: getReportProps({ research: [research] }),
                        }}>
                        <ItemVariant5<ScreenerResearchData>
                            key={idx}
                            index={idx}
                            topText={research?.research_report?.research_type_name || ''}
                            topSubText={moment(research?.research_report.publish_timestamp).format('MMMM D, YYYY')}
                            middleSubText={research?.research_report?.teaser || ''}
                            maxMiddleSubTextLength={100}
                            middleTitle={research?.research_report?.title}
                            href={middleTitleHref}
                            useLinkGetterParams={[
                                {
                                    ...researchLinkGetterParams[0],
                                    ...getReportOpenAnalyticsCallback({
                                        reportId: research.id,
                                        analyticsDataPickerRef,
                                    }),
                                },
                            ]}
                            bottomPillItems={primaryTickers?.map((company: any) => ({
                                content: company?.research_report_security?.security_trading?.ticker_symbol,
                                tooltipContent: company?.research_report_security?.company.company_name,
                                onClick: () => {
                                    const analyticsActionData = getAnalyticsActionDataForLink({
                                        resultValue: [research],
                                        linkData: company?.research_report_security,
                                        analyticsActionTrackingMode: ['research', 'ticker'],
                                    });

                                    if (analyticsActionData && analyticsDataPickerRef) {
                                        analyticsDataPickerRef.current?.registerAction({
                                            ...analyticsActionData,
                                            action: joinWithDelimiter({
                                                values: [
                                                    analyticsActionData.action || '',
                                                    company?.research_report_security?.security_trading?.ticker_symbol,
                                                ],
                                            }),
                                        });
                                    }

                                    setTimeout(
                                        () =>
                                            onPillClickHandler(
                                                `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                            ),
                                        1000,
                                    );

                                    if (props.onLinkClickCallback) props.onLinkClickCallback();
                                },
                            }))}
                            bottomPillItemsThreshold={3}
                            handleOuterSetRequestParamsProps={(setRequestParamsProps) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(research.id));
                            }}
                            hasLock={hasLock}
                            ModalComponent={getLockModal({
                                content: research?.research_report?.title,
                                reportType: research?.research_report?.research_type_name,
                            })}
                        />
                    </AnalyticsDataContextProvider>
                );
            })}
        </Stack>
    );
}

function TrendingBannerComponent(props: {
    isLoading: boolean;
    trendingResearchData: any[];
    analyticsDataPickerRef: React.RefObject<AnalyticsDataPickerRefValue>;
}) {
    return (
        <BannerCard
            title={Locations.Trending}
            bannerImage={TrendingBanner}
            containerStyles={{ height: '100%' }}
            childrenContainerSx={(theme: any) => ({
                ...childContainerStyles,
                [theme.breakpoints.down(1000)]: { maxHeight: 'none' },
            })}
            isLoading={props.isLoading}
            noResults={(props.trendingResearchData.length || 0) === 0}>
            <TrendingStackComponent
                trendingResearchData={props.trendingResearchData}
                analyticsDataPickerRef={props.analyticsDataPickerRef}
            />
        </BannerCard>
    );
}
