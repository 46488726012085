export async function getFontsBase64String(filePaths: string[]) {
    try {
        const promises = filePaths.map((filePath) => convertFileToBase64(filePath));
        const base64Strings = await Promise.all(promises);
        return base64Strings;
    } catch (error) {
        console.error('Error converting files to base64:', error);
    }
}

export async function convertFileToBase64(filePath: string) {
    try {
        const response = await fetch(filePath);
        const blob = await response.blob();
        return convertBlobToBase64(blob);
    } catch (error) {
        console.error(`Error converting file ${filePath} to base64:`, error);
        return null;
    }
}

export function convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export function decodeUrlToken(urlQueryParams: string): Record<string, any> {
    try {
        const queryParams = new URLSearchParams(urlQueryParams);
        const base64Token = queryParams.get('token')?.trim();
        if(!base64Token){
            return {};
        }

        const payload = decodeUrlBase64Utf8Json(base64Token);
        
        return payload && typeof payload === 'object' ? payload : {};
    } catch(err) {
        console.error("Error Decoding URL Token");
        return {};
    }
};

function isURLEncoded(str: string): boolean {
    try {
        return str !== decodeURIComponent(str);
    } catch (e) {
        return false;
    }
}

function isBase64String(str:string){
    if (!str || !/^[A-Za-z0-9+/=]+$/.test(str)) {
        return false;
    }
    return true
}

function decodeBase64(str:string){
    const isBase64Encoded = isBase64String(str)
    if(isBase64Encoded){
        return atob(str)
    }
    return str
}

function decodeUrlString(str: string){
    const isUrlEncoded = isURLEncoded(str)
    if(isUrlEncoded){
        return decodeURIComponent(str)
    }
    return str
}

export function decodeUrlBase64Utf8Json(encodedToken: string): Record<string, any> {
    try {
        // ideal encode token flow (json>json_string>base64_string>url_encoded_string)
        // ideal decode token flow (url_encoded_string>base64_string>json_string>json)
        let base64String = decodeUrlString(encodedToken);
        let utf8String = decodeBase64(base64String);

        utf8String = decodeUrlString(utf8String); //url decoded again if its still url_encoded else returns same string
        return JSON.parse(utf8String);
    } catch (err) {
        console.error("Decoding Error:");
        return {};
    }
}