import { ManageUserWatchlist } from 'components/watchlists/ManageUserWatchlist';
import { ShowSplaceScreen } from 'components/watchlists/ShowSplaceScreen';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';

export function WatchlistHome() {
    return (
        <ShowSplaceScreen
            onFalseComponent={
                <ManageUserWatchlist
                    containerStyles={{
                        paddingBottom: '0px',
                    }}
                    title='Watchlists'
                    watchlistCompaniesProps={{
                        addWatchListStarColumn: true,
                        getNumberOfResultsText: (numberOfResults) => `${numberOfResults || 'No'} Companies`,
                        preferencesConfiguration: {
                            useUserPreferencesProps,
                            selectorConfiguration: {
                                selector: UserPreferencesSelectors[UserPreferences.WatchlistResultsTable],
                            },
                        },
                        enableSavingUserSortModel: true,
                        enableSavingUserColumnsOrder: true,
                        enableSavingUserColumnsVisibility: true,
                        enableSavingUserColumnsWidths: true,
                        useDisableColumnFlexOnResize: true,
                        useColumnWidthsFromColumnDefs: true,
                    }}
                    showExport={true}
                    showUpdateWatchlist={true}
                />
            }
        />
    );
}
