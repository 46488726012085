import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { Box } from '@mui/material';
import { earningsChartTheme } from 'components/themes/theme';
import moment from 'moment';
import { useContext } from 'react';
import { UseQueryResult } from 'react-query';
import { getQueryConfig, getScoreHistory, getScoresEntitlements } from '../api/company';
import { ComponentProps } from '../types/company';
import { ScoreHistory } from './CFRAScoreHistory';
import { CFRAScores, CFRAScoresProps } from './CFRAScores';
import { BarChart } from './ScoreBarChart';
import { useHandleLinkClick } from './useHandleLinkClick';

export function EarningsScore({ company, reportID, enableQuery, hasLock, userEntitlements }: ComponentProps) {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { userEarningsScoresEntitlements } = getScoresEntitlements(userEntitlements);
    const companySecurityIds = enableQuery
        ? company.data?.results.company[0].id
        : null;
        
    const scoreHistoryData = sendSingleRequest?.(
        getScoreHistory(
            enableQuery || false,
            {
                'company_security.security_trading.cfra_security_trading_id': { values: [companySecurityIds] },
            },
            'earnings_score_history',
        ),
        getQueryConfig('earningsScoreHistoryData', 'company'),
    ) as UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;

    const handleLinkClick = useHandleLinkClick();

    if (scoreHistoryData.isLoading) return <ETFCard.ETFCard isLoading={true} />;

    if (!enableQuery) return <ETFCard.ETFEmptyCard cardLabel='CFRA Earnings Score'></ETFCard.ETFEmptyCard>;

    const companyDetails = company.data?.results.company[0] as any;
    const earningScores = companyDetails.frs_earnings_score_drivers;
    const trading = companyDetails.company_security?.security_trading;

    const stackContent = {
        title: 'Earnings Score',
        color: '#002B5A',
        value: earningScores?.decile,
        date: earningScores?.decile ? moment(earningScores?.date).format('MMMM YYYY') : '',
        textTitle: 'earnings risk',
        ticker: `${trading.ticker_symbol} - ${trading.exchange_lid.exchange_code}`,
        handleClick: () => {
            handleLinkClick(reportID || '');
        },
        hasLock: hasLock,
    } as CFRAScoresProps;

    const entitled = userEarningsScoresEntitlements.length > 0;

    return (
        <ETFCard.ETFCard>
            <Grid container>
                <Grid item xs={12} sx={{ paddingBottom: '15px' }}>
                    <CFRAScores {...stackContent} entitled={entitled} />
                </Grid>
                <Grid item xs={12}>
                    <ScoreHistory
                        data={scoreHistoryData.data}
                        entitled={entitled}
                        dateField={'frs_earnings_score_history.date'}
                        scoreLabel={'Earnings Score'}
                        responsePropName={'frs_earnings_score_history'}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ paddingTop: { xs: '15px', sm: '0' } }}
                    position={!entitled ? 'relative' : 'static'}>
                    {!entitled && (
                        <Box
                            sx={{
                                width: '70%',
                                height: '70%',
                                position: 'absolute',
                                left: '25%',
                                top: '15%',
                                zIndex: 100,
                                backdropFilter: 'blur(14px)',
                                display: 'flex',
                            }}></Box>
                    )}
                    <BarChart
                        chartTitle='CFRA Score Accrual Drivers'
                        data={earningScores}
                        metadataFields={company.data?._metadata.fields || []}
                        parentField='frs_earnings_score_drivers'
                        theme={earningsChartTheme}
                        entitled={entitled}
                    />
                </Grid>
            </Grid>
        </ETFCard.ETFCard>
    );
}
