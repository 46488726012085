import NotEntitledIcon from '@cfra-nextgen-frontend/shared/src/assets/images/NotEntitledIcon.svg';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { PopupVariant1ModalSx } from '@cfra-nextgen-frontend/shared/src/components/Popup/PopupVariant1';
import {
    TypographyStyle11,
    TypographyStyle33,
    TypographyStyle6,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, useMediaQuery } from '@mui/material';
import { ContactForm } from 'components/ContactForm/ContactForm';
import { ComponentProps, useEffect } from 'react';
import { PageNames } from 'utils/enums';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export type LockModalProps = {
    handleClose: () => void;
    disableModalPortal?: boolean;
    modalOpen: boolean;
    handleCloseModal: () => void;
    onSubmitNotEntitledNewFormData?: ComponentProps<typeof ContactForm>['onSubmitNotEntitledNewFormData'];
};

export function LockModal({
    handleClose,
    disableModalPortal,
    modalOpen,
    handleCloseModal,
    onSubmitNotEntitledNewFormData,
}: LockModalProps) {
    const isPhone = useMediaQuery('(max-height:700px)');
    const isPhoneIconButton = useMediaQuery('(max-height:720px)');
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    useEffect(() => {
        if (modalOpen) {
            // give some time for the case other action is capturing
            setTimeout(() => {
                analyticsDataPickerRef.current?.registerAction({
                    action: joinWithDelimiter({
                        values: ['open modal', 'Not Entitled Modal', onSubmitNotEntitledNewFormData?.title],
                    }),
                    reportType: onSubmitNotEntitledNewFormData?.reportType,
                });
            }, 500);
        }
    }, [modalOpen, onSubmitNotEntitledNewFormData]);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <ETFModal
                disableModalPortal={disableModalPortal}
                getOpenComponent={() => <></>}
                title={
                    <img
                        src={NotEntitledIcon}
                        alt=''
                        style={{
                            display: 'flex',
                            marginLeft: isPhoneIconButton ? '140px' : '125px',
                            width: isPhoneIconButton ? '60px' : '81px',
                            height: isPhoneIconButton ? '60px' : '75px',
                        }}
                    />
                }
                closeButtonFontSize={22}
                modalBoxStyles={{
                    width: '377px',
                    height: '682px',
                    borderRadius: '15px',
                    padding: useMediaQuery('(max-height:620px)') ? '15px' : '20px',
                }}
                descriptionStyle={{
                    boxShadow: 'none',
                }}
                titleStyle={{ ...PopupVariant1ModalSx.titleStyle, boxShadow: 'none', paddingBottom: '0px' }}
                dateStyle={{
                    boxShadow: 'none',
                    color: '#A09F9F',
                    fontSize: '12px',
                    paddingBottom: '0',
                    lineHeight: '1.5em',
                }}
                zIndex={5000}
                externalOpenModal={modalOpen}
                keepContentOnClose={false}
                callbackOnClose={handleCloseModal}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ textAlign: 'center', display: 'inline', marginTop: '5px' }}>
                        <TypographyStyle6>
                            <span style={{ fontSize: isPhone ? '14px' : '18px' }}>
                                It looks like you don't have access to this content.
                            </span>
                        </TypographyStyle6>
                    </Box>
                    <Box sx={{ textAlign: 'center', display: 'inline' }}>
                        <TypographyStyle11>
                            <span style={{ fontWeight: 'bold', fontSize: isPhone ? '10px' : '13.5px' }}>
                                But don't worry
                            </span>
                            <span style={{ fontSize: isPhone ? '10px' : '13.5px' }}>
                                , we're here to help! To learn more about gaining access and to request a
                            </span>
                            <span style={{ fontWeight: 'bold', fontSize: isPhone ? '10px' : '13.5px' }}>
                                {' '}
                                trial
                            </span>
                            <span style={{ fontSize: isPhone ? '10px' : '13.5px' }}>
                                , please reach out to our friendly sales team.
                            </span>
                        </TypographyStyle11>
                    </Box>
                    <ul style={{ paddingLeft: '15px', marginBottom: isPhone ? '10px' : '15px', marginTop: '5px' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                            <TypographyStyle33 sx={{ display: 'inline-block', fontSize: isPhone ? '11px' : '15px' }}>
                                Email:
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        paddingLeft: '4px',
                                        display: 'inline-block',
                                        fontSize: isPhone ? '11px' : '15px',
                                    }}
                                    href='mailto:cservices@cfraresearch.com'>
                                    cservices@cfraresearch.com
                                </a>
                            </TypographyStyle33>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <TypographyStyle33 style={{ fontSize: isPhone ? '11px' : '15px' }}>
                                Phone: (800) 220-0502
                            </TypographyStyle33>
                        </Box>
                    </ul>
                    <Box
                        sx={{
                            width: '100%',
                            position: 'relative',
                            paddingTop: '5px',
                            marginBottom: isPhone ? '-5px' : 'unset',
                            '&::after': {
                                content: '""',
                                position: 'absolute',
                                left: 0,
                                bottom: '10px',
                                width: '100%',
                                height: '1px',
                                backgroundImage: 'radial-gradient(circle, #aaa 1px, transparent 1px)',
                                backgroundSize: '5px 1px',
                            },
                        }}
                    />
                    <Box sx={{ paddingBottom: '5px' }}>
                        <TypographyStyle11>
                            <span style={{ fontSize: isPhone ? '9px' : '12px' }}>
                                Or, simply <span style={{ fontWeight: 'bold' }}>fill out the form below</span>, and
                                we'll get back to you shortly!
                            </span>
                        </TypographyStyle11>
                    </Box>
                    <ContactForm
                        thankyouMessage='Thank you for reaching out to us. Your query has been received, and our team will respond to you as soon as possible.'
                        hasCompanyField={false}
                        hasTitleName={false}
                        buttonCenterStyle={true}
                        buttonIsSend={true}
                        backgroundStyle={true}
                        onSubmitForNotEntitled={true}
                        onSubmitForContacts={false}
                        onSuccess={() => handleClose()}
                        titleCardName={'More Insights'}
                        titlePageName={PageNames.ResearchHub}
                        subjectTitle={'Sales Inquiry via Platform'}
                        lineInputWidth={true}
                        marginStyle
                        paddingSpacingProp
                        messageRequiredStyle
                        messageRequiredSizeStyle={isPhoneIconButton ? true : false}
                        onSubmitNotEntitledNewFormData={onSubmitNotEntitledNewFormData}
                        borderStyle
                        buttomPaddingStyle={isPhoneIconButton ? true : false}
                    />
                </Box>
            </ETFModal>
        </>
    );
}
