import { Page } from 'analytics/Page';
import { AnalystDirectoryElement } from 'features/analystDirectory/routes/AnalystDirectoryRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { LegalEdgeHome } from '../components/LegalEdgeHome';

export function LegalEdgeRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/'
                element={
                    <Page name={PageNames.LegalEdge}>
                        <LegalEdgeHome />
                    </Page>
                }
            />
            <Route
                path='/analyst-directory'
                element={
                    <Page name={PageNames.LegalEdgeAnalystDirectory}>
                        <AnalystDirectoryElement />
                    </Page>
                }
            />
        </Routes>
    );
}
