import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { BannerCard, childContainerStyles } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { CFRASelectVariant4 } from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRASelectVariant4';
import { PillVariant2 } from '@cfra-nextgen-frontend/shared/src/components/Pill/PillVariant2';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    TypographyStyle10,
    TypographyStyle24,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import {
    ApiNames,
    ProductLid,
    RequestTypes,
    TimeRanges,
    TimeRangesToHours,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SelectChangeEvent, Stack } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { Locations } from 'utils/preferences';
import { companiesUsageQueryKey, searchTermsUsageQueryKey } from 'utils/usage';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

const pillTextSx = {
    lineHeight: 1,
    maxWidth: '115px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

type PopularSearchesProps = {
    onSearchTermPillClick: (searchTerm: string) => void;
    onCompanyPillClick: (primaryEntityId: string, ticker: string, exchangeCode: string) => void;
};

type companyPillData = {
    primary_entity_id: string;
    ticker: string;
    exchange_code: string;
    count: number;
};

type searchTermPillData = {
    text: string;
    count: number;
};

const commonUsageRequestConfig = {
    requestType: RequestTypes.GET,
    path: 'usage-trending',
    apiName: ApiNames.UserManagement,
};

const commonUsageRequestSearchByParams = {
    productLid: ProductLid.ForensicAccountingResearch,
    usageTypeLid: UsageTypeLid.Search,
    size: 12,
    requestBody: {},
    config: {},
};

export function PopularSearches({ onSearchTermPillClick, onCompanyPillClick }: PopularSearchesProps) {
    const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRanges>(TimeRanges.Today);
    const [pillsData, setPillsData] = useState<Array<companyPillData | searchTermPillData> | undefined>();

    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { setCfraDataLocalRef } = analyticsDataContext || {};

    useEffect(() => {
        setCfraDataLocalRef?.current?.(
            (previousValue) => ({
                ...previousValue,
                actionData: {
                    ...previousValue?.actionData,
                    dateRange: selectedTimeRange,
                },
            }),
            ({ cfraDataLocalInputProp }) =>
                cfraDataLocalInputProp?.actionData?.cardName !== Locations.PopularSearches,
        );
    }, [setCfraDataLocalRef, selectedTimeRange]);

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest not found');
    }

    const companiesUsageQuery = sendSingleRequest?.(
        {
            ...commonUsageRequestSearchByParams,
            countField: 'primary_entity_id',
            groupBy: ['primary_entity_id', 'detail'],
            elapsedTimeHrs: TimeRangesToHours[selectedTimeRange],
        },
        {
            ...commonUsageRequestConfig,
            queryKeyFirstElement: companiesUsageQueryKey,
        },
    ) as UseQueryResult<any>;

    const searchTermsUsageQuery = sendSingleRequest?.(
        {
            ...commonUsageRequestSearchByParams,
            countField: 'text',
            groupBy: ['text'],
            elapsedTimeHrs: TimeRangesToHours[selectedTimeRange],
        },
        {
            ...commonUsageRequestConfig,
            queryKeyFirstElement: searchTermsUsageQueryKey,
        },
    ) as UseQueryResult<any>;

    useEffect(() => {
        if (searchTermsUsageQuery.isLoading || companiesUsageQuery.isLoading) {
            return;
        }

        const mostSearchedData = [
            ...(companiesUsageQuery?.data?.data
                ?.map(
                    (data: {
                        primary_entity_id: string;
                        detail?: { ticker?: string; exchange_code?: string };
                        count: number;
                    }) => ({
                        primary_entity_id: data.primary_entity_id,
                        ticker: data?.detail?.ticker,
                        exchange_code: data?.detail?.exchange_code,
                        count: data.count,
                    }),
                )
                .filter((data?: { primary_entity_id?: string }) => data?.primary_entity_id) || []),
            ...(searchTermsUsageQuery?.data?.data
                ?.map((data: { text: string; count: number }) => ({
                    text: data.text,
                    count: data.count,
                }))
                .filter((data: { text: string }) => data.text) || []),
        ]
            .sort((a, b) => b.count - a.count)
            .slice(0, 10);

        setPillsData(mostSearchedData);
    }, [
        searchTermsUsageQuery.data,
        companiesUsageQuery.data,
        companiesUsageQuery.isLoading,
        searchTermsUsageQuery.isLoading,
    ]);

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />

            <BannerCard
                bannerImage={''}
                title={Locations.PopularSearches}
                headerConfiguration={{
                    boxSx: {
                        height: 'auto',
                        padding: '18px 20px 11px 20px',
                    },
                    containerSx: {
                        justifyContent: 'space-between',
                    },
                    titleSx: {
                        paddingTop: '3px',
                        lineHeight: 1.5,
                    },
                    titleXs: false,
                    headerComponentsXs: false,
                }}
                childrenContainerSx={{
                    ...childContainerStyles,
                    padding: '0px',
                    marginRight: '10px',
                    marginTop: '0px',
                    maxHeight: '290px',
                }}
                isLoading={!pillsData}
                noResults={pillsData && pillsData.length === 0}
                headerComponents={[
                    <CFRASelectVariant4
                        key='timeFilter'
                        selectItems={Object.values(TimeRanges)}
                        handleSelectionChange={(e: SelectChangeEvent<unknown>) => {
                            analyticsDataPickerRef.current?.registerAction({
                                action: joinWithDelimiter({
                                    values: ['change date range via select', String(e.target.value)],
                                }),
                            });

                            setSelectedTimeRange(e.target.value as TimeRanges);
                        }}
                        currentSelection={selectedTimeRange}
                        placeholder='Time range'
                    />,
                ]}>
                {pillsData && pillsData.length > 0 && (
                    <Stack direction='row' sx={{ flexWrap: 'wrap', gap: 1 }}>
                        {pillsData.map((pill, index) => {
                            if ((pill as companyPillData)?.primary_entity_id !== undefined) {
                                const companyPill = pill as companyPillData;
                                const companyPillText = `${companyPill.ticker}: ${companyPill.exchange_code}`;
                                const pillContent = (
                                    <TypographyStyle24 sx={pillTextSx}>{companyPillText}</TypographyStyle24>
                                );
                                return (
                                    <PillVariant2
                                        key={index}
                                        content={pillContent}
                                        tooltipContent={companyPillText}
                                        onClick={() => {
                                            analyticsDataPickerRef.current?.registerAction({
                                                action: joinWithDelimiter({
                                                    values: ['click on company pill', companyPillText],
                                                }),
                                                companyCfraId: companyPill.primary_entity_id,
                                                companyTicker: companyPill.ticker,
                                                companyExchange: companyPill.exchange_code,
                                            });
                                            onCompanyPillClick(
                                                companyPill.primary_entity_id,
                                                companyPill.ticker,
                                                companyPill.exchange_code,
                                            );
                                        }}
                                    />
                                );
                            }

                            const searchTermPill = pill as searchTermPillData;
                            const pillContent = (
                                <TypographyStyle10 sx={pillTextSx}>{searchTermPill.text}</TypographyStyle10>
                            );
                            return (
                                <PillVariant2
                                    key={index}
                                    content={pillContent}
                                    tooltipContent={searchTermPill.text}
                                    onClick={() => {
                                        analyticsDataPickerRef.current?.registerAction({
                                            action: joinWithDelimiter({
                                                values: ['click on search term pill', searchTermPill.text],
                                            }),
                                        });
                                        onSearchTermPillClick(searchTermPill.text);
                                    }}
                                />
                            );
                        })}
                    </Stack>
                )}
            </BannerCard>
        </>
    );
}
