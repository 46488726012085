import { Hit, SearchResult } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types/opensearch';
import { getSumOfAllValues } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export function getSuggesterInfiniteOptionsProps<T>({ setRowsCount }: { setRowsCount?: (count?: number) => void } = {}) {
    return {
        getRowsData: function (data: SearchResult<T>): Array<Hit<T>> {
            setTimeout(() => {
                setRowsCount?.(data?.hits?.total?.value);
            }, 0);

            return data?.hits?.hits;
        },
    };
}

export function getSuggesterInfiniteRequestParamsConfig<T>(size: number) {
    return {
        requestType: RequestTypes.GET,
        apiName: ApiNames.Research,
        getNextPageParam: (lastPage: unknown, allPages: Array<unknown>) => {
            const lastPageLength = (lastPage as SearchResult<T>)?.hits?.hits?.length;

            if (!lastPageLength || lastPageLength < size) {
                return undefined;
            }

            return getSumOfAllValues(allPages.map((page) => (page as SearchResult<T>)?.hits?.hits?.length || 0));
        },
    };
}
