import { cfraCompanyIdField } from 'components/Watchlist/ManageWatchlist';
import { useWatchlistCompaniesData } from 'components/watchlists/hooks/useWatchlistCompaniesData';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { useWatchlistAnalytics } from 'components/watchlists/hooks/useWatchlistAnalytics';

export function WatchlistAnalyticsDataTracker({
    cfraCompanyIds,
    selectedWatchlist,
}: {
    cfraCompanyIds?: Array<string>;
    selectedWatchlist?: SavedItemTypes;
}) {
    const { getCompaniesBySecIdQuery } = useWatchlistCompaniesData({
        secTradingIdsFilter: cfraCompanyIds && cfraCompanyIds.length > 0 ? { [cfraCompanyIdField]: { values: cfraCompanyIds } } : undefined,
    });

    useWatchlistAnalytics({
        selectedWatchlist,
        getWatchlistCompaniesQry: getCompaniesBySecIdQuery,
    });

    return null;
}
