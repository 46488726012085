import { ETFCard, RoundedIconTextButton, Typography } from '@cfra-nextgen-frontend/shared';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { LockIconComponent } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/LockIconComponent';
import { ReadMoreCore } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMoreCore';
import { TypographyStyle26 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box } from '@mui/material';
import { LockModal } from 'components/LockComponent/LockModal';
import { BespokeTheme, PageTheme } from 'components/themes/theme';
import { useRef, useState } from 'react';
import { maxPageWidthInPx } from 'utils/lookAndFeel';
import { ComponentProps } from '../types/company';
import { useHandleLinkClick } from './useHandleLinkClick';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { PageNames } from 'utils/enums';
import { useNavigate } from 'react-router-dom';

export function CompanyProfile({ company, reportID, hasLock }: ComponentProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleLinkClick = useHandleLinkClick();
    const navigate = useNavigate();

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    if (company.isLoading) return <ETFCard.ETFCard isLoading={company.isLoading} />;

    if (company.data?.results?.company === undefined || company.data?.results?.company.length === 0) {
        return <ETFCard.ETFEmptyCard cardLabel='Company Profile' />;
    }

    const sector = company.data.results.company[0].company_security.company_sector as any;
    const description =
        company.data.results.company[0].company_security.company_description?.business_description || '';

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <ETFCard.ETFCard isLoading={company.isLoading} containerStyles={{ p: '28px' }}>
                <Box display='flex' flexDirection='column'>
                    <Box pb='15px' borderBottom='1px solid #E4E4E4'>
                        <Typography variant='cardSubCaption'>Company Profile</Typography>
                    </Box>
                    <Box pt='14px' borderBottom='1.5px dotted #CCCCCC'>
                        <TypographyStyle26>Industry/Sector:</TypographyStyle26>
                        <Typography variant='cardRegular' sx={{ lineHeight: { xs: '20px', sm: '36px' } }}>
                            {sector?._lookup_gics_ind_lid?.value}
                        </Typography>
                    </Box>
                    <Box pt='14px' borderBottom='1.5px dotted #CCCCCC'>
                        <TypographyStyle26 sx={{ color: '#57626A', display: { xs: 'none', sm: 'block' } }}>
                            Industry Risk Assessment Profiles:
                        </TypographyStyle26>
                        <TypographyStyle26 sx={{ color: '#57626A', display: { xs: 'block', sm: 'none' } }}>
                            IRAPs:
                        </TypographyStyle26>
                        {hasLock && reportID !== '' && (
                            <LockIconComponent
                                modalOpen={modalOpen}
                                setModalOpen={setModalOpen}
                                ModalComponent={LockModal}
                            />
                        )}
                        <ETFLinkButton
                            onClick={() => {
                                if (hasLock && reportID !== '') {
                                    setModalOpen(true);
                                } else if (handleLinkClick) {
                                    if (reportID === '') {
                                        analyticsDataPickerRef.current?.registerAction({
                                            action: joinWithDelimiter({
                                                values: ['click on link', sector?.lookup_gics_subind_lid?.value],
                                            }),
                                            navigateTo: PageNames.AccountingLensIndustryResearch,
                                        });

                                        setTimeout(() => window.open('/accounting-lens/reports/industry'), 500);
                                    } else {
                                        handleLinkClick(reportID || '');
                                    }
                                }
                            }}
                            text={reportID !== ''?sector?.lookup_gics_subind_lid?.value:"Full list of IRAPs"}
                            startIcon={
                                !hasLock && reportID !== ''? (
                                    <CustomSvgIcon
                                        component={PdfIcon}
                                        viewBox='0 0 18 18'
                                        sx={{
                                            width: '18px',
                                            fill: '#002B5A',
                                            '&:hover': {
                                                fill: '#007AB9',
                                            },
                                        }}
                                    />
                                ) : undefined
                            }
                            sx={{
                                ...PageTheme.typography.cardLink,
                                paddingLeft: '3px',
                                textAlign: 'left',
                                lineHeight: { xs: '20px', sm: '36px' },
                            }}
                        />
                    </Box>
                    <Box pt='14px'>
                        <TypographyStyle26 pb='8px'>Request Report:</TypographyStyle26>
                        <RoundedIconTextButton
                            type='submit'
                            theme={BespokeTheme}
                            buttonText={'Request Bespoke'}
                            onClickCallback={() => {
                                analyticsDataPickerRef.current?.registerAction({
                                    action: joinWithDelimiter({
                                        values: ['click on button', 'Request Bespoke'],                                      
                                    }),
                                    navigateTo: PageNames.BespokeServices,
                                });
                                navigate("/bespoke/services")                  
                            }}
                            sx={{ borderRadius: '5px' }}
                        />
                    </Box>
                    <Box pt='15px' mt='15px' borderTop='1px solid #E4E4E4'>
                        <TypographyStyle26>Description</TypographyStyle26>
                    </Box>
                    <Box>
                        <Typography variant='cardDescriptionText'>
                            <ReadMoreCore
                                textLengthToShowReadMore={300}
                                modalTitle='Company Description'
                                linkText='More'
                                showDots={true}
                                linkSx={{
                                    ...PageTheme.typography.cardLink,
                                    justifyContent: 'left',
                                    lineHeight: '22px',
                                    letterSpacing: '0',
                                }}
                                text={description}
                                maxWidth={maxPageWidthInPx}
                            />
                        </Typography>
                    </Box>
                </Box>
            </ETFCard.ETFCard>
        </>
    );
}
