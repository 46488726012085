import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, styled, SxProps, Typography } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { TypographyStyle10 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledBlueBoldText = styled(Typography)(() => ({
    paddingRight: '8px',
    lineHeight: 1,
    fontFamily: fontFamilies.GraphikMedium,
    fontSize: '13.5px',
    color: '#007AB9',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const StyledBlueText = styled(TypographyStyle10)(() => ({
    textAlign: 'left',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    wordWrap: 'break-word',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

export type ItemVariant1Props = {
    leftPartLeftText: string;
    leftPartRightText: string;
    navigateUrl?: string;
    onLinkClickCallback?: () => void;
    rightPartText: string;
    textContainersSx?: {
        left: SxProps;
        right: SxProps;
    }
}

export function ItemVariant1({
    leftPartLeftText,
    leftPartRightText,
    navigateUrl,
    onLinkClickCallback,
    rightPartText,
    textContainersSx,
}: ItemVariant1Props) {
    const navigate = useNavigate();
    const onLinkClickHandler = useCallback(() => {
        onLinkClickCallback?.();

        if (!navigateUrl) {
            return;
        }
        // give some time for analytics to register the action
        setTimeout(() => navigate(navigateUrl), 300);
    }, [navigate, onLinkClickCallback, navigateUrl]);

    return (
        <ButtonBase
            onClick={onLinkClickHandler}
            sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                display: 'flex',
                '&:hover': {
                    backgroundColor: '#F3F5FC',
                },
                borderRadius: '5px',
                padding: '10.875px 8px 10.875px 8px',
            }}>
            <Box sx={{ width: '50%', display: 'flex', alignItems: 'start', ...textContainersSx?.left }}>
                <StyledBlueBoldText textAlign='left'>
                    {leftPartLeftText}:{leftPartRightText}
                </StyledBlueBoldText>
            </Box>
            <Box sx={{ width: '50%', display: 'flex', alignItems: 'start', ...textContainersSx?.right }}>
                <StyledBlueText textAlign='left'>{rightPartText}</StyledBlueText>
            </Box>
        </ButtonBase>
    );
}
