import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    DataItem,
    ScreenerResearchCompanyData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { getValueByPath } from '@cfra-nextgen-frontend/shared/src/utils';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { CircularProgress } from '@mui/material';
import { SortModelItem } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { ExportButton } from 'components/watchlists/ExportButton';
import { useCallback, useContext, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { MAX_RESULTS_WINDOW, OPTIMAL_EXPORT_CHUNK_SIZE } from 'utils/api';
import { earningsFilterMetadataKey } from './ScoresCard';

export function ScoresExportButton({
    gridRef,
    companiesQuery,
    screenerCommonSearchByParams,
    filtersData,
}: {
    gridRef: React.RefObject<AgGridReact>;
    companiesQuery: UseQueryResult<ScreenerResearchCompanyData, unknown>;
    screenerCommonSearchByParams: SearchByParams;
    filtersData?: FiltersData;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const { getSsrDataExportFn, exportSSRAgGrid } = useContext(ProjectSpecificResourcesContext);

    if (!getSsrDataExportFn) {
        throw new Error('getSsrDataExportFn is not defined');
    }

    if (!exportSSRAgGrid) {
        throw new Error('exportSSRAgGrid is not defined');
    }

    const SSRDataExportFn = useCallback(
        async (sortColumns: SortModelItem[]) => {
            // for each score value + null (no score) get the data
            const resultsForEachScore = Array.from({ length: 10 }, (_, i) => i + 1).map((score) => {
                return getSsrDataExportFn?.({
                    metadataFields: companiesQuery?.data?._metadata?.fields || [],
                    requestParams: {
                        path: 'company/screener',
                        ...screenerCommonSearchByParams,
                        requestBody: getFiltersReqBody({
                            $and: [
                                screenerCommonSearchByParams.requestBody?.filters?.values,
                                {
                                    [earningsFilterMetadataKey]: { values: [score, ...(score === 1 ? [null] : [])] },
                                },
                            ],
                        }),
                        includeMetadata: false,
                        includeViewdata: false,
                    },
                    _resultsKey: 'company',
                    batchConfig: {
                        total:
                            filtersData?.data?.[earningsFilterMetadataKey]?.items?.find((item) => item.key === score)
                                ?.count || 0,
                        chunkSize: OPTIMAL_EXPORT_CHUNK_SIZE,
                        maxConcurrentRequests: 1,
                        maxResultsWindow: MAX_RESULTS_WINDOW,
                    },
                })(sortColumns);
            });

            const results = (await Promise.all(resultsForEachScore)).reduce((acc, result) => {
                return [...acc, ...result];
            }, []) as Array<DataItem>;

            // apply column sorting, use id desc as a secondary sorting
            const sortByColumn = (colId?: string, direction?: 'asc' | 'desc') => {
                results.sort((a, b) => {
                    const sortBy: (colId: string, direction: 'asc' | 'desc') => number = (
                        colId: string,
                        direction: 'asc' | 'desc',
                    ) => {
                        const aValue = getValueByPath(a, colId);
                        const bValue = getValueByPath(b, colId);

                        if (aValue > bValue) {
                            return direction === 'asc' ? 1 : -1;
                        }
                        if (aValue < bValue) {
                            return direction === 'asc' ? -1 : 1;
                        }

                        return 0;
                    };

                    let sortResult: number = 0;

                    if (colId && direction) {
                        sortResult = sortBy(colId, direction);
                    }

                    if (sortResult === 0) {
                        sortResult = sortBy('id', 'desc');
                    }

                    return sortResult;
                });
            };

            if (Array.isArray(sortColumns) && sortColumns.length > 0) {
                const sortColumn = sortColumns[0];
                const colId = sortColumn.colId;
                const direction = sortColumn.sort;
                sortByColumn(colId, direction);
            } else {
                sortByColumn();
            }

            return results;
        },
        [companiesQuery?.data?._metadata?.fields, getSsrDataExportFn, screenerCommonSearchByParams, filtersData?.data],
    );

    const exportCallback = useCallback(() => {
        setIsLoading(true);
        exportSSRAgGrid(
            gridRef,
            SSRDataExportFn,
            {
                autoSizeColumns: true,
                fileName: 'CFRA Scores',
                metadata: {
                    cardName: 'CFRA Scores',
                },
                sheetName: 'Data Details',
                useMaxAsOfDate: undefined,
                getFormattedValuesFirst: true,
            },
            companiesQuery?.data?._metadata?.fields || [],
        )
            .then((result) => {
                if (result.isLoading !== undefined) {
                    setIsLoading(result.isLoading);
                } else {
                    setIsLoading(false);
                }
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, [companiesQuery?.data?._metadata?.fields, gridRef, exportSSRAgGrid, SSRDataExportFn]);

    return (
        <ExportButton
            onClick={() => {
                if (isLoading) {
                    return;
                }

                exportCallback();
            }}
            sx={{
                marginLeft: 'auto',
            }}
            icon={isLoading ? <CircularProgress size={15} sx={{ color: 'primary.main' }} /> : undefined}
            tooltipText={isLoading ? 'Large exports may take up to 1 minute.' : 'Export'}
        />
    );
}
