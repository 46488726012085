import { ThemeOptions } from '@mui/material';
import React, { useCallback, useState, SyntheticEvent } from 'react';
import { AccordionVariant1 } from './AccordionVariant1';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export type AccordionsSectionProps = {
    options: Array<{
        icon?: string;
        label?: string;
        component?: JSX.Element;
    }>;
    themeOptions?: ThemeOptions;
    onChanges?: (index: number) => void;
};

export function AccordionsSection({ options, themeOptions }: AccordionsSectionProps) {
    const [expanded, setExpanded] = useState<number | undefined>(undefined);

    const handleChange = useCallback(
        (index: number) => (event: SyntheticEvent, isExpanded: boolean) => {
            analyticsDataPickerRef.current?.registerAction({
                action: joinWithDelimiter({
                    values: [`${isExpanded ? 'expand' : 'collapse'} accordion`, options[index].label],
                }),
            });

            setExpanded(isExpanded ? index : undefined);
        },
        [options],
    );

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            {React.Children.toArray(
                options.map((option, index) => {
                    const isExpanded = expanded === index;

                    return (
                        <AccordionVariant1
                            {...option}
                            themeOptions={themeOptions}
                            index={index}
                            handleChange={handleChange}
                            isExpanded={isExpanded}
                        />
                    );
                }),
            )}
        </>
    );
}
