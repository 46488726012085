import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { roundedTextButtonThemeV4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { RoundedTextButtonProps } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useFiltersForm } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { getFilteredFromDefaultValues } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { shadowTopStylesVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/shadows';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, Grid } from '@mui/material';
import { debounce, isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { FiltersButtonWithModalProps, FiltersFormProps } from './shared';

function StyledRoundedTextButton(props: Partial<RoundedTextButtonProps>) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <RoundedTextButton
                theme={roundedTextButtonThemeV4}
                buttonText={props.buttonText}
                {...props}
                onClickCallback={() => {
                    analyticsDataPickerRef.current?.registerAction({
                        action: joinWithDelimiter({
                            values: ['click on button', props.buttonText || ''],
                        }),
                    });

                    props.onClickCallback?.();
                }}
            />
        </>
    );
}

export function ButtonsPanel({
    submitHandler,
    callbackOnClose,
    control,
    externalFormStateStorage,
    externalChipItems,
    containerRef,
    watchlistName = '',
}: {
    submitHandler?: FiltersButtonWithModalProps['submitHandler'];
    callbackOnClose: () => void;
    control: ReturnType<typeof useFiltersForm>['control'];
    externalFormStateStorage: FiltersFormProps['externalFormStateStorage'];
    externalChipItems?: FiltersFormProps['externalChipItems'];
    containerRef?: React.RefObject<HTMLDivElement>;
    watchlistName: string;
}) {
    const [hasVerticalScroll, setHasVerticalScroll] = useState<boolean>(false);
    const [previousSelections, SetPreviousSelection] = useState({});

    // update hasVerticalScroll on container resize
    useEffect(() => {
        if (!containerRef?.current) {
            return;
        }

        const container = containerRef.current;

        const updateHasVerticalScroll = debounce(() => {
            setHasVerticalScroll(container.scrollHeight > container.clientHeight);
        }, 50);

        const resizeObserver = new ResizeObserver(() => updateHasVerticalScroll());
        resizeObserver.observe(container);

        return () => {
            resizeObserver.unobserve(container);
            updateHasVerticalScroll.cancel();
        };
    }, [containerRef]);

    const formData = useWatch({
        control: control,
    });

    // necessary to have it here (not pass through props) to trigger buttons re-render on clip click
    const {
        chipEventsManager: { onChipClearAllClick },
    } = useContext(ResultsContext);

    // necessary to have isDirty value not in the useMemo as it uses ref value, and so not updates when ref changes
    const filterSelections: Record<string, any> = getFilteredFromDefaultValues(formData);
    if (watchlistName !== '') filterSelections['watchlist'] = watchlistName;
    const isDirty = !isEqual(filterSelections, previousSelections);

    const showClearFilters = useMemo(
        () =>
            Object.keys(getFilteredFromDefaultValues(formData)).length > 0 ||
            Object.keys(externalChipItems || {}).length > 0,
        [formData, externalChipItems],
    );

    return (
        <Box
            sx={{
                padding: '14px 11px 1px 11px',
                position: 'sticky',
                bottom: -1,
                width: '100%',
                backgroundColor: '#FFFFFF',
                ...(hasVerticalScroll ? shadowTopStylesVariant1 : {}),
                pointerEvents: 'auto',
                zIndex: 1000, // make sure it's above other elements on scroll, capture clicks
            }}>
            <Grid container gap='9px'>
                {isDirty && (
                    <Grid item xs={12}>
                        <StyledRoundedTextButton
                            onClickCallback={() => {
                                SetPreviousSelection(filterSelections);
                                submitHandler?.();
                                callbackOnClose();
                            }}
                            buttonText='See Results'
                            sx={{
                                backgroundColor: '#002B5A',
                                color: '#FFFFFF',
                                borderColor: '#002B5A',
                            }}
                        />
                    </Grid>
                )}
                {!isDirty && (
                    <Grid item xs={12}>
                        <StyledRoundedTextButton onClickCallback={() => callbackOnClose()} buttonText='Close' />
                    </Grid>
                )}
                {showClearFilters && (
                    <Grid item xs={12}>
                        <StyledRoundedTextButton
                            onClickCallback={() => {
                                SetPreviousSelection({});
                                onChipClearAllClick?.();
                            }}
                            buttonText='Clear Filters'
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}
