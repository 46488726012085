import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { customBreakPointValues } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { createTheme, CssBaseline, ThemeOptions, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { PageThemeOptions } from 'components/themes/theme';
import { useContext, useEffect, useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { Locations } from 'utils/preferences';
import { HideOnMdAndAbove, HideOnMdAndBelow } from 'utils/responsive';
import { getUsageApiReqBody } from 'utils/usage';
import { getCompanyDetails, getHasConcernsPdf, getIRAPPdf, getQueryConfig, getScoresPdf } from './api/company';
import { CashFlow } from './components/CashFlow';
import { CompanyHeadlines } from './components/CompanyHeadlines';
import { CompanyOverview } from './components/CompanyOverview';
import { CompanyProfile } from './components/CompanyProfile';
import { EarningsScore } from './components/EarningsScore';
import { FinancialSnapshot } from './components/FinancialSnapshot';
import { IndustryResearch } from './components/IndustryResearch';
import { CompanyParams, PDFURLResponse } from './types/company';

const extendedPageThemeOptions: ThemeOptions = deepmerge(PageThemeOptions, {
    breakpoints: {
        values: customBreakPointValues,
    },
});

const extendedPageTheme = createTheme(extendedPageThemeOptions);

export default function CompanyProfilePage() {
    const companyParams = useParams<keyof CompanyParams>() as CompanyParams;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { userEntitlements } = useUserEntitlements();

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    useUsageLogger({
        requestBody: getUsageApiReqBody(UsageRequestTypes.CompanyViewed, companyParams),
        config: { enabled: true },
        actionType: UsageRequestTypes.CompanyViewed,
    });

    const company = sendSingleRequest(
        getCompanyDetails(companyParams),
        getQueryConfig('getCompanyDetails', 'company'),
    ) as UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;

    let gics_code: string = '';
    let is_biggest_concern = false;
    companyParams.enableQuery = false;

    // track company data in analytics page view
    useEffect(() => {
        let companySecurity = company?.data?.results?.company?.['0']?.company_security;

        if (!companySecurity) {
            return;
        }

        const companyData = companySecurity?.company;
        const securityTrading = companySecurity?.security_trading;

        const pageData: AnalyticsPageProps = {
            name: PageNames.CompanyProfile,
            companyTicker: securityTrading?.ticker_symbol,
            companyName: companyData?.company_name,
            companySector: companySecurity?.company_sector?._lookup_gics_sector_lid?.value,
            companyCfraId: companyData?.cfra_company_id,
            companySecurityId: securityTrading?.cfra_security_trading_id,
            companyExchange: securityTrading?.exchange_lid?.exchange_code,
        };

        globalThis?.analytics?.registerPageView?.(pageData);
    }, [company.data]);

    if (
        !company.isLoading &&
        company?.data?.results?.company !== undefined &&
        company.data.results.company.length > 0
    ) {
        let companyData = company.data?.results?.company[0];
        gics_code = companyData.company_security.company_sector?.lookup_gics_subind_lid?.key;
        is_biggest_concern =
            companyData.frs_biggest_concerns_list?.is_biggest_concern ||
            companyData.frs_hazard_list?.is_biggest_concern;
        companyParams.enableQuery = true;
    }

    const concerns_report = sendSingleRequest(
        getHasConcernsPdf(companyParams, is_biggest_concern),
        getQueryConfig('getHasConcernsPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const iraps_report = sendSingleRequest(
        getIRAPPdf(companyParams.enableQuery, gics_code),
        getQueryConfig('getIRAPPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const scores_report = sendSingleRequest(
        getScoresPdf(companyParams),
        getQueryConfig('getScoresPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const getReportId = (result: UseQueryResult<PDFURLResponse>) =>
        (result.isLoading || result.data?.results.research.length === 0 ? null : result.data?.results.research[0].id) ||
        '';

    const getHasLock = (result: UseQueryResult<PDFURLResponse>) => {
        return result.isLoading || result.data?.results.research.length === 0
            ? false
            : result.data?.results.research[0].research_report?.s3_pdf_url === '';
    };

    const industryResearchCard = useMemo(() => {
        return (
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.IndustryResearch,
                    },
                }}>
                <IndustryResearch enableQuery={Boolean(companyParams.enableQuery)} gics_code={gics_code} />
            </AnalyticsDataContextProvider>
        );
    }, [companyParams.enableQuery, gics_code]);

    return (
        <ThemeProvider theme={extendedPageTheme}>
            <CssBaseline />
            <Grid container>
                <Grid item xs={12}>
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.CompanyOverview,
                            },
                        }}>
                        <CompanyOverview
                            company={company}
                            reportID={getReportId(concerns_report)}
                            userEntitlements={userEntitlements}
                        />
                    </AnalyticsDataContextProvider>
                </Grid>
                <Grid container sx={{ paddingTop: '36px' }} spacing={3}>
                    <Grid item xs={12} md>
                        <AnalyticsDataContextProvider
                            cfraDataLocal={{
                                actionData: {
                                    cardName: Locations.LatestHeadlines,
                                },
                            }}>
                            <CompanyHeadlines
                                company={company}
                                queryParams={companyParams}
                                isLoading={company.isLoading}
                                gics_code={gics_code}
                            />
                        </AnalyticsDataContextProvider>
                        <AnalyticsDataContextProvider
                            cfraDataLocal={{
                                actionData: {
                                    cardName: Locations.FinancialSnapshot,
                                },
                            }}>
                            <FinancialSnapshot
                                company={company}
                                queryParams={companyParams}
                                isLoading={company.isLoading}
                            />
                        </AnalyticsDataContextProvider>
                        <HideOnMdAndBelow>{industryResearchCard}</HideOnMdAndBelow>
                    </Grid>
                    <Grid item xs={12} md={3.25} minWidth='342px'>
                        <AnalyticsDataContextProvider
                            cfraDataLocal={{
                                actionData: {
                                    cardName: Locations.CompanyProfile,
                                },
                            }}>
                            <CompanyProfile
                                company={company}
                                reportID={getReportId(iraps_report)}
                                hasLock={getHasLock(iraps_report)}
                            />
                        </AnalyticsDataContextProvider>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <HideOnMdAndAbove>{industryResearchCard}</HideOnMdAndAbove>
                </Grid>
                <Grid item xs={12} md={8} lg={6} sx={{ paddingTop: '36px' }}>
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.EarningsScore,
                            },
                        }}>
                        <EarningsScore
                            company={company}
                            enableQuery={companyParams.enableQuery}
                            reportID={getReportId(scores_report)}
                            userEntitlements={userEntitlements}
                            hasLock={getHasLock(scores_report)}
                        />
                    </AnalyticsDataContextProvider>
                </Grid>
                <Grid item xs={12} md={8} lg={6} sx={{ paddingTop: '36px' }} paddingLeft={{ lg: '24px' }}>
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.CashFlowScore,
                            },
                        }}>
                        <CashFlow
                            company={company}
                            enableQuery={companyParams.enableQuery}
                            reportID={getReportId(scores_report)}
                            userEntitlements={userEntitlements}
                            hasLock={getHasLock(scores_report)}
                        />
                    </AnalyticsDataContextProvider>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
