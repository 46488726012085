import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { setDataToSessionStorage } from '../utils/storage';
import { GetAuthenticatedRoutesJsxProps, RouteType } from './types';
import { getAuthenticatedRoutesJsx, getNotAuthenticatedRoutes, getNotAuthenticatedRoutesJsx } from './utils';

type AppRoutesProps = {
    loginElement: JSX.Element;
    authenticatedRoutesJsxGetterProps: GetAuthenticatedRoutesJsxProps;
    unauthenticatedRoutes?: Array<RouteType>;
    userContextProviderProps?: GetAuthenticatedRoutesJsxProps['userContextProviderProps'];
};

const SESSION_CHECK_INTERVAL = 1000 * 30; // every 30 seconds

export const AppRoutes = ({
    authenticatedRoutesJsxGetterProps,
    loginElement,
    unauthenticatedRoutes,
    userContextProviderProps,
}: AppRoutesProps) => {
    const [[, resultJsx], _setResultJsx] = useState<[string | undefined, JSX.Element]>([undefined, <></>]);

    const setResultJsx = useCallback(
        (key: string, jsx: JSX.Element) => {
            _setResultJsx((previousValue) => {
                const [jsxDescription] = previousValue;

                if (jsxDescription !== key) {
                    return [key, jsx];
                }

                return previousValue;
            });
        },
        [_setResultJsx],
    );

    const { route, user, signOut } = useAuthenticator((context) => [context.route, context.user]);

    const checkSession = useCallback(async () => {
        try {
            await Auth.currentSession();
        } catch (error) {
            // if error then session expired - should log out
            signOut();
        }
    }, [signOut]);

    useEffect(() => {
        checkSession();

        // Set interval to check session periodically
        const intervalId = setInterval(checkSession, SESSION_CHECK_INTERVAL);

        return () => {
            clearInterval(intervalId);
        };
    }, [checkSession]);

    const routesJsx = useMemo(
        () =>
            getAuthenticatedRoutesJsx({
                ...authenticatedRoutesJsxGetterProps,
                projectSpecificResources: {
                    ...authenticatedRoutesJsxGetterProps.projectSpecificResources,
                    userId: user?.username,
                    user,
                    signOut,
                },
                userContextProviderProps,
            }),
        [authenticatedRoutesJsxGetterProps, userContextProviderProps, signOut, user],
    );
    const authenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(routesJsx));
    }, [routesJsx]);

    const notAuthenticatedRoutesJsx = useMemo(() => {
        return getNotAuthenticatedRoutesJsx(getNotAuthenticatedRoutes(loginElement, unauthenticatedRoutes));
    }, [loginElement, unauthenticatedRoutes]);
    const notAuthenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(notAuthenticatedRoutesJsx));
    }, [notAuthenticatedRoutesJsx]);

    useEffect(() => {
        if (route === 'setup' || route === 'signIn') {
            const currentRoute = window.location.href.replace(window.location.origin, '');
            if (!currentRoute.includes('login')) {
                setDataToSessionStorage('preLoginRoute', currentRoute);
            }

            const key = 'not authenticated routes';
            setResultJsx(key, <RouterProvider key={key} router={notAuthenticatedRouter} />);
            return;
        }

        if (route === 'authenticated' && user?.username) {
            const key = 'authenticated routes';
            setResultJsx(key, <RouterProvider key='authenticated routes' router={authenticatedRouter} />);
            return;
        }
    }, [authenticatedRouter, notAuthenticatedRouter, route, user?.username, setResultJsx]);

    return resultJsx;
};
