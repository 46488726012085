import { UserEntitlementsResponseData } from '@cfra-nextgen-frontend/shared/src/types/userEntitlements';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { waitUntil } from '@cfra-nextgen-frontend/shared/src/utils/wait';
import { debounce } from 'lodash';

const unauthorizedUserProps = {
    name: 'unauthorized',
    company: 'unauthorized',
    group: 'unauthorized',
    userSfOpportunity: 'unauthorized',
    userSfCustodianAccount: 'unauthorized',
    id: 'unauthorized',
};

export const defaultListVariablesDelimiter = ';';

export class Analytics implements IAnalytics {
    constructor() {
        globalThis.cfraData = {
            pageData: {},
            actionData: {},
            siteData: {},
            userData: unauthorizedUserProps,
        };

        globalThis.cfraData.siteData.siteDomain = window.location.hostname.toLowerCase();

        // prevent duplicate page view \ action tracking in strict mode
        // this should also be useful in cases component can't avoid multiple re-renders
        this.registerPageView = debounce(this.registerPageView.bind(this), 200);
        this.registerAction = debounce(this.registerAction.bind(this), 200);
    }

    private _postPoneTracking: boolean | undefined;
    private _isUserDataLoading: boolean | undefined;

    public postPoneTracking() {
        this._postPoneTracking = true;
    }

    public resumeTracking() {
        this._postPoneTracking = false;
    }

    public setUserDataLoading(isLoading: boolean) {
        this._isUserDataLoading = isLoading;
    }

    public registerAction(actionProps: AnalyticsActionProps) {
        globalThis.cfraData.actionData = actionProps;

        globalThis.cfraData.actionData.entry = joinWithDelimiter({
            values: [globalThis.cfraData.pageData.name, actionProps.cardName, actionProps.tabName]
        })

        this.satelliteTrack('registerAction');
    }

    private satelliteTrack(elementName: string) {
        function assignBuildDate() {
            if (globalThis._satellite.buildInfo && !globalThis.cfraData.siteData.buildDate) {
                globalThis.cfraData.siteData.buildDate = globalThis._satellite.buildInfo.buildDate;
            }
        }

        waitUntil(
            () =>
                Boolean(
                    globalThis._satellite &&
                        globalThis._satellite.environment &&
                        globalThis._satellite.environment.id &&
                        this._isUserDataLoading !== true &&
                        this._postPoneTracking !== true,
                ),
            [
                assignBuildDate,
                () => {
                    globalThis._satellite?.track(elementName, globalThis.cfraData);
                },
            ],
        );
    }
    
    public registerPageView(pageProps: AnalyticsPageProps, sendDataToAdobe: boolean = true) {
        globalThis.cfraData.pageData = pageProps;

        if (!globalThis.cfraData.pageData.group) {
            globalThis.cfraData.pageData.group = globalThis.cfraData.pageData.name;
        }

        globalThis.cfraData.pageData.pageReferrer =
            decodeURIComponent(document.referrer).toLowerCase().trim() || 'no referrer';
        globalThis.cfraData.pageData.url = decodeURIComponent(window.location.href).toLowerCase().trim();

        if (!sendDataToAdobe) {
            return;
        }

        this.satelliteTrack('registerPageView');
    }

    public fillUserDetails(userDetails: UserEntitlementsResponseData) {
        const assignUserData = (userProp?: AnalyticsUserProps) => {
            globalThis.cfraData.userData = userProp || unauthorizedUserProps;
        };

        if (!userDetails) {
            return;
        }

        const userDetailsUserData = userDetails.user_data?.['results']?.['data']?.['0'];

        if (!userDetailsUserData) {
            assignUserData();
            return;
        }

        const userGroup = userDetailsUserData.user_group as Array<Record<string, any>> | undefined;

        assignUserData({
            name: userDetailsUserData.email,
            company: joinWithDelimiter({
                leftValue: userDetails.company_name,
                rightValue: String(userDetails.account_id),
            }),
            group: userGroup
                ?.map((groupData) => {
                    const group = groupData?.group;

                    return joinWithDelimiter({
                        leftValue: group?.name,
                        rightValue: group?.id,
                    });
                })
                .join(defaultListVariablesDelimiter),
            userSfOpportunity: userDetailsUserData.salesforce_opportunity_id,
            userSfCustodianAccount: userDetailsUserData.salesforce_custodian_account_id,
            id: userDetailsUserData.id,
        });
    }
}
