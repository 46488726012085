import { Box, SxProps } from '@mui/material';
import React from 'react';
import { TitleWithLink, TitleWithLinkProps } from '../TitleWithLink';
import { useNavigate } from 'react-router-dom';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

export type Results2ColumnsProps = {
    leftOptions: JSX.Element;
    rightOptions: JSX.Element;
    inputValue: string;
    topLinkParams?: {
        text: string;
        pathname: string;
        navigateTo?: string;
    };
    onClickOutsideCallback?: () => void;
    title: string;
    titleWithLinkProps?: Partial<TitleWithLinkProps>;
    optionsContainerSx?: SxProps;
};

export function Results2Columns({
    leftOptions,
    rightOptions,
    inputValue,
    topLinkParams,
    onClickOutsideCallback,
    title,
    titleWithLinkProps,
    optionsContainerSx,
}: Results2ColumnsProps) {
    const navigate = useNavigate();
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <TitleWithLink
                title={title}
                linkProps={{
                    linkText: inputValue ? topLinkParams?.text || '' : '',
                    onClick: () => {
                        analyticsDataPickerRef.current?.registerAction({
                            action: joinWithDelimiter({
                                values: ['click on link', topLinkParams?.text],
                            }),
                            navigateTo: topLinkParams?.navigateTo,
                        });

                        setTimeout(() => {
                            onClickOutsideCallback?.();
                            navigate(topLinkParams?.pathname || '', { state: { searchTerm: inputValue } });
                        }, 300);
                    },
                    to: {
                        // pathname: '/',
                    },
                }}
                {...titleWithLinkProps}
            />
            <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 45px)', ...optionsContainerSx }}>
                {/* Left sub block */}
                {leftOptions}
                {/* Right sub block */}
                {rightOptions}
            </Box>
        </>
    );
}
