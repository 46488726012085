import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { Navigate, Route, Routes } from 'react-router-dom';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';
import { AnalystDirectory } from '../components/AnalystDirectory';

export function AnalystDirectoryRoutes() {
    return (
        <Routes>
            <Route>
                <Route path='/*' element={<Navigate to='.' />} />
                <Route path='/' element={<AnalystDirectoryElement />} />
            </Route>
        </Routes>
    );
}

export function AnalystDirectoryElement() {
    return (
        <>
            <PageWithComponentInHeader
                pageContainerSx={{
                    maxWidth: '100%',
                    paddingLeft: horizontalPaddingInSu,
                    paddingRight: horizontalPaddingInSu,
                    display: 'flex',
                    justifyContent: 'center',
                    minHeight: 'unset',
                    '@media (max-width: 768.5px)': {
                        paddingLeft: '14px',
                        paddingRight: '14px',
                    },
                }}
                tabContainerSx={{
                    paddingTop: horizontalPaddingInSu,
                    paddingBottom: horizontalPaddingInSu,
                    paddingLeft: 0,
                    paddingRight: 0,
                    maxWidth: maxPageWidthInPx,
                    boxSizing: 'content-box',
                    backgroundColor: 'unset',
                    width: '100%',
                    '@media (max-width: 768.5px)': {
                        paddingTop: '14px',
                        paddingBottom: '14px',
                    },
                    alignItems: 'start',
                }}
                outletComponent={<AnalystDirectory />}
            />
            <Footer />
        </>
    );
}
