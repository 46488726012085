import { getQueryConfig } from "./api/company";
import { useContext, useEffect } from "react";
import { CompanyParams } from "./types/company";
import { ProjectSpecificResourcesContext } from "@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context";
import { useParams } from "react-router-dom";
import { ScreenerDataWithGenericResultsKey } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/screener";
import { UseQueryResult } from "react-query";

export default function CompanyProfileRedirect() {
    const companyParams = useParams<keyof CompanyParams>() as CompanyParams;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const company = sendSingleRequest(
        {
            requestBody: {
                filters: {
                    values: {
                        ...(companyParams.bm_id !== undefined
                            ? { 'frs_base_details.bluematrix_company_id': { values: [Number(companyParams.bm_id)] as Array<number> } }
                            : {}),
                    },
                },
            },
            view: 'companyprofile',
            includeData: true,
            size: 1,
        },
        getQueryConfig('getCompanyDetails', 'company'),
    ) as UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;

    useEffect(() => {
        if (!company.isLoading || company.isSuccess) {
            let companySecurity = company?.data?.results?.company?.['0']?.company_security;

            const securityTrading = companySecurity?.security_trading?.cfra_security_trading_id;

            if (securityTrading) {
                window.location.href = `/company-profile/security/${securityTrading}`;
            } else {
                window.location.href = `/company-profile/company/${companySecurity?.company?.cfra_company_id}`;
            }
        }
    }, [company.data, company.isLoading, company.isSuccess]);

    return (
        <div>
            <h4>Redirecting...</h4>
        </div>
    )
}