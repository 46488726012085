import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { UserAlertData } from '@cfra-nextgen-frontend/shared/src/types/alert';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, Skeleton } from '@mui/material';
import { LockModal } from 'components/LockComponent/LockModal';
import { usePdfViewer } from 'hooks/usePdfViewer';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PageNames, RoutePaths } from 'utils/enums';

export function PdfViewer() {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const source = searchParams.get('source') ?? undefined;
    const alertId = searchParams.get('alert_id') ?? undefined;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    const navigate = useNavigate();
    const navigateRef = useRef<(url: string) => void>((url: string) => navigate(url));
    const [isLockModalOpen, setIsLockModalOpen] = useState(false);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    if (!id) {
        throw new Error('Missing required parameters: id is required');
    }

    const getUserAlertQuery = sendSingleRequest<{ data: ScreenerDataWithGenericResultsKey<'data'> }>(
        {
            alertId,
            view: 'alert_analytics',
            noErrorOnNoKeyValuePairs: true,
            config: { enabled: alertId !== undefined },
        },
        {
            path: 'alert',
            apiName: ApiNames.UserManagement,
            queryKeyFirstElement: 'getUserAlert',
            requestType: RequestTypes.GET,
        },
    );

    // handle user analytics
    useEffect(() => {
        // pause tracking to prevent capture report data without page data
        globalThis?.analytics?.postPoneTracking?.();

        if (getUserAlertQuery.isLoading) {
            // wait while all the data is loaded
            return;
        }

        const userAlertData = getUserAlertQuery.data?.data?.results?.data?.['0'] as UserAlertData | undefined;

        let pageData: AnalyticsPageProps = {
            name: PageNames.PdfViewer,
            reportId: id,
            alertId,
            source,
        };

        // add all alert data in case we have it
        if (userAlertData) {
            pageData = {
                ...pageData,
                alertPrimarySourceId: userAlertData?.primary_source_id,
                alertType: joinWithDelimiter({
                    leftValue: userAlertData.lookup_alert_type?.value,
                    rightValue: String(userAlertData.lookup_alert_type?.lookup_id),
                }),
                alertEntityType: joinWithDelimiter({
                    leftValue: userAlertData.lookup_alert_type?.lookup_entity_type?.value,
                    rightValue: String(userAlertData.lookup_alert_type?.lookup_entity_type?.lookup_id),
                }),
                alertSubject: userAlertData.subject,
                alertDatetime: userAlertData.alert_time,
            };
        }

        // simulate state after capturing page view
        globalThis?.analytics?.registerPageView?.(pageData, false);

        // resume tracking
        globalThis?.analytics?.resumeTracking?.();
    }, [getUserAlertQuery.isLoading, getUserAlertQuery.data?.data?.results?.data, alertId, id, source]);

    const { extractedLink, isLoading, data } = usePdfViewer({ id, source, alertId, postponeRedirect: true });

    const handleCloseLockModal = useCallback(() => {
        navigateRef.current(`${RoutePaths.Home}/`);
    }, []);

    useEffect(() => {
        if (!isLoading && data?.results?.research?.length === 0) {
            setIsLockModalOpen(true);
        }
    }, [isLoading, data?.results?.research?.length]);

    if (isLoading) {
        return (
            <Box width='100%' height='100%'>
                <Skeleton sx={{ mt: '120px', mx: '20px', height: '30px' }} />
            </Box>
        );
    }

    if (!isLoading && !extractedLink) {
        return (
            <LockModal
                handleClose={handleCloseLockModal}
                handleCloseModal={handleCloseLockModal}
                modalOpen={isLockModalOpen}
            />
        );
    }

    return (
        <iframe
            src={extractedLink}
            width='100%'
            style={{ border: 'none', display: 'flex', flexGrow: 1 }}
            title='PDF Viewer'
        />
    );
}
