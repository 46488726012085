import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { useLinkGetter } from 'hooks/useLinkGetter';
import { useCallback } from 'react';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

export function useHandleLinkClick() {
    const { setRequestParamsProps } = useLinkGetter(...researchLinkGetterParams);

    const handleLinkClick = useCallback(
        (researchId: string) => {
            setRequestParamsProps(getRequestParamsPropsVariant1(researchId));
            window.open(`/pdf/${researchId}`)
        },
        [setRequestParamsProps],
    );

    return handleLinkClick;
}
