import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Theme } from '@mui/material';
import FormCheckboxList from '../../Form/FormCheckboxList';
import { combineIntoFormElementName } from './shared';
import { FilterProps } from './types';

type ScreenerFormCheckboxListProps = {
    theme?: Theme;
    component?: Components;
    title?: string;
} & FilterProps;

export const ScreenerFormCheckboxList: React.FC<ScreenerFormCheckboxListProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    submitHandler,
    onChangeClearHandler,
    theme,
    component,
    ...props
}) => {
    return (
        <FormCheckboxList
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.CheckboxList,
                filterMetadataKey,
            })}
            options={filtersData.data[filterMetadataKey].items}
            submitHandler={submitHandler}
            onChangeClearHandler={onChangeClearHandler}
            theme={theme}
            {...props}
        />
    );
};
