import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { LinkGeneratorModes } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { UsageRequestTypes, UsageSourceLid } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { openExternalLink } from '@cfra-nextgen-frontend/shared/src/utils/links';
import { registerReportOpen } from 'analytics/utils';
import { useContext, useEffect, useState } from 'react';
import { getResearchTypeId, researchLinkGetterParams } from 'utils/researchLinkGetter';
import { getUsageApiReqBody } from 'utils/usage';
import { useLinkGetter } from './useLinkGetter';
import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';

type usePdfViewerProps = {
    id: string;
    source?: string;
    alertId?: string;
    postponeRedirect?: boolean;
};

export const usePdfViewer = ({ id, source, alertId, postponeRedirect: outerPostponeRedirect }: usePdfViewerProps) => {
    const [innerPostponeRedirect, setInnerPostponeRedirect] = useState(true);

    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { cfraDataLocal } = analyticsDataContext || {};

    const { setRequestParamsProps, extractedLink, isLoading, data } = useLinkGetter({
        ...researchLinkGetterParams[0],
        mode: LinkGeneratorModes.ExtractLink,
        getPostUsageParams: (data) => {
            if (!data?.results?.research?.length) return;

            const research = data.results.research[0];
            const researchTypeId = getResearchTypeId(research);

            return getUsageApiReqBody(UsageRequestTypes.PdfViewerViewed, {
                research_type_id: researchTypeId,
                id: research.id,
                alert_id: alertId !== undefined ? Number(alertId): undefined,
                source_lid: source === 'email' ? UsageSourceLid.Email : undefined,
            });
        },
        analyticsCallback: (data) => {
            registerReportOpen({
                research: data?.results?.research,
                reportId: id,
                cfraDataLocal,
            });
            setTimeout(() => setInnerPostponeRedirect(false), 1000);
        },
    });

    useEffect(() => {
        if (id) {
            setRequestParamsProps(getRequestParamsPropsVariant1(id));
        }
    }, [id, setRequestParamsProps]);

    useEffect(() => {
        if (extractedLink && !outerPostponeRedirect && !innerPostponeRedirect) {
            openExternalLink(extractedLink, '_self');
        }
    }, [extractedLink, outerPostponeRedirect, innerPostponeRedirect]);

    return { extractedLink, isLoading, data };
};
