import { Box, Grid, GridSize, SxProps } from "@mui/material"

export const TableSkeleton = ({
    columns, numberOfRows, sx
}: {
    columns: { flex: GridSize }[],
    numberOfRows: number,
    sx?: SxProps;
}) => {
    const rows =  Array.apply(null, Array(numberOfRows)).map(() => ({}));
    return (<Grid sx={{width: '100%', ...sx}}>
        {rows.map((r, i) => {
            return <Grid key={i} container sx={{ marginBottom: '10px' }}>
                {columns.map((c, j) => {
                    return (
                        <Grid key={j} item xs={c.flex} sx={{ padding: '5px', flexWrap: 'wrap' }}>
                            <Box width={'100%'} height={'15px'} sx={{ backgroundColor: 'rgba(128, 128, 128, 0.15)', borderRadius: '2px' }}></Box>
                        </Grid>
                    )
                })}
            </Grid>
        })}
    </Grid>)
}