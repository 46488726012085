import { HorizontalCarousel } from '@cfra-nextgen-frontend/shared/src/components/CarouselArea/HorizontalCarousel';
import { ClearButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ClearButton';
import {
    autocompleteThemeVariant1
} from '@cfra-nextgen-frontend/shared/src/components/Form/themes/autocomplete';
import { dateRangePickerThemeVariant1 } from '@cfra-nextgen-frontend/shared/src/components/Form/themes/dateRangePicker';
import { UseFiltersFormInputProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormDateRangePicker } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormDateRangePicker';
import { ScreenerFormPillsRow } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormPillsRow';
import { ScreenerFormVirtualizeAutocomplete } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormVirtualizeAutocomplete';
import { Box, createTheme, styled, useMediaQuery } from '@mui/material';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';
import { ManageWatchlist } from 'components/Watchlist/ManageWatchlist';
import React, { useMemo, useState } from 'react';
import { GetFiltersJsxConfig } from './shared';

export type GetFiltersSectionJsxProps = GetFiltersJsxConfig & {};

const WrapBlocksComponent = function ({ children }: { children: React.ReactNode }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '12px', flexWrap: 'wrap' }}>
            {children}
        </Box>
    );
};

const StyledContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '23px',
});

export const getFiltersSectionJsx: (props: GetFiltersSectionJsxProps) => UseFiltersFormInputProps['getFiltersJsx'] = ({
    pillsRowComponentProps,
    virtualizeAutocompleteComponentsProps,
    dateRangePickerComponentProps,
    watchlistProps,
    theme = commonCustomBreakpointsTheme,
}) => {
    return ({ submitHandler, control, filtersData, getValues, setValue, validate, onChipClearAllClickRef }) => {
        // allow use hooks inside by returning a component
        function Result() {
            const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
            const clearButton = useMemo(
                () => (
                    <ClearButton
                        sx={isBelowMd ? { padding: '0px' } : undefined}
                        onClick={() => onChipClearAllClickRef.current?.()}
                    />
                ),
                [isBelowMd],
            );
            const AutocompletesRowWrapper = useMemo(
                () => (isBelowMd ? HorizontalCarousel : WrapBlocksComponent),
                [isBelowMd],
            );
        
            const [, setIndResearchGics] = useState<string[]>();

            return (
                <>
                    {pillsRowComponentProps && (
                        <StyledContainer>
                            <ScreenerFormPillsRow
                                useFormLabelWithContainer={false}
                                control={control}
                                filtersData={filtersData}
                                submitHandler={submitHandler}
                                getValues={getValues}
                                setValue={setValue}
                                validate={validate}
                                parentSectionKey={'root'}
                                hide={false}
                                {...pillsRowComponentProps}
                                afterPillsSlot={isBelowMd ? clearButton : undefined}
                                afterMoreButtonComponentWidth={isBelowMd ? 106 : undefined}
                            />
                        </StyledContainer>
                    )}

                    {!pillsRowComponentProps && isBelowMd && <StyledContainer>{clearButton}</StyledContainer>}

                    <AutocompletesRowWrapper>
                        <>
                            {React.Children.toArray(
                                virtualizeAutocompleteComponentsProps.map((props, index) => {
                                    return (
                                        <ScreenerFormVirtualizeAutocomplete
                                            key={index}
                                            alwaysShowLabel={true}
                                            highlightOnSelection={true}
                                            defaultInputLabel={props.defaultInputLabel}
                                            useFormLabelWithContainer={false}
                                            control={control}
                                            filtersData={filtersData}
                                            filterMetadataKey={props.filterMetadataKey}
                                            submitHandler={submitHandler}
                                            getValues={getValues}
                                            setValue={setValue}
                                            validate={validate}
                                            parentSectionKey={'root'}
                                            hide={false}
                                            theme={createTheme(autocompleteThemeVariant1)}
                                            ignoreFocus={false}
                                        />
                                    );
                                }),
                            )}
                            {watchlistProps && watchlistProps.setWatchlistCompanyIds && (
                                <div>
                                    <ManageWatchlist
                                        ref={watchlistProps.componentRef}
                                        setIndResearchGics={setIndResearchGics}
                                        watchlistCompanyIds={watchlistProps.watchlistCompanyIds}
                                        setWatchlistCompanyIds={watchlistProps.setWatchlistCompanyIds}
                                        hideActionButton
                                        enableWatchlistAnalytics={false}
                                    />
                                </div>
                            )}
                            {dateRangePickerComponentProps && (
                                <ScreenerFormDateRangePicker
                                    useFormLabelWithContainer={false}
                                    control={control}
                                    filtersData={filtersData}
                                    filterMetadataKey={dateRangePickerComponentProps.filterMetadataKey}
                                    submitHandler={submitHandler}
                                    getValues={getValues}
                                    parentSectionKey={'root'}
                                    setValue={setValue}
                                    validate={validate}
                                    hide={false}
                                    theme={dateRangePickerThemeVariant1}
                                    buttonsText={{ start: 'From', end: 'To' }}
                                />
                            )}
                        </>
                        {!isBelowMd && clearButton}
                    </AutocompletesRowWrapper>
                </>
            );
        }

        return <Result />;
    };
};
