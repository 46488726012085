import settingsIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/settings.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsPreferencesEachElement,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getSavedItemsByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { homePageTheme } from 'components/themes/theme';
import { ManageWatchlist, WatchlistCompanyIds } from 'components/Watchlist/ManageWatchlist';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { Locations, UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { CompanyReports, ReportsLoadingContainerCard } from './CompanyReports';
import { DailyPacketCard } from './DailyPacketCard';
import { IndustryResearch } from './IndustryResearch';
import { RecentViews } from './RecentViews';
import { TrendingCard } from './TrendingCard';
import { RiskMonitor } from './RiskMonitor';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';

const leftColumnWidthInSu = 7.895;

type CompanyReportsRefType = {
    selectedView: Views;
};

const userPreferencesGetterParams = {
    preferenceType: PreferenceType.IdsAndSelectionsPreferences,
};

const HomePageCommonSelector = UserPreferencesSelectors[UserPreferences.HomePageCommon];
const CompanyReportsResearchTypeIdsSelector = UserPreferencesSelectors[UserPreferences.HomePageCompanyReportsCommon];
const IndustryResearchGicsCodeSelector = UserPreferencesSelectors[UserPreferences.HomePageIndustryResearch];
const IndustryResearchAgGridPreferencesConfiguration = {
    useUserPreferencesProps,
    selectorConfiguration: {
        selector: IndustryResearchGicsCodeSelector,
    },
};

export function HomePage() {
    const navigate = useNavigate();
    const companyReportsRef = useRef<CompanyReportsRefType>({ selectedView: Views.GridView });

    const { getUserPreferences, setUserPreferences } = useUserPreferences(useUserPreferencesProps);

    const researchTypeIds = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: CompanyReportsResearchTypeIdsSelector,
        })?.researchTypeIds;
    }, [getUserPreferences]);

    const setResearchTypeIds = useCallback(
        (researchTypeIds?: Array<number>) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    ...CompanyReportsResearchTypeIdsSelector,
                    action: IdsAndSelectionsPreferencesActions.SetResearchTypeIds,
                    researchTypeIds: researchTypeIds,
                },
            );
        },
        [setUserPreferences],
    );

    const lastViewedWatchlistId = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: HomePageCommonSelector,
        })?.lastViewedWatchlistId;
    }, [getUserPreferences]);

    const setLastViewedWatchlistId = useCallback(
        (lastViewedWatchlistId?: number) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    each: [
                        {
                            ...HomePageCommonSelector,
                            action: IdsAndSelectionsPreferencesActions.SetLastViewedWatchlistId,
                            lastViewedWatchlistId: lastViewedWatchlistId,
                        },
                        {
                            ...CompanyReportsResearchTypeIdsSelector,
                            action: IdsAndSelectionsPreferencesActions.SetResearchTypeIds,
                            researchTypeIds: [],
                        },
                        {
                            ...IndustryResearchGicsCodeSelector,
                            action: IdsAndSelectionsPreferencesActions.SetGicsSectors,
                            gicsSectors: [],
                        },
                    ],
                },
            );
        },
        [setUserPreferences],
    );

    const gicsSectors = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: IndustryResearchGicsCodeSelector,
        })?.gicsSectors;
    }, [getUserPreferences]);

    const setGicsSectors = useCallback(
        (gicsSectors?: Array<string>) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    ...IndustryResearchGicsCodeSelector,
                    action: IdsAndSelectionsPreferencesActions.SetGicsSectors,
                    gicsSectors,
                },
            );
        },
        [setUserPreferences],
    );

    const [indResearchGics, setIndResearchGics] = useState<string[]>();
    const [watchlistCompanyIds, setWatchlistCompanyIds] = useState<WatchlistCompanyIds>();
    const maxWidth600 = useMediaQuery('(max-width:600px)');
    const isBelow750 = useMediaQuery('(max-width:750.5px)');
    const isAbove1000 = useMediaQuery('(min-width:1000.5px)');

    useEffect(() => {
        invalidateQueriesByKey(getSavedItemsByIdConfig.queryKeyFirstElement);
    }, []);

    const handleManageWatchlistClick = useCallback(() => {
        navigate('/watchlist', { state: { homepageViewedWatchlistId: lastViewedWatchlistId } });
    }, [navigate, lastViewedWatchlistId]);

    const handleOnViewChange = useCallback((view: Views) => {
        companyReportsRef.current.selectedView = view;
    }, []);

    const getIndustryResearchCard = useCallback(
        (maxNumberOfRowsToDisplay?: number) => {
            return (
                <>
                    {indResearchGics !== undefined ? (
                        <AnalyticsDataContextProvider
                            cfraDataLocal={{
                                actionData: {
                                    cardName: Locations.IndustryResearch,
                                },
                            }}>
                            <IndustryResearch
                                gicsCode={indResearchGics}
                                gicsSectors={gicsSectors}
                                setGicsSectors={setGicsSectors}
                                preferencesConfiguration={IndustryResearchAgGridPreferencesConfiguration}
                                maxNumberOfRowsToDisplay={maxNumberOfRowsToDisplay}
                            />
                        </AnalyticsDataContextProvider>
                    ) : (
                        <ReportsLoadingContainerCard />
                    )}
                </>
            );
        },
        [indResearchGics, gicsSectors, setGicsSectors],
    );

    const companyReports = useMemo(() => {
        return (
            <>
                {watchlistCompanyIds !== undefined ? (
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.CompanyReports,
                            },
                        }}>
                        <CompanyReports
                            key={'companyReportsRef'}
                            onViewChange={handleOnViewChange}
                            researchTypeIds={researchTypeIds}
                            setResearchTypeIds={setResearchTypeIds}
                            watchlistCompanyIds={watchlistCompanyIds}
                            selectedView={companyReportsRef.current.selectedView}
                        />
                    </AnalyticsDataContextProvider>
                ) : (
                    <ReportsLoadingContainerCard />
                )}
            </>
        );
    }, [watchlistCompanyIds, researchTypeIds, setResearchTypeIds, handleOnViewChange]);

    const riskMonitor = useMemo(() => {
        return (
            <>
                {watchlistCompanyIds !== undefined ? (
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.RiskMonitor,
                            },
                        }}>
                        <RiskMonitor key='RiskMonitor' watchlistCompanyIds={watchlistCompanyIds} />
                    </AnalyticsDataContextProvider>
                ) : (
                    <ReportsLoadingContainerCard />
                )}
            </>
        );
    }, [watchlistCompanyIds]);

    const trendingCard = useMemo(() => {
        return (
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.Trending,
                    },
                }}>
                <TrendingCard />
            </AnalyticsDataContextProvider>
        );
    }, []);

    const recentViews = useMemo(() => {
        return (
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.MyRecentViews,
                    },
                }}>
                <RecentViews />
            </AnalyticsDataContextProvider>
        );
    }, []);

    const dailyPacketCard = useMemo(() => {
        return (
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.DailyPacket,
                    },
                }}>
                <DailyPacketCard />
            </AnalyticsDataContextProvider>
        );
    }, []);

    return (
        <ThemeProvider theme={homePageTheme}>
            <AnalyticsDataContextProvider>
                <ResultsContextProvider>
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                        <ManageWatchlist
                            setIndResearchGics={setIndResearchGics}
                            watchlistCompanyIds={watchlistCompanyIds}
                            setWatchlistCompanyIds={setWatchlistCompanyIds}
                            lastViewedWatchlistId={lastViewedWatchlistId}
                            useCreatedWatchlistId
                            setLastViewedWatchlistId={setLastViewedWatchlistId}
                        />
                        <ETFLinkButton
                            text={maxWidth600 ? '' : 'Manage Watchlist'}
                            onClick={handleManageWatchlistClick}
                            sx={{
                                '&:hover': {
                                    background: 'transparent',
                                },
                                '& .MuiButton-endIcon': {
                                    marginLeft: maxWidth600 ? '0px' : '8px',
                                },
                            }}
                            endIcon={<Box component='img' src={settingsIcon} width='100%' height='auto' />}
                        />
                    </Box>

                    <Grid container spacing={horizontalPaddingInSu}>
                        <Grid item xs={12} md={leftColumnWidthInSu}>
                            <Stack spacing={4}>
                                <Box>{riskMonitor}</Box>
                                <Box>{companyReports}</Box>
                                {(isAbove1000 || isBelow750) && getIndustryResearchCard()}
                            </Stack>
                        </Grid>
                        {(isAbove1000 || isBelow750) && (
                            <Grid item xs={12} md={12 - leftColumnWidthInSu}>
                                <Stack spacing={4}>
                                    {trendingCard}
                                    {recentViews}
                                    {dailyPacketCard}
                                </Stack>
                            </Grid>
                        )}
                        {!isBelow750 && !isAbove1000 && (
                            <>
                                <Grid item container spacing={4} xs={12}>
                                    <Grid item xs={6}>
                                        {trendingCard}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {getIndustryResearchCard(20)}
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={4} xs={12}>
                                    <Grid item xs={6}>
                                        {recentViews}
                                    </Grid>
                                    <Grid item xs={6}>
                                        {dailyPacketCard}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </ResultsContextProvider>
            </AnalyticsDataContextProvider>
        </ThemeProvider>
    );
}
