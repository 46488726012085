import { StyledTabV2 } from '@cfra-nextgen-frontend/cfra-institutional/src/features/home/components/StyledTab';
import { Layout } from '@cfra-nextgen-frontend/shared';
import {
    AnalyticsDataContext,
    AnalyticsDataContextProvider,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { ScreenerResearchAnalystData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    AnalystGroupLid,
    ApiNames,
    fontFamilies,
    fontWeights,
    RequestTypes,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, styled, SxProps, Typography, useMediaQuery } from '@mui/material';
import { StyledTabsV2 } from 'features/home/components/StyledTabs';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useLocation } from 'react-router-dom';
import { sendSingleRequest } from 'utils/api';
import { Locations } from 'utils/preferences';
import { ProfilePicture } from './ProfileAvatar';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle16,
    TypographyStyle27,
    TypographyStyle31,
    TypographyStyle7,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { AnalystProfileDetailsMobile } from './AnalystProfileDetailsMobile';
import { AnalystProfileDetailsDesktop } from './AnalystProfileDetailsDesktop';

const tabList: Array<{ label: string; view: string; filters: { [key: string]: any } }> = [
    {
        label: 'All',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': {
                values: [AnalystGroupLid.AccountingLens, AnalystGroupLid.LegalEdge],
            },
        },
    },
    {
        label: 'Accounting',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': { values: [AnalystGroupLid.AccountingLens] },
        },
    },
    {
        label: 'Legal Edge',
        view: 'profile_list',
        filters: {
            'platinum.analyst_group.analyst_group_lid': { values: [AnalystGroupLid.LegalEdge] },
        },
    },
];

export const desktopBreakpoint = '(max-width: 767.5px)';
export const breakpointWorksOnMobile = `@media (max-width: 767.5px)`;
export const breakpointWorksOnDesktop = '@media (min-width: 767.5px)';

const ListItemBox = styled(Box)(() => ({
    [breakpointWorksOnMobile]: {
        width: '84px',
        maxWidth: '84px',
        padding: '0px 11px',
    },
    [breakpointWorksOnDesktop]: {
        paddingLeft: '48px',
        paddingRight: '6px',
    },
}));

const containerSx: SxProps = {
    margin: '0px',
    borderRadius: '8px',
    [breakpointWorksOnMobile]: {
        padding: '9px 0px',
    },
    [breakpointWorksOnDesktop]: {
        height: 'fit-content',
        padding: '24px 0',
    },
};

const headerSx: SxProps = {
    padding: '0 24px',
};

const tabPanelSx: SxProps = {
    display: 'flex',
    width: '100%',
    minHeight: '300px',
    [breakpointWorksOnMobile]: {
        paddingTop: '6.5px',
        flexDirection: 'column',
    },
    [breakpointWorksOnDesktop]: {
        justifyContent: 'center',
        mt: '-1px',
        pt: '10px',
        borderTop: 'solid 1px #E4E8F4',
    },
};

const listContainerFrameSx: SxProps = {
    position: 'relative',
    [breakpointWorksOnMobile]: {
        minWidth: '100%',
        height: 'auto',
        marginBottom: '16px',
        padding: '0px 9px',
    },
    [breakpointWorksOnDesktop]: {
        minWidth: '300px',
        mb: '10px',
    },
};

const listContainerSx: SxProps = {
    [breakpointWorksOnMobile]: {
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        padding: '0 0 6px 0',
        gap: '0',
        WebkitOverflowScrolling: 'touch',
        position: 'relative',
        '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#E4E8F4',
            borderRadius: '5px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
    },
    [breakpointWorksOnDesktop]: {
        ...scrollbarThemeV3,
        overflow: 'hidden auto',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
};

const avatarContainerSx: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [breakpointWorksOnMobile]: {
        width: '62px',
        height: '69px',
    },
    [breakpointWorksOnDesktop]: {
        width: '96px',
    },
};

const listItemSx: SxProps = {
    display: 'flex',
    cursor: 'pointer',
    [breakpointWorksOnMobile]: {
        padding: '6.5px 0',
        flexDirection: 'column',
        gap: '8px',
        maxHeight: '126px',
    },
    [breakpointWorksOnDesktop]: {
        padding: '22px 0',
    },
};

const nameContainerSx = {
    [breakpointWorksOnMobile]: {
        display: 'flex',
        flexDirection: 'column',
        pt: '0',
        pl: '0',
        textAlign: 'center',
        position: 'relative',
        zIndex: 1,
    },
};

const styledTabsSx = {
    [breakpointWorksOnMobile]: {
        px: '9px',
        '& .MuiTabs-scrollButtons': {
            '& svg': {
                fill: '#3D99CA',
            },
        },
        '& .MuiTabs-scrollButtons.Mui-disabled': {
            width: 0,
        },
        '& .MuiTab-root': {
            fontFamily: fontFamilies.GraphikRegular,
            fontSize: '12px',
            lineHeight: '20px',
            color: '#002B5A',
            '&[aria-selected="true"]': {
                color: '#007AB9',
            },
        },
    },
};

function determineTabFromPath(pathname = '') {
    if (pathname.startsWith('/accounting-lens')) {
        return AnalystGroupLid.AccountingLens;
    } else if (pathname.startsWith('/legal-edge')) {
        return AnalystGroupLid.LegalEdge;
    }
    return 0;
}

const defaultTitle = 'FRS Analyst';

export const AnalystDirectory = () => {
    return (
        <AnalyticsDataContextProvider
            cfraDataLocal={{
                actionData: {
                    cardName: Locations.MeetOurAnalysts,
                },
            }}>
            <AnalystDirectoryInner />
        </AnalyticsDataContextProvider>
    );
};

export const AnalystDirectoryInner = () => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(determineTabFromPath(location.pathname));
    const [selectedAnalystId, setSelectedAnalystId] = useState<number | undefined>();
    const [selectedAnalystIdx, setSelectedAnalystIdx] = useState<number | undefined>();

    const isMobile = useMediaQuery(desktopBreakpoint);

    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { setCfraDataLocalRef } = analyticsDataContext || {};

    useEffect(() => {
        setCfraDataLocalRef?.current?.(
            (previousValue) => ({
                ...previousValue,
                actionData: {
                    ...previousValue?.actionData,
                    tabName: tabList[selectedTab].label,
                },
            }),
            ({ cfraDataLocalInputProp }) => cfraDataLocalInputProp?.actionData?.cardName !== Locations.MeetOurAnalysts,
        );
    }, [setCfraDataLocalRef, selectedTab]);

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const handleTabChange = (_: React.SyntheticEvent, v: number) => {
        analyticsDataPickerRef.current?.registerAction({
            action: joinWithDelimiter({
                values: ['click on tab', tabList[v].label],
            }),
        });
        setSelectedTab(v);
        setSelectedAnalystId(undefined);
        setSelectedAnalystIdx(undefined);
    };

    const getAnalystProfileListQuery = sendSingleRequest?.(
        {
            path: 'analyst/screener',
            securityType: 'analyst',
            view: 'profile_list',
            requestBody: {
                filters: {
                    values: {
                        ...tabList[selectedTab].filters,
                    },
                },
            },
            config: {
                enabled: typeof selectedTab === 'number',
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'analyst/screener',
            queryKeyFirstElement: 'getAnalystProfileListQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchAnalystData>;

    const analysts = useMemo(() => {
        return getAnalystProfileListQuery?.data?.results?.analyst || [];
    }, [getAnalystProfileListQuery?.data?.results?.analyst]);

    useEffect(() => {
        if (
            analysts.length > 0 &&
            typeof selectedAnalystId !== 'number' &&
            typeof analysts[0]?.analyst?.analyst_id === 'number'
        ) {
            setSelectedAnalystId(analysts[0].analyst.analyst_id);
            setSelectedAnalystIdx(0);
        }
    }, [analysts, selectedAnalystId]);

    const handleListItemClick = (item: { [x: string]: any; analyst?: any }, idx: number) => {
        analyticsDataPickerRef.current?.registerAction({
            action: joinWithDelimiter({
                values: [
                    'click on analyst',
                    item?.analyst?.first_name,
                    item?.analyst?.last_name,
                    item?.analyst?.title || defaultTitle,
                    item?.analyst.analyst_id,
                ],
            }),
        });
        setSelectedAnalystId(item.analyst.analyst_id);
        setSelectedAnalystIdx(idx);
    };

    const showSkeleton = getAnalystProfileListQuery?.isLoading;

    const AnalystProfileDetails = useMemo(
        () => (isMobile ? AnalystProfileDetailsMobile : AnalystProfileDetailsDesktop),
        [isMobile],
    );

    const cardContent = useMemo(() => {
        return (
            <>
                {!isMobile && (
                    <Box sx={headerSx} mb='18px'>
                        <TypographyStyle16
                            variant='h3'
                            sx={{ color: '#002B5A', mb: '12px', fontWeight: fontWeights.Medium }}>
                            {Locations.MeetOurAnalysts}
                        </TypographyStyle16>
                        <TypographyStyle31>
                            A passionate group of analysts and market leaders dedicated to providing in-depth financial
                            insights and strategic market analysis. Our team combines expertise and innovation to
                            deliver actionable intelligence for informed investment decisions.
                        </TypographyStyle31>
                    </Box>
                )}

                <Grid item xs={12} p={!isMobile ? '0 24px' : undefined}>
                    <StyledTabsV2
                        value={selectedTab}
                        variant='scrollable'
                        allowScrollButtonsMobile
                        TabIndicatorProps={{ style: { display: 'none' } }}
                        onChange={handleTabChange}
                        sx={styledTabsSx}>
                        {tabList.map((tab, idx) => (
                            <StyledTabV2 key={idx} label={tab.label} {...Layout.a11yProps(idx)} value={idx} />
                        ))}
                    </StyledTabsV2>
                </Grid>

                <Grid item xs={12} sx={tabPanelSx}>
                    {showSkeleton ? (
                        <Layout.Skeleton
                            height={10}
                            sx={{ m: isMobile ? '100px 25px 24px 25px' : '100px 0 24px 0', width: '90%' }}
                        />
                    ) : (
                        <Box
                            display='flex'
                            justifyContent='space-between'
                            width='100%'
                            sx={{
                                [breakpointWorksOnMobile]: {
                                    flexDirection: 'column',
                                },
                            }}>
                            <Box sx={listContainerFrameSx}>
                                <Box
                                    sx={listContainerSx}
                                    onClick={
                                        isMobile
                                            ? (e) => {
                                                  const target = e.target as HTMLElement;
                                                  const listItem = target.closest('[data-analyst-id]');
                                                  if (listItem) {
                                                      const analystId = listItem.getAttribute('data-analyst-id');
                                                      const idx = Number(listItem.getAttribute('data-idx'));
                                                      const analyst = analysts.find(
                                                          (a) => a.analyst.analyst_id === Number(analystId),
                                                      );
                                                      if (analyst) {
                                                          handleListItemClick(analyst, idx);
                                                      }
                                                  }
                                              }
                                            : undefined
                                    }>
                                    {analysts.map((item, index) => (
                                        <ListItemBox
                                            key={item?.analyst?.analyst_id}
                                            bgcolor={
                                                selectedAnalystId === item?.analyst?.analyst_id ? '#F3F5FC' : '#FFF'
                                            }
                                            {...(isMobile
                                                ? {
                                                      'data-analyst-id': item?.analyst?.analyst_id,
                                                      'data-idx': index,
                                                      'data-selected': selectedAnalystId === item?.analyst?.analyst_id,
                                                      sx: {
                                                          borderBottom: '1px dotted #E4E4E4',
                                                      },
                                                  }
                                                : {})}>
                                            <Box
                                                key={index}
                                                {...(isMobile
                                                    ? {
                                                          sx: listItemSx,
                                                      }
                                                    : {
                                                          sx: {
                                                              ...listItemSx,
                                                              borderBottom:
                                                                  selectedAnalystIdx === index ||
                                                                  selectedAnalystIdx === index + 1 ||
                                                                  index === analysts.length - 1
                                                                      ? 'none'
                                                                      : '1.5px dotted #CCCCCC',
                                                          },
                                                          onClick: () => handleListItemClick(item, index),
                                                      })}>
                                                <Box sx={avatarContainerSx}>
                                                    <ProfilePicture
                                                        {...(isMobile
                                                            ? {
                                                                  width: '84px',
                                                                  height: '69px',
                                                                  avatarDim: '84px',
                                                              }
                                                            : {
                                                                  width: '86.62px',
                                                                  height: '96px',
                                                                  avatarDim: '96px',
                                                              })}
                                                        imageUrl={item?.analyst?.profile_picture_s3_url}
                                                        firstName={item?.analyst?.first_name}
                                                        lastName={item?.analyst?.last_name}
                                                    />
                                                </Box>
                                                {isMobile ? (
                                                    <Box sx={nameContainerSx}>
                                                        <Typography
                                                            sx={{
                                                                color: '#555555',
                                                                fontFamily: fontFamilies.GraphikRegular,
                                                                fontSize: '11px',
                                                                lineHeight: '18px',
                                                                textAlign: 'center',
                                                                whiteSpace: 'normal',
                                                            }}>
                                                            {`${item?.analyst?.first_name} ${item?.analyst?.last_name}`}
                                                        </Typography>
                                                    </Box>
                                                ) : (
                                                    <Box pt='6px' pl='18px'>
                                                        <TypographyStyle27 lineHeight='24.2px'>
                                                            {item?.analyst?.first_name}
                                                        </TypographyStyle27>
                                                        <TypographyStyle27 lineHeight='24.2px'>
                                                            {item?.analyst?.last_name}
                                                        </TypographyStyle27>
                                                        <TypographyStyle7 lineHeight='20px' pt='6px'>
                                                            {item?.analyst?.title || defaultTitle}
                                                        </TypographyStyle7>
                                                    </Box>
                                                )}
                                            </Box>
                                        </ListItemBox>
                                    ))}
                                </Box>
                            </Box>
                            {selectedAnalystId &&
                                (isMobile ? (
                                    <Box
                                        sx={{
                                            flex: 1,
                                            width: '100%',
                                        }}>
                                        <AnalystProfileDetails analystId={selectedAnalystId} />
                                    </Box>
                                ) : (
                                    <AnalystProfileDetails analystId={selectedAnalystId} />
                                ))}
                        </Box>
                    )}
                </Grid>
            </>
        );
    }, [selectedTab, selectedAnalystId, analysts, showSkeleton, isMobile, selectedAnalystIdx, AnalystProfileDetails]);

    const card = useMemo(() => {
        return (
            <ETFCard containerStyles={containerSx}>
                {isMobile ? cardContent : <Grid width='100%'>{cardContent}</Grid>}
            </ETFCard>
        );
    }, [cardContent, isMobile]);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            {isMobile ? (
                card
            ) : (
                <Grid container display='block'>
                    {card}
                </Grid>
            )}
        </>
    );
};
