import { Navigate, Route, Routes } from 'react-router-dom';
import { PdfViewer } from '../components/PdfViewer';
import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { SnackbarProvider } from 'notistack';

export function PdfViewerRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='.' />} />
            <Route
                path='/:id'
                element={
                    <AnalyticsDataContextProvider isPageLevelContext>
                        <SnackbarProvider classes={{ containerRoot: 'cfra-snackbar-root' }}>
                            <PdfViewer />
                        </SnackbarProvider>
                    </AnalyticsDataContextProvider>
                }
            />
        </Routes>
    );
}
