import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { ScreenerResearchCompanyData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { uniqueArray } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { defaultListVariablesDelimiter } from 'analytics/analytics';
import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { useContext, useEffect } from 'react';
import { UseQueryResult } from 'react-query';

type UseWatchlistAnalyticsProps = {
    getWatchlistCompaniesQry: UseQueryResult<ScreenerResearchCompanyData>;
    selectedWatchlist?: SavedItemTypes;
    enabled?: boolean;
};

export function useWatchlistAnalytics({
    getWatchlistCompaniesQry,
    selectedWatchlist,
    enabled,
}: UseWatchlistAnalyticsProps) {
    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { setCfraDataLocalRef } = analyticsDataContext || {};

    useEffect(() => {
        if (enabled === false) {
            return;
        }

        if (
            !getWatchlistCompaniesQry.isLoading &&
            getWatchlistCompaniesQry.data &&
            getWatchlistCompaniesQry.data.results.company.length > 0
        ) {
            const ticketsList = uniqueArray(
                getWatchlistCompaniesQry.data.results.company.map(
                    (item) => item.company_security.security_trading.ticker_symbol,
                ),
            );

            setCfraDataLocalRef?.current?.((previousValue) => ({
                ...previousValue,
                actionData: {
                    ...previousValue?.actionData,
                    selectedWatchlist: joinWithDelimiter({
                        leftValue: selectedWatchlist?.name,
                        rightValue: String(selectedWatchlist?.id),
                    }),
                    watchlistTickersCount: ticketsList.length,
                    watchlistTickers: ticketsList.join(defaultListVariablesDelimiter),
                },
            }));
            return;
        }

        setCfraDataLocalRef?.current?.((previousValue) => ({
            ...previousValue,
            actionData: {
                ...previousValue?.actionData,
                selectedWatchlist: undefined,
                watchlistTickersCount: undefined,
                watchlistTickers: undefined,
            },
        }));
    }, [selectedWatchlist, setCfraDataLocalRef, getWatchlistCompaniesQry, enabled]);
}
