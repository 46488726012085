import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { useWatchlistActions } from 'components/watchlists/hooks/useWatchlistActions';
import { useWatchlistAnalytics } from 'components/watchlists/hooks/useWatchlistAnalytics';
import { useWatchlistCompaniesData } from 'components/watchlists/hooks/useWatchlistCompaniesData';
import { useWatchlistData } from 'components/watchlists/hooks/useWatchlistData';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { ScoresCard, ScoresCardRefType } from './ScoresCard';

const divider = getDividerString('divider');
const watchlistFilterName = 'company_security.security_trading.cfra_security_trading_company_id';
const watchlistChipId = `${watchlistFilterName}${divider}`;

export function ScoresCardWithContexts() {
    const [externalFilters, setExternalFilters] = useState<Record<string, any>>();
    const [externalChipItems, setExternalChipItems] = useState<Record<string, ChipItem>>();

    const scoresCardRef = useRef<ScoresCardRefType>(null);

    const handleWatchlistChips: Dispatch<SavedItemTypes | undefined> = useCallback((newSelectedWatchlist) => {
        // handle removing chip if unselect watchlist option is selected
        if (!newSelectedWatchlist) {
            setExternalChipItems((previousValue) => {
                if (!previousValue || (previousValue && previousValue[watchlistChipId] === undefined)) {
                    return previousValue;
                }

                previousValue && delete previousValue[watchlistChipId];
                return { ...previousValue };
            });
            return;
        }

        // handle new watchlist selected
        const chipItems: Record<string, ChipItem> = {
            [watchlistChipId]: {
                chip: {
                    label: `Watchlist: ${newSelectedWatchlist.name}`,
                    values: '',
                },
                stateData: {
                    controlID: watchlistChipId,
                    values: [],
                    filterSections: {},
                },
            },
        };

        setExternalChipItems((previousValue) => ({
            ...previousValue,
            ...chipItems,
        }));
    }, []);

    const {
        createWatchlistButtonJsx,
        watchlistSelectJsx,
        operationsModalsJsx,
        selectedWatchlist,
        unselectWatchlist,
        watchlistOperationsMenuIconJsx,
    } = useWatchlistActions({
        allowNoWatchlistSelected: true,
        noWatchlistSelectedPlaceholder: 'Select Watchlist',
        applyUserPreferences: false,
        onWatlistSelectionChanged: handleWatchlistChips,
        showResetOption: true,
        menuIconContainerSx: {
            paddingTop: 0,
        },
    });

    const onExternalChipDeleteClick = useCallback(
        (chipId: string) => {
            const filterAndValue = chipId.split(divider);

            if (filterAndValue.length !== 2) {
                throw new Error('onExternalChipDeleteClick exception. Invalid chip key');
            }

            const [filter] = filterAndValue;

            setExternalChipItems((previousValue) => {
                previousValue && delete previousValue[chipId];
                return { ...previousValue };
            });

            switch (filter) {
                case watchlistFilterName:
                    setExternalFilters((previousValue: Record<string, any>) => {
                        previousValue && delete previousValue[filter];
                        return { ...previousValue };
                    });
                    unselectWatchlist();
                    break;
            }
        },
        [unselectWatchlist],
    );

    const clearAllExternalChipItems = useCallback(() => {
        setExternalChipItems(undefined);
        setExternalFilters(undefined);
        unselectWatchlist();
        scoresCardRef.current?.clearSearch?.();
        scoresCardRef.current?.resetToggle?.();
    }, [unselectWatchlist]);

    const { secTradingIdsFilter } = useWatchlistData({ selectedWatchlist });
    const { getCompaniesBySecIdQuery } = useWatchlistCompaniesData({ secTradingIdsFilter });
    useWatchlistAnalytics({
        selectedWatchlist,
        getWatchlistCompaniesQry: getCompaniesBySecIdQuery,
    });

    useEffect(() => {
        setExternalFilters(secTradingIdsFilter);
    }, [secTradingIdsFilter]);

    return (
        <ResultsContextProvider onChipClearAllExternalCallback={clearAllExternalChipItems}>
            <FiltersModalContextProvider>
                <ScoresCard
                    ref={scoresCardRef}
                    watchlist={{
                        createWatchlistButtonJsx,
                        watchlistSelectJsx,
                        operationsModalsJsx,
                        selectedWatchlist,
                        watchlistOperationsMenuIconJsx,
                    }}
                    externalFilters={externalFilters}
                    externalChipItems={externalChipItems}
                    onExternalChipDeleteClick={onExternalChipDeleteClick}
                />
            </FiltersModalContextProvider>
        </ResultsContextProvider>
    );
}
