import { CfraDataLocal } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { AnalyticsDataPickerRefValue } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { DataItem, ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { uniqueArray } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { RefObject } from 'react';
import { getPrimaryObject, getSecondaryObjects } from 'utils/arrays';
import { defaultListVariablesDelimiter } from './analytics';

export const getReportOpenAction = ({ hasResearchData }: { hasResearchData?: boolean }) => {
    let action = 'report open';

    if (!hasResearchData) {
        action += ' (no report data)';
    }

    return action;
};

export function getSuggesterCompanyProps({ company }: { company: Record<string, any> }): AnalyticsActionProps {
    return {
        companyTicker: company?.['company_security.security_trading.ticker_symbol'],
        companyName: company?.['company_security.company.company_name'],
        companyCfraId: company?.['company_security.company.cfra_company_id'],
        companySecurityId: company?.['company_security.security_trading.cfra_security_trading_id'],
        companyExchange: company?.['company_security.security_trading.exchange_lid.exchange_code'],
    };
}

export function getReportCompanyProps({
    researchReportSecurityItem,
}: {
    researchReportSecurityItem: Record<string, any>;
}): AnalyticsActionProps {
    return {
        companyTicker: researchReportSecurityItem?.security_trading?.ticker_symbol,
        companyName: researchReportSecurityItem?.company?.company_name,
        companyCfraId: researchReportSecurityItem?.company?.cfra_company_id,
        companySecurityId: researchReportSecurityItem?.security_trading?.cfra_security_trading_id,
        companyExchange:
            researchReportSecurityItem?.security_trading?.exchange?.value ||
            researchReportSecurityItem?.security_trading?.exchange_lid?.exchange_code,
    };
}

export function getSuggesterReportProps({ research }: { research?: Record<string, any> }): AnalyticsActionProps {
    const researchReportAuthor = research?.research_report_author;
    const primaryAuthor = getPrimaryObject(researchReportAuthor);
    const secondaryAuthors = getSecondaryObjects(researchReportAuthor);
    const getAuthorName = (authorData?: Record<string, any>) => authorData?.['platinum.analyst.pen_name'];

    const researchReportSecurityTrading = research?.research_report_security__trading;
    const primaryCompany = getPrimaryObject(researchReportSecurityTrading);
    const secondaryCompanies = getSecondaryObjects(researchReportSecurityTrading);
    const getCompanyTicker = (company?: Record<string, any>) =>
        company?.['research_report_security.security_trading.ticker_symbol'];
    return {
        reportType: joinWithDelimiter({
            leftValue: research?.['research_report.research_type_name'],
            rightValue: research?.['research_report.research_type_id'],
        }),
        reportName: research?.['research_report.title'],
        reportAuthorPrimary: getAuthorName(primaryAuthor),
        reportAuthorSecondary: secondaryAuthors
            ?.map?.((reportAuthor: Record<string, any>) => getAuthorName(reportAuthor))
            .join(defaultListVariablesDelimiter),
        reportTickerPrimary: joinWithDelimiter({
            leftValue: getCompanyTicker(primaryCompany),
        }),
        list3: secondaryCompanies?.map((company: Record<string, any>) =>
            joinWithDelimiter({
                leftValue: getCompanyTicker(company),
            }),
        ),
        reportId: research?.['research_report._id'],
        isEntitled: Boolean(research?.['research_report.s3_pdf_url']),
        reportPublishTimestamp: research?.['research_report.publish_timestamp'],
    };
}

export function getReportProps({ research }: { research: Array<Record<string, any>> }): AnalyticsActionProps {
    const reportData = research[0] as Record<string, any> | undefined;

    const researchReport = reportData?.research_report;
    const researchReportAuthor = reportData?.research_report_author;
    const primaryAuthor = getPrimaryObject(researchReportAuthor);
    const secondaryAuthors = getSecondaryObjects(researchReportAuthor);
    const getAuthorName = (authorData?: Record<string, any>) => authorData?.platinum?.analyst?.pen_name;

    const researchReportSecurityTrading = reportData?.research_report_security__trading;
    const primaryCompany = getPrimaryObject(researchReportSecurityTrading);
    const secondaryCompanies = getSecondaryObjects(researchReportSecurityTrading);
    const getCompanyName = (company?: Record<string, any>) => company?.research_report_security?.company?.company_name;
    const getCompanyTicker = (company?: Record<string, any>) =>
        company?.research_report_security?.security_trading?.ticker_symbol;
    const getSecurityTradingId = (company?: Record<string, any>) =>
        company?.research_report_security?.security_trading?.cfra_security_trading_id;

    const gics = reportData?.research_report_security__company__gics;
    const primaryGics = getPrimaryObject(gics);
    const secondaryGics = getSecondaryObjects(gics);
    const getSectorData = (gics?: Record<string, any>) =>
        gics?.research_report_security?.company_sector?.lookup_gics_sector_lid;

    return {
        reportType: joinWithDelimiter({
            leftValue: researchReport?.research_type_name,
            rightValue: researchReport?.research_type_id,
        }),
        reportName: researchReport?.title,
        reportDomain: researchReport?.research_category,
        reportAuthorPrimary: getAuthorName(primaryAuthor),
        reportAuthorSecondary: secondaryAuthors
            ?.map?.((reportAuthor: Record<string, any>) => getAuthorName(reportAuthor))
            .join(defaultListVariablesDelimiter),
        reportCompanyNamePrimary: joinWithDelimiter({
            leftValue: getCompanyName(primaryCompany),
            rightValue: getSecurityTradingId(primaryCompany),
        }),
        reportSectorPrimary: joinWithDelimiter({
            leftValue: getSectorData(primaryGics)?.value,
            rightValue: getSectorData(primaryGics)?.key,
        }),
        reportSectorSecondary: uniqueArray(
            secondaryGics?.map((gics) =>
                joinWithDelimiter({
                    leftValue: getSectorData(gics)?.value,
                    rightValue: getSectorData(gics)?.key,
                }),
            ) || [],
        ).join(defaultListVariablesDelimiter),
        list2: secondaryCompanies?.map((company: Record<string, any>) =>
            joinWithDelimiter({
                leftValue: getCompanyName(company),
                rightValue: getSecurityTradingId(company),
            }),
        ),
        reportTickerPrimary: joinWithDelimiter({
            leftValue: getCompanyTicker(primaryCompany),
            rightValue: getSecurityTradingId(primaryCompany),
        }),
        list3: secondaryCompanies?.map((company: Record<string, any>) =>
            joinWithDelimiter({
                leftValue: getCompanyTicker(company),
                rightValue: getSecurityTradingId(company),
            }),
        ),
        reportId: reportData?.id,
        isEntitled: Boolean(researchReport?.s3_pdf_url),
        reportPublishTimestamp: researchReport?.publish_timestamp,
    };
}

export function registerReportOpen({
    research,
    reportId,
    cfraDataLocal,
    analyticsDataPickerRef,
}: {
    research?: Array<Record<string, any>>;
    reportId: string;
    cfraDataLocal?: CfraDataLocal;
    analyticsDataPickerRef?: RefObject<AnalyticsDataPickerRefValue>;
}) {
    const _registerAction = analyticsDataPickerRef?.current?.registerAction || globalThis.analytics?.registerAction;

    if (!research || research.length === 0) {
        const actionData: AnalyticsActionProps = {
            action: getReportOpenAction({
                hasResearchData: false,
            }),
            reportId,
        };

        _registerAction?.({
            ...cfraDataLocal?.actionData,
            ...actionData,
        });
        return;
    }

    const reportProps = getReportProps({ research });

    const actionData: AnalyticsActionProps = {
        ...reportProps,
        action: getReportOpenAction({
            hasResearchData: true,
        }),
        reportId,
    };

    _registerAction?.({
        ...cfraDataLocal?.actionData,
        ...actionData,
    });
}

export function getReportOpenAnalyticsCallback(props?: {
    reportId?: string;
    cfraDataLocal?: CfraDataLocal;
    analyticsDataPickerRef?: RefObject<AnalyticsDataPickerRefValue>;
}) {
    const { reportId, cfraDataLocal, analyticsDataPickerRef } = props || {};

    return {
        analyticsCallback: (data?: ScreenerResearchData, research?: Array<DataItem>) => {
            registerReportOpen({
                cfraDataLocal,
                research: research || data?.results?.research,
                reportId: reportId || research?.['0']?.id || data?.results?.research?.['0']?.id,
                analyticsDataPickerRef,
            });
        },
    };
}
