import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { ReactComponent as GridViewIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/grid-view.svg';
import { ReactComponent as TableViewIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/table-view.svg';
import { IconButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/IconButton';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { useCallback, useRef, useState } from 'react';

export enum Views {
    GridView = 'Grid View',
    TableView = 'Table View',
}

type GridViewsPanelProps = {
    defaultViewSelected?: Views;
    onClickCallback: (view: Views) => void;
};

const activeNormalIconColor = '#007AB9';
const activeHoverIconColor = '#5cacd4';
const inactiveNormalIconColor = '#545454';
const inactiveHoverIconColor = '#7f7f7f';

export function GridViewsPanel({ defaultViewSelected, onClickCallback }: GridViewsPanelProps) {
    const [selectedView, _setSelectedView] = useState<Views | undefined>(defaultViewSelected);
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    const setSelectedView = useCallback((view: Views) => {
        analyticsDataPickerRef.current?.registerAction({
            action: joinWithDelimiter({
                values: ['change view', view],
            }),
        });
        _setSelectedView(view);
    }, []);

    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <IconButton
                onClick={() => {
                    onClickCallback(Views.TableView);
                    setSelectedView(Views.TableView);
                }}
                sx={{
                    borderRadius: '5px',
                    padding: '5px',
                    '&:hover': {
                        svg: {
                            fill: selectedView === Views.TableView ? activeHoverIconColor : inactiveHoverIconColor,
                        },
                    },
                }}
                tooltipText='Table View'
                icon={
                    <CustomSvgIcon
                        component={TableViewIcon}
                        viewBox='0 0 20 16'
                        sx={{
                            width: '19px',
                            height: '16px',
                            fill: selectedView === Views.TableView ? activeNormalIconColor : inactiveNormalIconColor,
                        }}
                    />
                }
            />
            <IconButton
                onClick={() => {
                    onClickCallback(Views.GridView);
                    setSelectedView(Views.GridView);
                }}
                sx={{
                    borderRadius: '5px',
                    padding: '5px',
                    '&:hover': {
                        svg: {
                            fill: selectedView === Views.GridView ? activeHoverIconColor : inactiveHoverIconColor,
                        },
                    },
                }}
                tooltipText='Grid View'
                icon={
                    <CustomSvgIcon
                        component={GridViewIcon}
                        viewBox='0 0 17 16'
                        sx={{
                            width: '17px',
                            height: '16px',
                            fill: selectedView === Views.GridView ? activeNormalIconColor : inactiveNormalIconColor,
                        }}
                    />
                }
            />
        </>
    );
}
