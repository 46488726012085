import { ScreenerChipThemeOutline } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';
import {
    useFiltersForm,
    UseFiltersFormInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { useCallback, useMemo } from 'react';
import { FiltersButtonWithModal } from './FiltersButtonWithModal';
import { FiltersSection } from './FiltersSection';
import { getFiltersSectionJsx } from './getFiltersSectionJsx';
import { getModalFiltersJsx } from './getModalFiltersJsx';
import { FiltersFormProps } from './shared';

export function FiltersForm({ filtersConfig, isMobileVariant, ...props }: FiltersFormProps) {
    const getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'] = useMemo(() => {
        if (isMobileVariant) {
            return getModalFiltersJsx(filtersConfig);
        }

        return getFiltersSectionJsx(filtersConfig);
    }, [isMobileVariant, filtersConfig]);

    const getFiltersDefaultValue = useCallback(() => {
        let filterDefaultValues: Record<string, any> = {};
        Object.keys(filtersConfig).forEach((key) => {
            let componentProps = filtersConfig[key as keyof typeof filtersConfig];
            if (Array.isArray(componentProps)) {
                filterDefaultValues = componentProps.reduce((acc, filter) => {
                    if (filter?.defaultValues) {
                        acc[filter.filterMetadataKey] = filter.defaultValues;
                    }
                    return acc;
                }, filterDefaultValues as Record<string, any>);
            }

            if (key === 'pillsRowComponentProps' && componentProps) {
                const pillComponent = (componentProps as typeof filtersConfig.pillsRowComponentProps);
                if (pillComponent) {
                    filterDefaultValues[pillComponent.filterMetadataKey] = pillComponent.defaultValues;
                }
            }
        });
        return filterDefaultValues;
    }, [filtersConfig]);

    const { filtersFormJsx, filtersChipPanelJsx, submitHandler, control } = useFiltersForm({
        ...props,
        getFiltersJsx,
        getFiltersDefaultValue,
        chipStyles: {
            containerSx: { padding: isMobileVariant ? '18px 14px 15px 14px' : '0', maxHeight: 'unset' },
            onChipItemsExistSxProps: { marginBottom: isMobileVariant ? '0px' : '17px' },
        },
        skeletonContainerSx: {
            padding: '0',
            marginBottom: '24px',
            ...(isMobileVariant ? { padding: '24px 14px' } : {}),
        },
        formStyle: { width: '100%', marginBottom: isMobileVariant ? '0px' : '24px' },
        useSubmitInFilters: true,
        chipTheme: isMobileVariant ? ScreenerChipThemeOutline : undefined,
    });

    if (isMobileVariant) {
        return (
            <FiltersButtonWithModal
                filtersFormJsx={filtersFormJsx}
                filtersChipPanelJsx={filtersChipPanelJsx}
                externalChipItems={props.externalChipItems}
                submitHandler={submitHandler}
                control={control}
                externalFormStateStorage={props.externalFormStateStorage}
                watchlistName={filtersConfig.watchlistProps?.componentRef?.current?.selectedWatchlist?.name}
            />
        );
    }

    return <FiltersSection filtersFormJsx={filtersFormJsx} filtersChipPanelJsx={filtersChipPanelJsx} />;
}
