import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { ExpandableAccordion } from '@cfra-nextgen-frontend/shared/src/components/ExpandableAccordion';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { customBreakpointTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { homePageTheme } from 'components/themes/theme';
import { ScoresInfoBanner } from 'features/scores/components/ScoresInfoBanner';
import { useRef } from 'react';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { ScoresCardWithContexts } from './scoresCard/ScoresCardWithContexts';
import { Locations } from 'utils/preferences';

const contentScores = {
    topics: [
        {
            title: 'Understanding CFRA Scores',
            content: <ScoresInfoBanner />,
        },
    ],
};

function getTitleBoxStyles(isExpanded: boolean) {
    return {
        backgroundColor: isExpanded ? '#002B5A' : '#fff',
        marginBottom: '0px',
    };
}
function getCointentBoxStyles() {
    return {
        marginBottom: '0px',
        paddingLeft: '0px',
    };
}
export function ScoresHome() {
    const isMobileVariant = useMediaQuery(customBreakpointTheme.breakpoints.down('sm'));

    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);

    return (
        <ThemeProvider theme={homePageTheme}>
            <Grid container spacing={horizontalPaddingInSu} paddingX={{ xs: '15px', sm: '0' }}>
                {!isMobileVariant && (
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: contentScores.topics[0].title,
                            },
                        }}>
                        <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                        <Grid item xs={12} sx={{ marginTop: '-8px' }}>
                            <ExpandableAccordion
                                content={contentScores}
                                titleStyle={{ fontSize: '40px' }}
                                contentWrapperSx={{ marginTop: '0px' }}
                                getTitleBoxStyles={getTitleBoxStyles}
                                getCointentBoxStyles={getCointentBoxStyles}
                                onExpandIconClickCallback={(index) => {
                                    analyticsDataPickerRef.current?.registerAction({
                                        action: joinWithDelimiter({
                                            values: [
                                                `${index === null ? 'collapse' : 'expand'} accordion`,
                                                contentScores.topics[0].title,
                                            ],
                                        }),
                                    });
                                }}
                            />
                        </Grid>
                    </AnalyticsDataContextProvider>
                )}

                <Grid item xs={12}>
                    <AnalyticsDataContextProvider
                        cfraDataLocal={{
                            actionData: {
                                cardName: Locations.Scores,
                            },
                        }}>
                        <ScoresCardWithContexts />
                    </AnalyticsDataContextProvider>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
