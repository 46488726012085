import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';

export const getIcon: (iconName?: string) => React.FC<React.SVGProps<SVGSVGElement>> = (iconName) => {
    switch (iconName) {
        case 'pdf':
            return PdfIcon;
        default:
            throw new Error(`The ${iconName} icon is not supported by the getIcon function.`);
    }
};

export const preloadImage = (src: string) => {
    const img = new Image();
    img.src = src;
};