import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { Footer } from '@cfra-nextgen-frontend/shared/src/components/Footer';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import { useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { GeneralPreferencesConfiguration } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { getFiltersReqBody, SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ComponentLid, EntityTypeLid } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { Box, SxProps, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BcLabel } from 'components/BcLabel/BcLabel';
import { commonCustomBreakpointsTheme } from 'components/themes/customBreakpointsTheme';
import { WatchlistCompanyIds, WatchlistRef } from 'components/Watchlist/ManageWatchlist';
import {
    ComponentProps,
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { researchFiltersRequestParams } from 'utils/api';
import { SortOptions } from 'utils/enums';
import {
    horizontalPaddingInPx,
    horizontalPaddingInSu,
    maxPageWidthInPx,
    maxPageWidthInPxIncludingPadding,
} from 'utils/lookAndFeel';
import { Locations } from 'utils/preferences';
import { pageWithComponentInHeaderStyleProps } from 'utils/styles';
import { defaultSearchUsageReqBody, getPageLid, searchesUsageLoggerProps } from 'utils/usage';
import { FiltersForm } from './filters/FiltersForm';
import { FiltersFormProps, GetFiltersJsxConfig } from './filters/shared';
import { SearchResults } from './SearchResults';
import { TypeSearchComponent } from './TypesearchComponent';
import { SideBarComponentRef } from './utils';
import { WatchlistAnalyticsDataTracker } from './WatchlistAnalyticsDataTracker';

const divider = getDividerString('divider');
const companyFilterName = 'insights.research_report_security__company.research_report_security.company.cfra_company_id';
const searchFilterName = 'search';
const watchlistChipName = 'Watchlist';

type ResearchComponentProps = {
    filtersFormProps: { filtersConfig: GetFiltersJsxConfig };
    sideBarComponent?: React.ReactNode;
    queriesKeyFirstElementPostfix?: string;
    screenerSearchByParams?: SearchByParams;
    showTypeSearchComponent?: boolean;
    showOnlyTableView?: boolean;
    showTopLevelSortOptions?: boolean;
    defaultFilters?: Record<string, any>;
    defaultSortOption?: SortOptions | undefined;
    showBCLabel?: boolean;
    usageConfiguration?: {
        localSearch: {
            onCompanyClickConfig: {
                getRequestBody: (props: {
                    companyId: string;
                    ticker: string;
                    exchangeCode: string;
                }) => Record<string, any>;
            };
            onSearchTermClickConfig: {
                getRequestBody: (props: { searchTerm: string }) => Record<string, any>;
            };
        };
    };
    sideBarComponentRef?: React.RefObject<SideBarComponentRef>;
    gridViewItemContainerStyles?: SxProps;
    resultsCardTitleOnMobile: string;
    preferencesConfiguration?: GeneralPreferencesConfiguration<
        [Locations.SearchResults, `${Locations.SearchResults}_${Views.TableView}`]
    >;
};

export type ResearchComponentRef = {
    onCompanyClick: (companyId: string, ticker: string, exchangeCode: string) => void;
    onSearchTermClick: (searchTerm: string) => void;
};

// assume to be moved to the shared folder in the future
const ResearchComponentShared = forwardRef<ResearchComponentRef, ResearchComponentProps>(
    (
        {
            filtersFormProps,
            sideBarComponent,
            queriesKeyFirstElementPostfix,
            screenerSearchByParams,
            showTypeSearchComponent = true, // default to true
            showOnlyTableView,
            showTopLevelSortOptions = true, // default to true
            defaultFilters,
            defaultSortOption,
            showBCLabel = true, // default to true
            usageConfiguration,
            sideBarComponentRef,
            gridViewItemContainerStyles,
            resultsCardTitleOnMobile,
            preferencesConfiguration,
        },
        ref,
    ) => {
        const [externalPostData, setExternalPostData] = useState<Record<string, any>>();
        const [externalChipItems, setExternalChipItems] = useState<Record<string, ChipItem>>();

        const [searchTerms, setSearchTerms] = useState<Array<string>>([]);
        const { setRequestBody } = useUsageLogger(searchesUsageLoggerProps);
        const [watchlistCompanyIds, setWatchlistCompanyIds] = useState<WatchlistCompanyIds>();
        const watchlistComponentRef = useRef<WatchlistRef>(null);
        const isMobileVariant = useMediaQuery(commonCustomBreakpointsTheme.breakpoints.down('sm'));
        const isBelowLg = useMediaQuery(commonCustomBreakpointsTheme.breakpoints.down('lg'));

        useEffect(() => {
            let prevCompanies = filtersFormProps.filtersConfig.watchlistProps?.watchlistCompanyIds?.cfraCompanyId || [];
            let currentCompanies = watchlistCompanyIds?.cfraCompanyId || [];
            const watchlistName = watchlistComponentRef.current?.selectedWatchlist?.name || '';
            const chipId = `${watchlistChipName}${divider}${watchlistChipName}`;

            if (filtersFormProps.filtersConfig.watchlistProps) {
                let watchlistProps = filtersFormProps.filtersConfig.watchlistProps;
                watchlistProps.setWatchlistCompanyIds = setWatchlistCompanyIds;
                watchlistProps.watchlistCompanyIds = watchlistCompanyIds;
                watchlistProps.componentRef = watchlistComponentRef;
            }

            if (currentCompanies.length > 0) {
                //watchlist has been selected, we add the watchlist companies to companies that may already exist from popular search
                setExternalPostData((previousValue: Record<string, any>) => {
                    let newCompanies =
                        (previousValue?.[companyFilterName]?.values || [])
                            .filter((value: string) => !prevCompanies.includes(value))
                            .concat(currentCompanies) || [];

                    return {
                        ...previousValue,
                        [companyFilterName]: {
                            values: newCompanies,
                        },
                    };
                });

                setExternalChipItems((previousValue) => {
                    if (previousValue && previousValue[chipId]) {
                        delete previousValue[chipId];
                    }

                    return {
                        ...previousValue,
                        ...{
                            [chipId]: {
                                chip: {
                                    label: watchlistChipName,
                                    values: watchlistName,
                                },
                                stateData: {
                                    controlID: chipId,
                                    values: [],
                                    filterSections: {},
                                },
                            },
                        },
                    };
                });
            }

            if (currentCompanies.length === 0 && prevCompanies.length > 0) {
                //watchlist has been removed but a prev watchlist existed. Remove those watchlists
                setExternalPostData((previousValue: Record<string, any>) => {
                    let newCompanies =
                        (previousValue?.[companyFilterName]?.values || []).filter(
                            (value: string) => !prevCompanies.includes(value),
                        ) || [];

                    return {
                        ...previousValue,
                        [companyFilterName]: newCompanies.length === 0 ? undefined : { values: newCompanies },
                    };
                });

                setExternalChipItems((previousValue) => {
                    previousValue && delete previousValue[chipId];
                    return { ...previousValue };
                });
            }
        }, [watchlistCompanyIds, filtersFormProps.filtersConfig.watchlistProps]);

        const searchTerm = useMemo(() => searchTerms.join(' OR '), [searchTerms]);

        const filtersFormStateStorage = useRef<{
            dirtyFields: Record<string, boolean>;
            submittingData: Record<string, any>;
        } | null>(null);

        useEffect(() => {
            sideBarComponentRef?.current?.updateSideBarSearchByParams({
                requestBody: externalPostData,
                search: searchTerm,
            });
        }, [externalPostData, sideBarComponentRef, searchTerm]);

        const onCompanyClick: ResearchComponentRef['onCompanyClick'] = useCallback(
            (companyId: string, ticker: string, exchangeCode: string) => {
                const getOnCompanyClickUsageRequestBody =
                    usageConfiguration?.localSearch?.onCompanyClickConfig?.getRequestBody;
                if (getOnCompanyClickUsageRequestBody) {
                    // save company usage
                    setRequestBody({
                        body: getOnCompanyClickUsageRequestBody({
                            companyId,
                            ticker,
                            exchangeCode,
                        }),
                        enabled: true,
                    });
                }

                setExternalPostData((previousValue: Record<string, any>) => {
                    return {
                        ...previousValue,
                        [companyFilterName]: {
                            values: [...(previousValue?.[companyFilterName]?.values || []), companyId],
                        },
                    };
                });

                const chipId = `${companyFilterName}${divider}${companyId}`;

                const chipItems: Record<string, ChipItem> = {
                    [chipId]: {
                        chip: {
                            label: `${ticker}:${exchangeCode}`,
                            values: '',
                        },
                        stateData: {
                            controlID: chipId,
                            values: [],
                            filterSections: {},
                        },
                    },
                };

                setExternalChipItems((previousValue) => ({
                    ...previousValue,
                    ...chipItems,
                }));
            },
            [setRequestBody, usageConfiguration?.localSearch?.onCompanyClickConfig],
        );

        const onCompanyClickRef = useRef(onCompanyClick);

        const onSearchTermClick: ResearchComponentRef['onSearchTermClick'] = useCallback(
            (searchTerm: string) => {
                const getSearchTermUsageRequestBody =
                    usageConfiguration?.localSearch?.onSearchTermClickConfig?.getRequestBody;
                if (getSearchTermUsageRequestBody) {
                    // save search term usage
                    setRequestBody({
                        body: getSearchTermUsageRequestBody({
                            searchTerm,
                        }),
                        enabled: true,
                    });
                }

                const chipId = `${searchFilterName}${divider}${searchTerm}`;

                const chipItems: Record<string, ChipItem> = {
                    [chipId]: {
                        chip: {
                            label: 'Search Term',
                            values: searchTerm,
                        },
                        stateData: {
                            controlID: chipId,
                            values: [],
                            filterSections: {},
                        },
                    },
                };

                setExternalChipItems((previousValue) => ({
                    ...previousValue,
                    ...chipItems,
                }));

                setSearchTerms((previousValue) => [...previousValue, searchTerm]);
            },
            [setRequestBody, usageConfiguration?.localSearch?.onSearchTermClickConfig?.getRequestBody],
        );

        useImperativeHandle(ref, () => ({
            onCompanyClick,
            onSearchTermClick,
        }));

        const clearAllExternalChipItems = useCallback(() => {
            setExternalChipItems(undefined);
            setExternalPostData(undefined);
            setSearchTerms([]);
            watchlistComponentRef.current?.handleResetClick();
        }, []);

        const onExternalChipDeleteClick = useCallback((key: string) => {
            const filterAndValue = key.split(divider);

            if (filterAndValue.length !== 2) {
                throw new Error('onExternalChipDeleteClick exception. Invalid chip key');
            }

            const [filter, value] = filterAndValue;

            setExternalChipItems((previousValue) => {
                previousValue && delete previousValue[key];
                return { ...previousValue };
            });

            switch (filter) {
                case companyFilterName:
                    setExternalPostData((previousValue: Record<string, any>) => {
                        const newValue: Record<string, any> = {
                            ...previousValue,
                            [filter]: {
                                values: [
                                    ...(previousValue?.[filter]?.values || []).filter(
                                        (_value: string) => _value !== value,
                                    ),
                                ],
                            },
                        };

                        if (newValue[filter].values.length === 0 && newValue[filter]) {
                            delete newValue[filter];
                        }

                        return newValue;
                    });
                    break;
                case searchFilterName:
                    setSearchTerms((previousValue) => previousValue.filter((_value: string) => _value !== value));
                    break;
                case watchlistChipName:
                    watchlistComponentRef.current?.handleResetClick();
                    break;
                default:
                    break;
            }
        }, []);

        const leftColumnWidthInSu = 8.84;

        const { state } = useLocation();
        const externalSearchTerm = state?.searchTerm;

        useEffect(() => {
            const companyDetails = state?.companyDetails;
            if (!companyDetails) return;

            const { companyId, ticker, exchangeCode } = companyDetails;
            if (companyId && ticker && exchangeCode) {
                onCompanyClickRef.current?.(companyId, ticker, exchangeCode);
            }
        }, [state?.companyDetails]);

        useEffect(() => {
            if (typeof externalSearchTerm === 'string' && externalSearchTerm.length > 0) {
                onSearchTermClick(externalSearchTerm);
            }
        }, [externalSearchTerm, onSearchTermClick]);

        const filtersRequestParams: FiltersFormProps['filtersRequestParams'] = useMemo(() => {
            const filtersConfig = filtersFormProps.filtersConfig;

            return [
                {
                    ...researchFiltersRequestParams[0],
                    requestBody: {
                        ...getFiltersReqBody(defaultFilters),
                        requested_filters: [
                            filtersConfig.dateRangePickerComponentProps?.filterMetadataKey,
                            filtersConfig.pillsRowComponentProps?.filterMetadataKey,
                            ...(filtersConfig.virtualizeAutocompleteComponentsProps?.map(
                                (component) => component.filterMetadataKey,
                            ) || []),
                        ].filter(Boolean),
                    },
                },
                researchFiltersRequestParams[1],
            ];
        }, [defaultFilters, filtersFormProps.filtersConfig]);

        const filtersComponent = useMemo(() => {
            const filtersProps = {
                externalChipItems,
                onExternalChipDeleteClick,
                filtersRequestParams,
                isMobileVariant,
                externalFormStateStorage: filtersFormStateStorage,
                ...filtersFormProps,
            };

            return (
                <AnalyticsDataContextProvider
                    cfraDataLocal={{
                        actionData: {
                            cardName: Locations.Filters,
                            isMobileVariant,
                        },
                    }}>
                    <FiltersForm {...filtersProps} />
                </AnalyticsDataContextProvider>
            );
        }, [externalChipItems, onExternalChipDeleteClick, filtersFormProps, filtersRequestParams, isMobileVariant]);

        const typeSearchComponent = useMemo(() => {
            return (
                <AnalyticsDataContextProvider
                    cfraDataLocal={{
                        actionData: {
                            cardName: Locations.LocalSearch,
                            isMobileVariant,
                        },
                    }}>
                    <TypeSearchComponent
                        onCompanyClick={onCompanyClick}
                        onSearchTermClick={onSearchTermClick}
                        isMobileVariant={isMobileVariant}
                        queriesKeyFirstElementPostfix={queriesKeyFirstElementPostfix}
                    />
                </AnalyticsDataContextProvider>
            );
        }, [onCompanyClick, onSearchTermClick, queriesKeyFirstElementPostfix, isMobileVariant]);

        const searchResultsPreferencesConfiguration: ComponentProps<typeof SearchResults>['preferencesConfiguration'] =
            useMemo(() => {
                if (!preferencesConfiguration) {
                    return undefined;
                }

                return {
                    ...preferencesConfiguration,
                    selectorConfiguration: {
                        [Views.TableView]:
                            preferencesConfiguration.selectorConfiguration[
                                `${Locations.SearchResults}_${Views.TableView}`
                            ],
                        Common: preferencesConfiguration.selectorConfiguration[Locations.SearchResults],
                    },
                };
            }, [preferencesConfiguration]);

        return (
            <ResultsContextProvider onChipClearAllExternalCallback={clearAllExternalChipItems}>
                <WatchlistAnalyticsDataTracker
                    selectedWatchlist={watchlistComponentRef.current?.selectedWatchlist}
                    cfraCompanyIds={watchlistCompanyIds?.cfraCompanyId}
                />
                <FiltersModalContextProvider>
                    <>
                        <PageWithComponentInHeader
                            {...pageWithComponentInHeaderStyleProps}
                            component={
                                showTypeSearchComponent &&
                                !isMobileVariant && (
                                    <Box
                                        sx={{
                                            maxWidth: maxPageWidthInPx,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingTop: '23px',
                                            paddingBottom: '23px',
                                        }}>
                                        {typeSearchComponent}
                                    </Box>
                                )
                            }
                            outletComponent={
                                <Box
                                    sx={{
                                        width: '100%',
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}>
                                    {!isMobileVariant && filtersComponent}
                                    <Grid
                                        container
                                        spacing={
                                            !isBelowLg ? (sideBarComponent ? horizontalPaddingInSu : undefined) : 1.75
                                        }
                                        sx={{
                                            maxWidth: sideBarComponent
                                                ? `calc(${maxPageWidthInPx} + ${horizontalPaddingInPx * 3}px)`
                                                : maxPageWidthInPxIncludingPadding,
                                            flexWrap: {
                                                xs: 'wrap',
                                                md: 'nowrap',
                                            },
                                            ...(isBelowLg
                                                ? {
                                                      paddingLeft: '14px',
                                                      paddingRight: '14px',
                                                  }
                                                : {
                                                      paddingLeft: horizontalPaddingInSu,
                                                      paddingRight: horizontalPaddingInSu,
                                                  }),
                                            alignItems: 'flex-start',
                                        }}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={sideBarComponent ? leftColumnWidthInSu : 12}
                                            sx={{
                                                display: 'flex',
                                                flexWrap: isMobileVariant ? 'wrap-reverse' : 'wrap',
                                            }}>
                                            {showBCLabel && (
                                                <BcLabel
                                                    containerSx={
                                                        isMobileVariant
                                                            ? {
                                                                  marginTop: '5px',
                                                              }
                                                            : {
                                                                  paddingBottom: '10px',
                                                                  marginTop: '-13px',
                                                              }
                                                    }
                                                />
                                            )}
                                            <AnalyticsDataContextProvider
                                                cfraDataLocal={{
                                                    actionData: {
                                                        cardName: Locations.SearchResults,
                                                        isMobileVariant,
                                                    },
                                                }}>
                                                <SearchResults
                                                    cardTitleOnMobile={resultsCardTitleOnMobile}
                                                    searchTerm={searchTerm}
                                                    externalPostData={externalPostData}
                                                    defaultFilters={defaultFilters}
                                                    defaultSortOption={defaultSortOption}
                                                    queryKeyFirstElementPostfix={queriesKeyFirstElementPostfix}
                                                    externalSearchByParams={screenerSearchByParams}
                                                    showOnlyTableView={showOnlyTableView}
                                                    showTopLevelSortOptions={showTopLevelSortOptions}
                                                    gridViewItemContainerStyles={gridViewItemContainerStyles}
                                                    variant={isMobileVariant ? 'mobile' : 'desktop'}
                                                    titleRightSlotMobile={
                                                        isMobileVariant ? filtersComponent : undefined
                                                    }
                                                    belowTitleSlotMobile={
                                                        showTypeSearchComponent && isMobileVariant
                                                            ? typeSearchComponent
                                                            : undefined
                                                    }
                                                    preferencesConfiguration={searchResultsPreferencesConfiguration}
                                                />
                                            </AnalyticsDataContextProvider>
                                        </Grid>
                                        {sideBarComponent && (
                                            <Grid item xs={12} md={12 - leftColumnWidthInSu}>
                                                {sideBarComponent}
                                            </Grid>
                                        )}
                                    </Grid>
                                </Box>
                            }
                        />
                        <Footer />
                    </>
                </FiltersModalContextProvider>
            </ResultsContextProvider>
        );
    },
);

export const ResearchComponent = forwardRef<ResearchComponentRef, ResearchComponentProps>((props, ref) => {
    return (
        <ThemeProvider theme={commonCustomBreakpointsTheme}>
            <AnalyticsDataContextProvider>
                <ResearchComponentShared
                    {...props}
                    ref={ref}
                    usageConfiguration={{
                        localSearch: {
                            onCompanyClickConfig: {
                                getRequestBody: ({ ticker, companyId, exchangeCode }) => ({
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.LocalSearch,
                                    entity_type_lid: EntityTypeLid.Company,
                                    primary_entity_id: companyId,
                                    detail: {
                                        ticker,
                                        exchange_code: exchangeCode,
                                    },
                                }),
                            },
                            onSearchTermClickConfig: {
                                getRequestBody: ({ searchTerm }) => ({
                                    ...defaultSearchUsageReqBody,
                                    page_lid: getPageLid(),
                                    component_lid: ComponentLid.LocalSearch,
                                    text: searchTerm,
                                }),
                            },
                        },
                    }}
                />
            </AnalyticsDataContextProvider>
        </ThemeProvider>
    );
});
