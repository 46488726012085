import { determineSendSingleInfiniteRequest, determineSendSingleRequest, SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { USER_MANAGEMENT_URL } from '@cfra-nextgen-frontend/shared/src/config';
import {
    determineGetData,
    determinePrefetchQuery,
    determineUseData,
    determineUseMultipleData,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiInstanceTypes, ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { API } from 'aws-amplify';
import Axios, { AxiosRequestConfig } from 'axios';

const axiosAuth = (url: string) => {
    const axios = Axios.create({
        baseURL: url,
        headers: {
            'Content-Type': 'application/json',
        },
        params: {},
    });

    axios.interceptors.request.use(
        (config: AxiosRequestConfig) => config,
        (error) => {
            return Promise.reject(error);
        },
    );

    axios.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    return axios(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
            return Promise.reject(err);
        },
    );

    return axios;
};

export const getData = determineGetData({
    apiNameToApiDetails: {
        [ApiNames.Research]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
        [ApiNames.UserManagement]: {
            instance: API,
            instanceType: ApiInstanceTypes.AwsAmplify,
        },
        [ApiNames.UserManagementUnauthenticated]: {
            instance: axiosAuth(USER_MANAGEMENT_URL),
            instanceType: ApiInstanceTypes.Axios,
        }
    },
    useDataGetter: (data) => data,
});

export const UseData = determineUseData(getData);
export const UseMultipleData = determineUseMultipleData(getData);
export const prefetchQuery = determinePrefetchQuery(getData);
export const sendSingleRequest = determineSendSingleRequest({ UseData });
export const sendSingleInfiniteRequest = determineSendSingleInfiniteRequest({ getData });

export const researchFiltersRequestParams: Parameters<SendSingleRequest> = [
    {
        securityType: 'research',
        requestBody: {},
        config: {},
    },
    {
        requestType: RequestTypes.POST,
        path: 'research/screener-filters',
        queryKeyFirstElement: 'researchFiltersQuery',
        apiName: ApiNames.Research,
    },
];

export const companyFiltersRequestParams: Parameters<SendSingleRequest> = [
    {
        securityType: 'research',
        requestBody: {},
        config: {},
    },
    {
        requestType: RequestTypes.POST,
        path: 'company/screener-filters',
        queryKeyFirstElement: 'companyFiltersQuery',
        apiName: ApiNames.Research,
    },
];

export const OPTIMAL_EXPORT_CHUNK_SIZE = 650;
export const MAX_RESULTS_WINDOW = 10_000;