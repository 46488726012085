import { ScreenerResearchCompanyData } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/screener";
import { getFiltersReqBody } from "@cfra-nextgen-frontend/shared/src/utils/api";
import { ApiNames, RequestTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { UseQueryResult } from "react-query";
import { sendSingleRequest } from "utils/api";

type UseCompaniesDataProps = {
    secTradingIdsFilter?: Record<string, any>;
}

export function useWatchlistCompaniesData({ secTradingIdsFilter }: UseCompaniesDataProps) {
    const getCompaniesBySecIdQuery = sendSingleRequest(
        {
            securityType: 'research',
            view: 'watchlist',
            requestBody: secTradingIdsFilter ? getFiltersReqBody(secTradingIdsFilter) : undefined,
            config: {
                enabled: secTradingIdsFilter ? true : false,
            },
        },
        {
            path: 'company/screener',
            apiName: ApiNames.Research,
            requestType: RequestTypes.POST,
            queryKeyFirstElement: 'researchCompanyScreenerQuery',
        },
    ) as UseQueryResult<ScreenerResearchCompanyData>;

    return { getCompaniesBySecIdQuery }
}



