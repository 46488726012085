import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import {
    useFiltersForm,
    UseFiltersFormExternalInputProps,
    UseFiltersFormInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { ScreenerFormPillsRowProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/ScreenerFormPillsRow';
import { SxProps, Theme } from '@mui/material';
import { WatchlistCompanyIds, WatchlistRef } from 'components/Watchlist/ManageWatchlist';


export type GetFiltersJsxConfig = {
    pillsRowComponentProps?: {
        label: string;
        filterMetadataKey: ScreenerFormPillsRowProps['filterMetadataKey'];
        secondaryStyleStartIndex?: ScreenerFormPillsRowProps['secondaryStyleStartIndex'];
        customSortOrder?: ScreenerFormPillsRowProps['customSortOrder'];
        imageMap: ScreenerFormPillsRowProps['imageMap'];
        optionsToOverride?: ScreenerFormPillsRowProps['optionsToOverride'];
        defaultValues?: Array<number | string>;
    };
    virtualizeAutocompleteComponentsProps: Array<{
        defaultInputLabel: string;
        placeholder?: string;
        filterMetadataKey: string;
        defaultValues?: Array<number> | null;
    }>;
    dateRangePickerComponentProps?: {
        label: string;
        filterMetadataKey: string;
    };
    watchlistProps?: {
        setWatchlistCompanyIds?: React.Dispatch<React.SetStateAction<WatchlistCompanyIds | undefined>>;
        watchlistCompanyIds?: WatchlistCompanyIds | undefined;
        componentRef?: React.RefObject<WatchlistRef>;
        label: string;
    };
    theme?: Theme;
    showIconsOnMobile?: boolean;
};

export type FiltersFormProps = UseFiltersFormExternalInputProps & {
    isMobileVariant?: boolean;
    filtersConfig: GetFiltersJsxConfig;
    filtersRequestParams: Parameters<SendSingleRequest>;
    externalFormStateStorage?: UseFiltersFormInputProps['externalFormStateStorage'];
};

export type FiltersJsxWrapperProps = {
    filtersFormJsx?: JSX.Element;
    filtersChipPanelJsx?: JSX.Element;
};

export type FiltersButtonWithModalProps = FiltersJsxWrapperProps & {
    externalChipItems: FiltersFormProps['externalChipItems'];
    submitHandler?: ReturnType<typeof useFiltersForm>['submitHandler'];
    control: ReturnType<typeof useFiltersForm>['control'];
    externalFormStateStorage?: FiltersFormProps['externalFormStateStorage'];
    filterButtonTextStyle?: SxProps;
    watchlistName?: string;
};

export function getFiltersConfig(filtersConfig: GetFiltersJsxConfig, state: { filtersDefaultValues: Record<string, any> }) {
    const { filtersDefaultValues } = state ?? {};
    if (!filtersDefaultValues) return filtersConfig;

    return Object.keys(filtersConfig).reduce(
        (updatedConfig: any, key) => {
            const value = filtersConfig[key as keyof typeof filtersConfig];

            updatedConfig[key] = Array.isArray(value)
                ? value.map((item) => {
                      const defaultValue = filtersDefaultValues[item.filterMetadataKey];
                      return defaultValue !== undefined ? { ...item, defaultValues: defaultValue } : item;
                  })
                : value;
            
            const pillValue = value as typeof filtersConfig.pillsRowComponentProps;
            if (key === 'pillsRowComponentProps' && pillValue && filtersDefaultValues[pillValue.filterMetadataKey]) {
                updatedConfig[key] = { ...pillValue, defaultValues: filtersDefaultValues[pillValue.filterMetadataKey] }
            }
            return updatedConfig;
        },
        { ...filtersConfig },
    );
}
