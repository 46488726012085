import { AnalyticsDataContextProvider } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { useUserEntitlements } from '@cfra-nextgen-frontend/shared/src/hooks/useUserEntitlements';
import { WelcomePopup } from 'components/WelcomePopup/WelcomePopup';
import { TopNavigation } from 'features/topNavigation/TopNavigation';
import { useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Locations } from 'utils/preferences';
import { SnackbarProvider } from 'notistack';

export const PATHS_WITHOUT_TOP_NAV = [];

export function AuthenticatedRoutesWrapper() {
    const { pathname } = useLocation();
    const { userEntitlements } = useUserEntitlements();

    const hideTopNavigation = useMemo(() => {
        return PATHS_WITHOUT_TOP_NAV.some((path) => pathname.startsWith(path));
    }, [pathname]);

    useLayoutEffect(() => {
        // pause tracking until user entitlements are available
        globalThis?.analytics?.setUserDataLoading?.(true);

        if (userEntitlements) {
            globalThis?.analytics?.fillUserDetails?.(userEntitlements);
            globalThis?.analytics?.setUserDataLoading?.(false);
        }

        return () => {
            globalThis?.analytics?.setUserDataLoading?.(false);
        };
    }, [userEntitlements]);

    const Navigation = useMemo(() => {
        if (hideTopNavigation) {
            return null;
        }
        return (
            <AnalyticsDataContextProvider
                cfraDataLocal={{
                    actionData: {
                        cardName: Locations.TopNavigation,
                    },
                }}>
                <TopNavigation userEntitlements={userEntitlements} />
                <SnackbarProvider classes={{ containerRoot: 'cfra-snackbar-root' }}>
                    <WelcomePopup />
                </SnackbarProvider>
            </AnalyticsDataContextProvider>
        );
    }, [hideTopNavigation, userEntitlements]);

    return (
        <AnalyticsDataContextProvider>
            {Navigation}
            <Outlet />
        </AnalyticsDataContextProvider>
    );
}
