import { CellRendererParam } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { getReportCompanyProps, getReportProps } from 'analytics/utils';

export function getAnalyticsActionDataForLink({
    resultValue,
    linkData,
    param,
    initialActionProps,
    ...props
}: {
    resultValue: any;
    linkData?: any;
    param?: CellRendererParam;
    analyticsActionTrackingMode?: [string, string];
    initialActionProps?: AnalyticsActionProps;
}) {
    const analyticsActionTrackingMode =
        props.analyticsActionTrackingMode || param?.item_data?.analytics_action_tracking_mode;

    if (
        !analyticsActionTrackingMode ||
        !Array.isArray(analyticsActionTrackingMode) ||
        analyticsActionTrackingMode.length !== 2
    ) {
        return;
    }

    const linkType: string = analyticsActionTrackingMode[1];
    let actionProps: AnalyticsActionProps = initialActionProps || {
        action: `click on ${linkType}`,
        navigateTo: 'Company Profile',
    };

    const index: 'company' | 'research' = analyticsActionTrackingMode[0] as 'company' | 'research';

    switch (index) {
        case 'company':
            actionProps = {
                ...actionProps,
                ...getReportCompanyProps({
                    researchReportSecurityItem: resultValue?.['0']?.company_security || {},
                }),
            };
            break;
        case 'research':
            actionProps = {
                ...actionProps,
                ...getReportProps({
                    research: resultValue || {},
                }),
                ...getReportCompanyProps({
                    researchReportSecurityItem:
                        linkData ||
                        resultValue?.['0']?.research_report_security__trading?.research_report_security ||
                        {},
                }),
            };
            break;
    }

    return actionProps;
}
