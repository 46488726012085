import { Layout, Typography } from '@cfra-nextgen-frontend/shared';
import { ScreenerResearchAnalystData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    TypographyStyle29,
    TypographyStyle30,
    TypographyStyle32,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, SxProps } from '@mui/material';
import { UseQueryResult } from 'react-query';
import { sendSingleRequest } from 'utils/api';
import { ProfilePicture } from './ProfileAvatar';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

const containerSx: SxProps = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
};

const primaryDetailContainerSx: SxProps = {
    textAlign: 'left',
    padding: '12px 0px 17px 0px',
    margin: '0px 20px',
    width: 'calc(100% - 40px)',
    borderBottom: '1px dotted #E4E4E4',
};

const profileHeaderSx: SxProps = {
    display: 'flex',
    gap: '21px',
    alignItems: 'flex-start',
};

const detailsProfileImageSx: SxProps = {
    flexShrink: 0,
};

const detailsNameTypographySx: SxProps = {
    fontSize: '24px',
    lineHeight: '26.4px',
    color: '#002B5A',
    fontFamily: fontFamilies.GraphikSemibold,
    marginBottom: '4px',
};

const detailsTitleTypographySx: SxProps = {
    fontSize: '12px',
    lineHeight: '13.2px',
    color: '#5A5A5A',
    fontFamily: fontFamilies.GraphikMedium,
};

const detailsContactInfoSx: SxProps = {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#3C3C3C',
    fontFamily: fontFamilies.GraphikRegular,
    marginBottom: '4px',
};

const detailsContactEmailSx: SxProps = {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#007BB8',
    fontFamily: fontFamilies.GraphikRegular,
    textDecoration: 'none',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    display: 'inline-block',
    width: '100%',
};

const bioContainerSx: SxProps = {
    width: '100%',
    padding: '24px 25px 16px 25px',
};

const defaultTitle = 'FRS Analyst';

const profileAvatarResponsiveHeight= 'clamp(91px, (100vw - 176px)*.50825, 255px)';
const profileAvatarResponsiveWidth= 'clamp(91px, (100vw - 207px)*.54125, 255px)';

export function AnalystProfileDetailsMobile({ analystId }: { analystId: number }) {
    const getAnalystProfileQuery = sendSingleRequest?.(
        {
            path: 'analyst/screener',
            securityType: 'analyst',
            view: 'profile',
            requestBody: {
                filters: {
                    values: {
                        'platinum.analyst.analyst_id': { values: [analystId] },
                    },
                },
            },
            config: {
                enabled: typeof analystId === 'number',
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'analyst/screener',
            queryKeyFirstElement: 'getAnalystProfileQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchAnalystData>;

    if (getAnalystProfileQuery.isLoading) {
        return <Layout.Skeleton height={10} sx={{ m: '100px 25px 100px 25px' }} />;
    }

    const profileData = getAnalystProfileQuery?.data?.results?.analyst?.[0] || {};

    return (
        <Box sx={containerSx}>
            <Box sx={primaryDetailContainerSx}>
                <Box sx={profileHeaderSx}>
                    <Box sx={detailsProfileImageSx}>
                        <ProfilePicture
                            width={profileAvatarResponsiveWidth}
                            height={profileAvatarResponsiveHeight}
                            avatarDim={profileAvatarResponsiveWidth}
                            imageUrl={profileData?.analyst?.profile_picture_s3_url}
                            firstName={profileData?.analyst?.first_name}
                            lastName={profileData?.analyst?.last_name}
                            isDetailView={true}
                        />
                    </Box>
                    <Box>
                        <TypographyStyle29 sx={detailsNameTypographySx}>
                            {profileData?.analyst?.first_name} {profileData?.analyst?.last_name}
                        </TypographyStyle29>
                        <TypographyStyle30 marginBottom='16px' sx={detailsTitleTypographySx}>
                            {profileData?.analyst?.title || defaultTitle}
                        </TypographyStyle30>

                        <TypographyStyle30 marginBottom='6px' sx={detailsTitleTypographySx}>
                            Contact Information
                        </TypographyStyle30>
                        {profileData?.analyst?.phone && (
                            <TypographyStyle32 sx={detailsContactInfoSx}>{profileData.analyst.phone}</TypographyStyle32>
                        )}
                        {profileData?.analyst?.email_address && (
                            <Box
                                component='a'
                                href={`mailto:${profileData.analyst.email_address}`}
                                sx={detailsContactEmailSx}>
                                {profileData.analyst.email_address}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            {profileData?.analyst?.bio && (
                <Box sx={bioContainerSx}>
                    <Typography
                        sx={{
                            fontFamily: fontFamilies.GraphikMedium,
                            fontSize: '12px',
                            lineHeight: '20px',
                            color: '#5A5A5A',
                            marginBottom: '12px',
                        }}>
                        Bio
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: fontFamilies.GraphikRegular,
                            fontSize: '12px',
                            lineHeight: '22px',
                            color: '#3C3C3C',
                            overflowY: 'auto',
                            paddingRight: '12px',
                            ...scrollbarThemeV3,
                        }}>
                        {profileData.analyst.bio}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}
