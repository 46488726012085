import { ApiNames, decodeUrlToken, RequestTypes } from "@cfra-nextgen-frontend/shared/src/utils";
import { Auth } from "aws-amplify";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { sendSingleRequest } from 'utils/api';

type VerifyLoginProps = {
    setForgetPassword?: (v: boolean) => void;
}

function routeToLogin() {
    window.location.href = '/login';
}

const useVerifyLogin = ({ setForgetPassword }: VerifyLoginProps) => {
    const validate = useRef(false);
    const [isLoginVerified, setIsLoginVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [updatePassword, setUpdatePassword] = useState<boolean | undefined>(undefined);

    const loginToken = useMemo(() => {
        const token = decodeUrlToken(window.location.search)
        return {
            "source": token?.source,
            "username": token?.username || token?.userName,
            "password": token?.pw || token?.password,
            "rememberMe": ['yes', '1'].includes(token?.rememberMe),
            "forgot": ['yes', '1'].includes(token?.forgot)
        }
    }, []);

    const authenticateUser = useCallback(async () => {
        if (!loginToken?.username || !loginToken?.password) {
            setIsLoading(false);
            return;
        }
        if (validate.current) {
            return;
        }

        validate.current = true;
        try {
            const userAuth = await Auth.signIn(loginToken.username, loginToken.password);
            if (userAuth) {
                if (!updatePassword) {
                    window.location.href = '/';
                } else {
                    await Auth.signOut();
                    setIsLoginVerified(true);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        } catch (error: any) {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setIsLoginVerified(!!user);
            } catch {
                setIsLoginVerified(false);
            } finally {
                setIsLoading(false);
            }
        }
    }, [loginToken.password, loginToken.username, updatePassword]);

    useEffect(() => {
        if (loginToken.forgot) {
            setIsLoading(false)
            setUpdatePassword(true)
        }
    }, [loginToken.forgot])

    useEffect(() => {
        if (updatePassword) {
            setForgetPassword?.(true)
        }
    }, [setForgetPassword, updatePassword]);

    useEffect(() => {
        if ((typeof updatePassword === 'boolean' && !loginToken.forgot) || !loginToken.username) {
            authenticateUser();
        }
    }, [authenticateUser, loginToken.forgot, loginToken.username, updatePassword]);

    const passwordStatusQry = sendSingleRequest<any>(
        {
            userEmail: loginToken.username,
            config: {
                enabled: !!loginToken?.username
            }
        },
        {
            path: 'password-status',
            apiName: ApiNames.UserManagementUnauthenticated,
            queryKeyFirstElement: 'getPasswordStatus',
            requestType: RequestTypes.GET,
        });



    useEffect(() => {
        if (passwordStatusQry.isSuccess && passwordStatusQry?.data?.data) {
            const { update_password } = passwordStatusQry?.data?.data?.data || {}
            if (typeof update_password === 'boolean') {
                setUpdatePassword(update_password)
            } else {
                routeToLogin()
            }
        } else if (passwordStatusQry.isError) {
            routeToLogin()
        }
    }, [passwordStatusQry?.data?.data, passwordStatusQry.isError, passwordStatusQry.isSuccess])

    return { isLoginVerified, isLoading, loginToken, updatePassword };
};

export default useVerifyLogin;