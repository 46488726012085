import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { ItemVariant8 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant8';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import {
    TypographyStyle21,
    TypographyStyle7,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import Box from '@mui/material/Box';
import React from 'react';
import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { useRef } from 'react';
import { joinWithDelimiter } from '@cfra-nextgen-frontend/shared/src/utils/strings';

type PopupVariant1Props = {
    description: string;
    textToFilePath: Record<string, string>;
    title: string;
    titleRight: string;
    getOpenModalButton: (handleOpen: () => void) => JSX.Element;
    analyticsOpenModalCallback?: () => void;
};

const leftColumnWidthInSu = 7.15358;

export const PopupVariant1ModalSx = {
    titleStyle: {
        fontFamily: fontFamilies.GraphikMedium,
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.1%',
        color: '#002B5A',
        paddingBottom: '11px',
        boxShadow: 'none',
    },
    closeButtonFontSize: 22,
    modalBoxStyles: {
        display: 'block',
        maxWidth: '1376px',
        width: '70%',
        maxHeight: '90%',
        overflowY: 'hidden',
        padding: '21px 24px 28px 24px',
    },
};

export const retrieveGetOpenModalButton =
    (linkContent: string, analyticsDataPickerRef: React.RefObject<AnalyticsDataPickerRefValue>) =>
    (handleOpen: () => void) => {
        return (
            <Box
                sx={{
                    display: 'inline',
                }}>
                <ETFLinkButton
                    onClick={() => {
                        analyticsDataPickerRef.current?.registerAction({
                            action: joinWithDelimiter({
                                values: ['click on', linkContent],
                            }),
                        });

                        handleOpen();
                    }}
                    content={linkContent}
                    sx={{
                        minWidth: '0px',
                        height: '12px',
                        fontSize: '12px',
                        fontFamily: fontFamilies.GraphikSemibold,
                        textDecoration: 'underline',
                        color: '#007AB9',
                    }}
                />
            </Box>
        );
    };

export function PopupVariant1({
    description,
    textToFilePath,
    title,
    titleRight,
    getOpenModalButton,
    analyticsOpenModalCallback,
}: PopupVariant1Props) {
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <ETFModal
                getOpenComponent={getOpenModalButton}
                title={title}
                analyticsOpenModalCallback={analyticsOpenModalCallback}
                {...PopupVariant1ModalSx}>
                <Grid
                    container
                    rowGap={2}
                    sx={{
                        maxHeight: 'calc(90vh - 81px)',
                        overflowY: 'auto',
                        ...scrollbarThemeV3,
                    }}>
                    <Grid
                        item
                        xs={12}
                        sm={leftColumnWidthInSu}
                        sx={{
                            paddingRight: '28px',
                        }}>
                        <TypographyStyle7
                            sx={{
                                lineHeight: '22px',
                                paddingTop: '2px',
                                fontWeight: 400,
                                fontSize: '13.5px !important',
                            }}>
                            {description}
                        </TypographyStyle7>
                    </Grid>
                    <Grid item xs={12} sm={12 - leftColumnWidthInSu}>
                        <TypographyStyle21
                            sx={{
                                lineHeight: '24px',
                                paddingBottom: '11px',
                                fontWeight: 400,
                                fontSize: '14px !important',
                            }}>
                            {titleRight}
                        </TypographyStyle21>
                        <AnalyticsDataPicker ref={analyticsDataPickerRef} />
                        {React.Children.toArray(
                            Object.keys(textToFilePath).map((text, index) => {
                                return (
                                    <ItemVariant8
                                        key={index}
                                        text={text}
                                        onClick={() => {
                                            analyticsDataPickerRef.current?.registerAction({
                                                action: joinWithDelimiter({
                                                    values: ['open pdf', text],
                                                }),
                                            });
                                            window.open(textToFilePath[text]);
                                        }}
                                        textStyle={{
                                            fontSize: '12px !important',
                                        }}
                                    />
                                );
                            }),
                        )}
                    </Grid>
                </Grid>
            </ETFModal>
        </>
    );
}
