import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserPreferences } from '../../types/userPreferences';
import { ApplicationType, AppTypeToPreferencesSavedItemsLid } from '../../utils/enums';
import { ProjectSpecificResourcesContext } from '../ProjectSpecificResourcesContext/Context';
import { CreateSavedItemResponseTypes, SavedItemListResponseTypes } from '../Screener/types/savedScreens';
import { createSavedItemConfig, getSavedItemsByTypeConfig } from '../../utils/userSavedItem';
import { useMakeIndependent } from '../../hooks/useMakeIndependent';
import { getPreferencesObjectQueryKey } from './PreferencesUpdater';
import { queryClient } from '../../lib/react-query-client';

export type PreferencesFetcherProps = {
    onSuccess: (userPreferences: UserPreferences) => void;
    applicationType?: ApplicationType;
    getPreferencesConfig?: { cacheTime?: number };
};

export function PreferencesFetcher({ applicationType, getPreferencesConfig, onSuccess }: PreferencesFetcherProps) {
    if (!applicationType) {
        throw new Error('applicationType is required for PreferencesFetcher');
    }
    
    const [userPreferences, _setUserPreferences] = useState<UserPreferences | undefined>(undefined);

    const { independentValue: onSuccessIndependent } = useMakeIndependent({
        valueGetter: () => (userPreferences: UserPreferences) => onSuccess(userPreferences),
        defaultValue: () => {},
    });

    useEffect(() => {
        if (userPreferences) {
            onSuccessIndependent.current(userPreferences);
        }
    }, [onSuccessIndependent, userPreferences]);

    const setUserPreferences = useCallback((newUserPreferences: UserPreferences) => {
        _setUserPreferences((prevUserPreferences) => {
            if (JSON.stringify(prevUserPreferences) !== JSON.stringify(newUserPreferences)) {
                return newUserPreferences;
            }

            return prevUserPreferences;
        });
    }, []);

    const savedItemLid = useMemo(() => {
        const _savedItemLid = AppTypeToPreferencesSavedItemsLid[applicationType];

        if (_savedItemLid === undefined) {
            throw new Error(`No saved item lid found for application type ${applicationType}`);
        }

        return _savedItemLid;
    }, [applicationType]);

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getPreferencesQuery = sendSingleRequest<SavedItemListResponseTypes>(
        {
            types: [savedItemLid],
            includeValues: true,
            config: { cacheTime: getPreferencesConfig?.cacheTime || 3000 },
        },
        getSavedItemsByTypeConfig,
    );

    const existingUserPreferenceData = useMemo(() => {
        const data = getPreferencesQuery.data?.data;

        return data?.length !== undefined && data?.length > 0 ? data[0] : undefined;
    }, [getPreferencesQuery.data?.data]);

    useEffect(() => {
        if (existingUserPreferenceData) {
            setUserPreferences(existingUserPreferenceData.value as UserPreferences);
        }
    }, [existingUserPreferenceData, setUserPreferences]);

    const createUserPreference = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            noErrorOnNoKeyValuePairs: true,
            requestBody: {
                name: 'UserPreferences',
                saved_item_lid: savedItemLid,
                value: { preferences: {} },
            },
            config: {
                enabled: existingUserPreferenceData === undefined && !getPreferencesQuery?.isLoading,
            },
        },
        createSavedItemConfig,
    );

    useEffect(() => {
        const value = createUserPreference?.data?.data?.value;

        if (!createUserPreference.isLoading && value) {
            setUserPreferences(value as UserPreferences);
            queryClient.invalidateQueries(getPreferencesObjectQueryKey);
        }
    }, [createUserPreference.data?.data.value, createUserPreference.isLoading, setUserPreferences]);

    return null;
}
