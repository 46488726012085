import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    useLinkGetterInputProps,
    useLinkGetter as useLinkGetterShared,
} from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { AnalyticsDataContext } from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataContext';
import { getReportOpenAnalyticsCallback } from 'analytics/utils';
import { useContext } from 'react';

export const useLinkGetter = (props: useLinkGetterInputProps<ScreenerResearchData>, reportId?: string) => {
    const analyticsDataContext = useContext(AnalyticsDataContext);
    const { cfraDataLocal } = analyticsDataContext || {};

    return useLinkGetterShared<ScreenerResearchData>({
        ...getReportOpenAnalyticsCallback({ cfraDataLocal, reportId }),
        ...props,
    });
};
