import {
    AnalyticsDataPicker,
    AnalyticsDataPickerRefValue,
} from '@cfra-nextgen-frontend/shared/src/analytics/AnalyticsDataPicker';
import { ETFDivider } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFDivider';
import {
    PopupVariant1,
    retrieveGetOpenModalButton,
} from '@cfra-nextgen-frontend/shared/src/components/Popup/PopupVariant1';
import { customBreakpointTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { TypographyStyle20 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { useMediaQuery } from '@mui/material';
import { cashFlowScoresDescription, commonPopupProps, earningsScoresDescription } from 'components/scores/shared';
import { useRef } from 'react';

export function ScoresInfoBanner() {
    const isMobileVariant = useMediaQuery(customBreakpointTheme.breakpoints.down('sm'));
    const analyticsDataPickerRef = useRef<AnalyticsDataPickerRefValue>(null);
    return (
        <>
            <AnalyticsDataPicker ref={analyticsDataPickerRef} />
            <ETFDivider
                headerStyle={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    paddingTop: '0px',
                    paddingBottom: '5px',
                    boxShadow: 'none',
                }}
                containerStyles={{
                    padding: isMobileVariant ? '18px 0' : '18px 24px',
                }}
                descriptionComponent={
                    <TypographyStyle20
                        component='div'
                        sx={{
                            fontSize: '12px !important',
                            color: '#3C3C3C',
                            lineHeight: '22px',
                            fontWeight: 400,
                        }}>
                        Access quantitative assessments for over 30,000 companies, featuring detailed insights into Cash
                        Flow and Earnings Scores.
                        <br />
                        <PopupVariant1
                            {...commonPopupProps}
                            description={cashFlowScoresDescription}
                            getOpenModalButton={retrieveGetOpenModalButton('Cash Flow Scores', analyticsDataPickerRef)}
                        />
                        : Evaluate cash flow dynamics for nearly 4,000 North American companies. These scores provide a
                        comprehensive view, including collections from customers, payments to suppliers, and interest
                        payments to creditors.
                        <br />
                        <PopupVariant1
                            {...commonPopupProps}
                            description={earningsScoresDescription}
                            getOpenModalButton={retrieveGetOpenModalButton('Earnings Scores', analyticsDataPickerRef)}
                        />
                        : Assess the quality of earnings by measuring discretionary, non-cash components of earnings
                        growth. These scores help identify companies that may report disappointing results, restate
                        earnings, or face regulatory or legal action.
                    </TypographyStyle20>
                }
            />
        </>
    );
}
