import { FiltersModalContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/FiltersModalContext';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { Page } from 'analytics/Page';
import { PageWrapper } from 'components/PageWrapper/PageWrapper';
import { AnalystDirectoryElement } from 'features/analystDirectory/routes/AnalystDirectoryRoutes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PageNames } from 'utils/enums';
import { ResearchCompanyHome } from '../components/ResearchCompany/ResearchCompanyHome';
import { ResearchIndustryHome } from '../components/ResearchIndustry/ResearchIndustryHome';
import { RiskMonitorHome } from '../components/RiskMonitor/RiskMonitorHome';

export function AccountingRoutes() {
    return (
        <Routes>
            <Route>
                <Route path='/*' element={<Navigate to='./risk-monitor' />} />
                <Route
                    path='/risk-monitor'
                    element={
                        <Page name={PageNames.AccountingLensRiskMonitor}>
                            <PageWrapper>
                                <ResultsContextProvider>
                                    <FiltersModalContextProvider>
                                        <RiskMonitorHome />
                                    </FiltersModalContextProvider>
                                </ResultsContextProvider>
                            </PageWrapper>
                        </Page>
                    }
                />
                <Route
                    path='/reports/company'
                    element={
                        <Page name={PageNames.AccountingLensCompanyResearch}>
                            <ResearchCompanyHome />
                        </Page>
                    }
                />
                <Route
                    path='/reports/industry'
                    element={
                        <Page name={PageNames.AccountingLensIndustryResearch}>
                            <ResearchIndustryHome />
                        </Page>
                    }
                />
                <Route
                    path='/analyst-directory'
                    element={
                        <Page name={PageNames.AccountingLensAnalystDirectory}>
                            <AnalystDirectoryElement />
                        </Page>
                    }
                />
            </Route>
        </Routes>
    );
}
