import { Text } from '@aws-amplify/ui-react';
import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { TypographyStyle13 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Box, Button, styled, SxProps } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { sendSingleRequest } from 'utils/api';

const StyledInput = styled('input')(({ theme }) => ({
    height: '60px',
    border: '1px solid #ddd',
    borderRadius: '10px',
    color: '#555',
    fontSize: '17px',
    fontFamily: fontFamilies.GraphikRegular,
    minWidth: '300px',
    boxSizing: 'border-box',
    m: '0 !important',
    width: '100%',
    outline: 'none',
    padding: '8px 16px',
    '&::placeholder': {
        color: '#555',
    },
    '&:focus': {
        borderColor: '#ddd',
        boxShadow: '0 0 4px 1px #1fb0f8 inset',
    },
}));

const StyledButton = styled(Button)(() => ({
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '49px',
    width: '100%',
    maxWidth: '400px',
    marginTop: '8px',
    borderRadius: '10px',
    color: '#fff',
    backgroundColor: '#002b5a',
    fontSize: '15px',
    fontFamily: fontFamilies.GraphikBlack,
    textTransform: 'uppercase',
    '&:hover': {
        backgroundColor: '#001327',
    },
    '&.Mui-disabled': {
        backgroundColor: '#eeeeee',
    },
}));

const containerSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    maxWidth: '480px',
};

const formContainerSx: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '14px',
    maxWidth: '400px',
    width: '100%',
};

type UpdatePasswordProps = {
    username: string;
    password: string;
    HeaderSection?: React.ReactNode;
    onSuccess?: () => void;
    onFailure?: () => void;
    formLabelSx?: SxProps;
};

export const CustomForceUpdatePassword = (props: UpdatePasswordProps) => {
    const { password, HeaderSection, username, onSuccess } = props;
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ newPassword: '', confirmNewPassword: '' });
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const validatePassword = useCallback(() => {
        const errors = { newPassword: '', confirmNewPassword: '' };

        const specialChars = '^$*.[]{}()?"!@#%&/\\,><\':;|_~`+=';
        const specialCharRegex = new RegExp(`[${specialChars.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')}]`);
        const passwordRules = [
            { regex: /.{8,}/ },
            { regex: /[a-z]/ },
            { regex: /[A-Z]/ },
            { regex: /[0-9]/ },
            { regex: /[^a-zA-Z0-9]/ },
            { regex: specialCharRegex },
        ];

        const failedRules = passwordRules.filter((rule) => !rule.regex.test(newPassword));
        if (password === newPassword) {
            errors.newPassword = 'New password cannot be the same as your current password.';
        } else if (failedRules.length > 0) {
            errors.newPassword =
                'Password must include 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.';
        }

        if (newPassword !== confirmNewPassword) {
            errors.confirmNewPassword = 'Passwords do not match.';
        }

        setErrors(errors);
        return !errors.newPassword && !errors.confirmNewPassword;
    }, [password, newPassword, confirmNewPassword]);

    const response = sendSingleRequest(
        {
            config: {
                enabled: isPasswordUpdated,
            },
            noErrorOnNoKeyValuePairs: true,
        },
        {
            requestType: RequestTypes.POST,
            path: 'password-status',
            queryKeyFirstElement: 'updatePasswordStatus',
            apiName: ApiNames.UserManagement,
        },
    );

    useEffect(() => {
        if (response.isSuccess) {
            ShowSnack(SnackMessageForm({ message: 'Your password was successfully updated' }));
            onSuccess?.();
        }
    }, [ShowSnack, onSuccess, response.isSuccess]);

    const handlePasswordReset = async () => {
        const isValid = validatePassword();
        if (!isValid) return;

        setLoading(true);
        try {
            const user = await Auth.signIn(username, password);
            if (user) {
                await Auth.changePassword(user, password, newPassword);
                setIsPasswordUpdated(true);
                return;
            }
            enqueueSnackbar('Ooops, something went wrong.', { variant: 'error' });
            props.onFailure?.();
            setLoading(false);
        } catch (err) {
            console.log(err);
            enqueueSnackbar('Ooops, something went wrong.', { variant: 'error' });
            props.onFailure?.();
            setLoading(false);
        }
    };

    return (
        <Box sx={containerSx}>
            {HeaderSection}
            <Box sx={formContainerSx}>
                <TypographyStyle13 sx={{ width: '100%', ...(props?.formLabelSx || {}) }}>
                    Update Password
                </TypographyStyle13>
                
                <StyledInput
                    type='password'
                    placeholder='New Password'
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                {errors.newPassword && <Text style={{ color: 'red' }}>{errors.newPassword}</Text>}

                <StyledInput
                    type='password'
                    placeholder='Confirm Password'
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
                {errors.confirmNewPassword && <Text style={{ color: 'red' }}>{errors.confirmNewPassword}</Text>}

                <StyledButton onClick={handlePasswordReset} disabled={loading}>
                    {loading ? 'Processing...' : 'Submit'}
                </StyledButton>
            </Box>
        </Box>
    );
};
