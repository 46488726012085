import { ETFInfo } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFInfo/ETFInfo';
import { SxProps, Theme } from '@mui/material';

type InformationPopupProps = {
    analyticsOpenModalProps?: AnalyticsActionProps;
    buttonFontSize?: number;
    descriptionComponent: React.ReactNode;
    popupTitle: string;
    titleStyle?: SxProps;
    tooltipText?: string;
    tooltipTheme?: Theme;
    analyticsOpenModalCallback?: () => void;
};

export function InformationPopup({
    analyticsOpenModalProps,
    buttonFontSize = 15,
    descriptionComponent,
    popupTitle,
    titleStyle,
    tooltipText,
    tooltipTheme,
    analyticsOpenModalCallback,
}: InformationPopupProps) {
    return (
        <ETFInfo
            title={popupTitle}
            titleStyle={titleStyle}
            tooltipText={tooltipText}
            tooltipTheme={tooltipTheme}
            description={descriptionComponent}
            buttonFontSize={buttonFontSize}
            modalBoxStyles={{
                maxHeight: '85%',
                overflow: 'hidden',
            }}
            analyticsOpenModalCallback={
                analyticsOpenModalCallback ||
                (analyticsOpenModalProps
                    ? () =>
                          globalThis.analytics?.registerAction?.({
                              ...analyticsOpenModalProps,
                              action: `open information popup: ${popupTitle}`,
                              cardName: `${analyticsOpenModalProps?.cardName} : ${popupTitle}`,
                          })
                    : undefined)
            }
        />
    );
}
