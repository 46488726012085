import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDataFromSessionStorage, removeDataFromSessionStorage } from '../utils/storage';

export function PreLoginRouteHandler({ children }: { children: React.ReactNode }) {
    const { route } = useAuthenticator((context) => [context.route, context.user]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const preLoginRouteValue = getDataFromSessionStorage('preLoginRoute');
        if (route === 'authenticated' && preLoginRouteValue) {
            const from = location.state?.from?.pathname || '/';
            const preLoginRoute = (from !== '/' ? from : null) || preLoginRouteValue;
            navigate(preLoginRoute, { replace: true });
            removeDataFromSessionStorage('preLoginRoute');
        }
    }, [route, navigate, location]);

    const memorizedChildren = useMemo(() => children, [children]);

    return <>{memorizedChildren}</>;
}
