import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    SendSingleRequest,
    SendSingleRequestConfig,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { openExternalLink } from '@cfra-nextgen-frontend/shared/src/utils/links';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { UsageLoggerProps, useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';

export enum LinkGeneratorModes {
    OpenInNewTab = 'openInNewTab',
    ExtractLink = 'extractLink',
}

export type GetRequestParamsProps = {
    searchByParams?: Partial<SearchByParams>;
    config?: Partial<SendSingleRequestConfig>;
};

export type useLinkGetterInputProps<T> = {
    mode: LinkGeneratorModes;
    getRequestParams: (props: GetRequestParamsProps) => Parameters<SendSingleRequest>;
    getLink: (data: T) => string;
    analyticsCallback?: (data?: T) => void;
    enableUsageLog?: boolean;
    getPostUsageParams?: (data: T) => any;
    usageLoggerProps?: UsageLoggerProps;
};

export type UseLinkGetterOutputProps<T> = {
    setRequestParamsProps: Dispatch<LinkGeneratorState<T>['requestParamsProps']>;
    isLoading: boolean;
    isError: boolean;
    extractedLink?: string;
    data?: UseQueryResult<T>['data'];
};

type LinkGeneratorState<T> = {
    requestParamsProps?: Parameters<useLinkGetterInputProps<T>['getRequestParams']>[0];
    isError: boolean;
    extractedLink?: string;
};

export function useLinkGetter<T>({
    mode,
    getRequestParams,
    getLink,
    analyticsCallback,
    enableUsageLog = false,
    usageLoggerProps = {},
    getPostUsageParams,
}: useLinkGetterInputProps<T>): UseLinkGetterOutputProps<T> {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setRequestBody } = useUsageLogger({ ...usageLoggerProps });

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('useLinkGetter hook exception. sendSingleRequest is not provided.');
        }
    }, [sendSingleRequest]);

    const [{ requestParamsProps, isError, extractedLink }, setState] = useState<LinkGeneratorState<T>>({
        isError: false,
        extractedLink: '',
    });

    // make sure the <return value of getRequestParams>[0].config.enabled is provided or false to avoid infinite loops
    const queryResult = sendSingleRequest?.(...getRequestParams({ ...requestParamsProps })) as UseQueryResult<T>;

    const setRequestParamsProps = useCallback((requestParamsProps: LinkGeneratorState<T>['requestParamsProps']) => {
        setState((prevState) => ({
            ...prevState,
            requestParamsProps,
        }));
    }, []);

    if (!sendSingleRequest) {
        throw new Error(
            'useLinkGetter exception. sendSingleRequest is not available in ProjectSpecificResourcesContext.',
        );
    }

    useEffect(() => {
        if (!requestParamsProps || queryResult?.isLoading) {
            return;
        }

        const extractedLinkValue = queryResult?.data ? getLink(queryResult.data) : undefined;

        analyticsCallback?.(queryResult.data);

        if (!extractedLinkValue) {
            setState((prevState) => ({
                ...prevState,
                isError: true,
                requestParamsProps: undefined,
            }));
            return;
        }

        switch (mode) {
            case LinkGeneratorModes.OpenInNewTab:
                openExternalLink(extractedLinkValue);
                setState((prevState) => ({
                    ...prevState,
                    requestParamsProps: undefined,
                }));
                break;
            case LinkGeneratorModes.ExtractLink:
                setState((prevState) => ({
                    ...prevState,
                    requestParamsProps: undefined,
                    extractedLink: extractedLinkValue,
                }));
                break;
            default:
                throw new Error('Invalid mode provided to useLinkGetter. Only "openInNewTab" is supported.');
        }

        if (enableUsageLog && queryResult?.data && getPostUsageParams) {
            const usageReqBody = getPostUsageParams(queryResult.data);
            if (usageReqBody) {
                setRequestBody({ body: usageReqBody, enabled: true });
            }
        }
    }, [
        queryResult,
        mode,
        getPostUsageParams,
        setRequestBody,
        enableUsageLog,
        requestParamsProps,
        getLink,
        analyticsCallback,
    ]);

    return {
        setRequestParamsProps,
        isLoading: queryResult?.isLoading || false,
        isError,
        extractedLink,
        data: queryResult?.data,
    };
}
